import React, { useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '../../../../../hooks/useTranslation';
import { approvalWorkflowAPI } from 'api/approval/approvalManagement/approvalWorkflowAPI';

// components
import ApprovalMatrixModal from 'components/Modals/TemplateModalDialogs/Compliance/ApprovalMatrixModal';
import { Typography } from 'antd';

interface IProps {
  workflowId: string;
  workflowType: 'client' | 'admin';
  clientGroupNumericId: number | null;
}

const ViewApprovalMatrix = ({
  workflowId,
  workflowType,
  clientGroupNumericId,
}: IProps) => {
  const { t } = useTranslation('compliance');
  const [isApprovalMatrixVisible, setIsApprovalMatrixVisible] = useState(false);

  const { response } = useFetch(() => {
    if (!isApprovalMatrixVisible) {
      return null;
    }

    switch (workflowType) {
      case 'client':
        return clientGroupNumericId
          ? approvalWorkflowAPI.fetchClientTransactionApprovalWorkflow(
              workflowId,
              clientGroupNumericId,
            )
          : null;

      case 'admin':
        return approvalWorkflowAPI.fetchApprovalWorkflowInstanceById(
          workflowId,
        );
    }
  }, [workflowId, workflowType, clientGroupNumericId, isApprovalMatrixVisible]);

  return (
    <>
      <Typography.Link onClick={() => setIsApprovalMatrixVisible(true)}>
        {t('transactions.view_approval_matrix')}
      </Typography.Link>
      <ApprovalMatrixModal
        data={response}
        isVisible={isApprovalMatrixVisible}
        closeCallback={() => setIsApprovalMatrixVisible(false)}
      />
    </>
  );
};

export default ViewApprovalMatrix;
