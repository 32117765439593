import React, { memo, useState } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import {
  ClientGroupShortForCRMItemFromQuery,
  CRMItemWithClientGroupsFromQuery,
  relationshipAPI,
} from 'api/crm/relationship/relationshipAPI';

// components
import Text from '@core_components/Text';
import Spinner from '@core_components/Spinner';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Popover } from 'antd';

interface IProps {
  label: string;
  option: CRMItemWithClientGroupsFromQuery;
}

const OptionWithClientGroupsPopover = memo(({ label, option }: IProps) => {
  const { t } = useTranslation('crm');
  const [isLoading, setLoading] = useState(false);
  const [clientGroups, setClientGroups] = useState<
    ClientGroupShortForCRMItemFromQuery[] | null
  >(null);

  const handleVisibleChange = async (visible: boolean) => {
    if (!visible || isLoading || clientGroups) {
      return;
    }

    if (option.clientGroups.total > option.clientGroups.data.length) {
      setLoading(true);
      const response = await relationshipAPI.fetchClientGroupsForEntry(
        option._id,
        { page: 1, limit: -1 },
      );
      setClientGroups(response.data);
      setLoading(false);
    } else {
      setClientGroups(option.clientGroups.data);
    }
  };

  return (
    <Popover
      overlayStyle={{ zIndex: 99999 }}
      placement="bottom"
      content={
        isLoading ? (
          <Spinner />
        ) : clientGroups && clientGroups.length ? (
          <>
            <Text weight="semi-bold">
              {t('entity.notes.associated_client_groups')}
            </Text>

            {clientGroups.map((group) => (
              <Text key={group._id}>
                <EllipsisTooltip
                  title={group.shortName}
                  maxTextContainerWidth="240px"
                >
                  {group.shortName}
                </EllipsisTooltip>
              </Text>
            ))}
          </>
        ) : null
      }
      onVisibleChange={handleVisibleChange}
    >
      {label}
    </Popover>
  );
});

export default OptionWithClientGroupsPopover;
