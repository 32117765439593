import APIConfig from '../../config/api';
import { APIService } from 'api/axiosInstance';
import { FetchParamsModel, FetchResponseModel } from 'typings/common';

export interface ClientLockoutFromQuery {
  contactId: string;
  contactName: string;
  createdAt: number;
  createdBy: string;
  id: string;
  lockoutExpiration: number;
  lockoutReason: string;
  lockoutStatus: string;
  lockoutTimestamp: number;
  lockoutType: string;
  manualUnlockReason: string;
  manualUnlockTimestamp: number;
  updatedAt: number;
  userId: string;
  username: string;
}

interface FetchClientLockoutsParamsModel extends FetchParamsModel {
  contactIds: string | undefined;
  lockoutTypes: string | undefined;
  lockoutStatus: string | undefined;
}

type UnlockUserRequestModel = {
  reason: string;
};

const clientLockoutsAPI = {
  fetchClientLockouts: async (params: FetchClientLockoutsParamsModel) => {
    return APIService.get<FetchResponseModel<ClientLockoutFromQuery>>(
      `${APIConfig.authApi}/users/lockouts`,
      { params },
    ).then((response) => response.data);
  },

  fetchClientLockoutsByUserId: async (
    userId: string,
    params: FetchParamsModel,
  ) => {
    return APIService.get<FetchResponseModel<ClientLockoutFromQuery>>(
      `${APIConfig.authApi}/users/${userId}/lockouts`,
      { params },
    ).then((response) => response.data);
  },

  unlockClientLockout: async (
    model: UnlockUserRequestModel,
    lockoutId: string,
  ) => {
    return APIService.patch(
      `${APIConfig.authApi}/users/lockouts/${lockoutId}/unlock`,
      model,
    ).then((response) => response.data);
  },
};

export default clientLockoutsAPI;
