import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { styled } from 'styled-components';
import { DateHelpers } from '@helpers/date';
import { AccountsHelpers } from '../../../../../helpers/finance/accounts';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { ClientTransactionTypes } from 'enums/banking/transactions';
import { PendingTransactionFromQuery } from '../../../../../api/transactions/transactionsAPI';
import { ClientTransferApprovalStatuses } from 'enums/transfers/transfers';

// components
import InputAmount from '@core_components/InputAmount';
import DocumentLink from '../../../../Typography/DocumentLink';
import AccountAutocomplete from 'components/Forms/FormComponents/Autocompletes/Finance/AccountAutocomplete';
import FilterDropdownOverlay from 'components/Tables/FilterDropdownOverlay';
import ClientTransactionStatus from '../../../../Additional/Statuses/ClientTransactionStatus';
import CurrencyFormAutocomplete from 'components/Forms/FormComponents/Autocompletes/Finance/CurrencyFormAutocomplete';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Col, DatePicker, Row, Typography } from 'antd';

export type ActionKeys = 'view';

interface IProps
  extends RequiredPropsForTableModel<PendingTransactionFromQuery> {
  clientGroupId: number;
  filters: Record<string, any>;
  onFilterChange: (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => void;
  onActionsClick?: (
    key: ActionKeys,
    record: PendingTransactionFromQuery,
  ) => void;
}

const ClientTransactionsTable = ({
  onActionsClick,
  filters,
  onFilterChange,
  clientGroupId,
  ...rest
}: IProps) => {
  const { t } = useTranslation('banking');

  const tableColumns = useMemo<TableColumnModel[]>(() => {
    function handleAmountChange(
      field: 'from' | 'to',
      value: number,
      filterProps: FilterDropdownProps,
      currentValue: number[] | undefined,
    ) {
      const newValue = currentValue
        ? [...currentValue]
        : [undefined, undefined];

      switch (field) {
        case 'from':
          {
            newValue[0] = value;
            if (typeof newValue[1] !== 'undefined' && value > newValue[1]) {
              newValue[1] = value;
            }
          }
          break;

        case 'to':
          {
            newValue[1] = value;
            if (typeof newValue[0] !== 'undefined' && value < newValue[0]) {
              newValue[0] = value;
            }
          }
          break;
      }

      filterProps.setSelectedKeys(newValue as any);
    }

    return [
      {
        width: 200,
        title: t('transactions.table.requested_date'),
        key: 'requested_date',
        render: (record: PendingTransactionFromQuery) =>
          record.creationDate > 0
            ? DateHelpers.formatTimestampToString(record.creationDate)
            : '',
        filteredValue: filters.requestedDate,
        filterDropdown: (filterProps: FilterDropdownProps) => {
          return (
            <FilterDropdownOverlay filterProps={filterProps}>
              <StyledRangePicker
                allowClear
                size="large"
                value={filterProps.selectedKeys as any}
                onChange={(value) => filterProps.setSelectedKeys(value as any)}
              />
            </FilterDropdownOverlay>
          );
        },
      },
      {
        width: 200,
        title: t('transactions.table.value_date'),
        key: 'value_date',
        render: (record: PendingTransactionFromQuery) =>
          record.valueDate > 0
            ? DateHelpers.formatTimestampToString(record.valueDate)
            : '',
        filteredValue: filters.valueDate,
        filterDropdown: (filterProps: FilterDropdownProps) => {
          return (
            <FilterDropdownOverlay filterProps={filterProps}>
              <StyledRangePicker
                allowClear
                size="large"
                value={filterProps.selectedKeys as any}
                onChange={(value) => filterProps.setSelectedKeys(value as any)}
              />
            </FilterDropdownOverlay>
          );
        },
      },
      {
        width: 200,
        title: t('transactions.table.transaction_status'),
        key: 'status',
        render: (record: PendingTransactionFromQuery) => (
          <ClientTransactionStatus
            transaction={{
              status: record.statusId,
              expiresAt: record.workflowExpirationDate,
            }}
          />
        ),
        filteredValue: filters.status,
        filterSearch: true,
        filterMultiple: true,
        filters: [
          {
            text: t(
              `transactions.client_transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.Completed]}`,
            ),
            value: 'completed',
          },
          {
            text: t(
              `transactions.client_transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.PendingApprovals]}`,
            ),
            value: 'pending',
          },
          {
            text: t(
              `transactions.client_transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.Processing]}`,
            ),
            value: 'processing',
          },
          {
            text: t(
              `transactions.client_transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.Rejected]}`,
            ),
            value: 'rejected',
          },
          {
            text: t(
              `transactions.client_transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.Cancelled]}`,
            ),
            value: 'cancelled',
          },
          {
            text: t(
              `transactions.client_transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.Scheduled]}`,
            ),
            value: 'scheduled',
          },
        ],
      },
      {
        width: 400,
        title: t('transactions.table.from_account'),
        key: 'from_account',
        mobileCardProps: { hideFromMainSection: true },
        render: (record: PendingTransactionFromQuery) =>
          AccountsHelpers.generateAccountFullName(
            record.fromAccountName,
            record.fromAccountNumber,
            record.fromCurrencyISOCode,
          ),
        filteredValue: filters.fromAccount,
        filterDropdown: (filterProps: FilterDropdownProps) => {
          return (
            <FilterDropdownOverlay filterProps={filterProps}>
              <StyledClientBankAccountsAutocomplete
                clientGroupId={clientGroupId}
                showBalance={false}
                isFullAccountNumber={false}
                value={filterProps.selectedKeys as any}
                minSearchLength={0}
                onChange={(value) => filterProps.setSelectedKeys(value as any)}
              />
            </FilterDropdownOverlay>
          );
        },
      },
      {
        width: 350,
        title: t('transactions.table.description'),
        key: 'description',
        render: (record: PendingTransactionFromQuery) => {
          if (!record.description) {
            return null;
          }

          if (!onActionsClick) {
            return record.description;
          }

          return (
            <Typography.Link onClick={() => onActionsClick('view', record)}>
              {record.description}
            </Typography.Link>
          );
        },
        mobileCardProps: { hideFromMainSection: true },
      },
      {
        width: 200,
        title: t('transactions.table.documents'),
        key: 'documents',
        render: (record: PendingTransactionFromQuery) =>
          record.relatedDocuments.map(({ dmsId, linkToDownload, name }) => (
            <div key={dmsId}>
              <DocumentLink
                text={name}
                fileId={linkToDownload}
                documentId={dmsId}
              />
            </div>
          )),
        mobileCardProps: { hideFromMainSection: true },
      },
      {
        width: 200,
        title: t('transactions.table.transaction_type'),
        key: 'type',
        mobileCardProps: { isCardTitle: true, hide: true },
        render: (record: PendingTransactionFromQuery) => record.sourceTypeName,
        filteredValue: filters.transactionType,
        filterSearch: true,
        filterMultiple: true,
        filters: [
          {
            value: ClientTransactionTypes.InternalTransfer,
            text: t(
              `transactions.types.${ClientTransactionTypes[ClientTransactionTypes.InternalTransfer]}`,
            ),
          },
          {
            value: ClientTransactionTypes.WireTransfer,
            text: t(
              `transactions.types.${ClientTransactionTypes[ClientTransactionTypes.WireTransfer]}`,
            ),
          },
        ],
      },
      {
        width: 200,
        title: t('transactions.table.currency'),
        key: 'currency',
        render: (record: PendingTransactionFromQuery) => record.currencyIsoCode,
        filteredValue: filters.currency,
        filterDropdown: (filterProps: FilterDropdownProps) => {
          return (
            <FilterDropdownOverlay filterProps={filterProps}>
              <StyledCurrencyFormAutocomplete
                isActive
                isAccount
                isExternal={false}
                mode="multiple"
                value={filterProps.selectedKeys as any}
                onChange={(value) => filterProps.setSelectedKeys(value as any)}
              />
            </FilterDropdownOverlay>
          );
        },
      },
      {
        width: 200,
        title: t('transactions.table.amount'),
        key: 'amount',
        render: (record: PendingTransactionFromQuery) =>
          record.amount
            ? AccountsHelpers.convertAmountBigIntToLocaleString(record.amount)
            : '',

        filteredValue: filters.amount,
        filterDropdown: (filterProps: FilterDropdownProps) => {
          const value = filterProps.selectedKeys as any;
          return (
            <FilterDropdownOverlay filterProps={filterProps}>
              <Row wrap={false} justify="space-between" align="middle">
                <Col flex="48%">
                  <StyledInputNumber
                    size="large"
                    placeholder={t('enter_from', { ns: 'common' })}
                    value={value ? value[0] : undefined}
                    onChange={(newValue) =>
                      handleAmountChange(
                        'from',
                        newValue as number,
                        filterProps,
                        value,
                      )
                    }
                  />
                </Col>
                <Col flex="48%">
                  <StyledInputNumber
                    size="large"
                    placeholder={t('enter_to', { ns: 'common' })}
                    value={value ? value[1] : undefined}
                    onChange={(newValue) =>
                      handleAmountChange(
                        'to',
                        newValue as number,
                        filterProps,
                        value,
                      )
                    }
                  />
                </Col>
              </Row>
            </FilterDropdownOverlay>
          );
        },
      },
    ];
  }, [onActionsClick, clientGroupId, filters]);

  return (
    <Table columns={tableColumns} onFilterChange={onFilterChange} {...rest} />
  );
};

const StyledRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;

const StyledCurrencyFormAutocomplete = styled(CurrencyFormAutocomplete)`
  width: 100%;
`;

const StyledInputNumber = styled(InputAmount)`
  width: 100%;
`;

const StyledClientBankAccountsAutocomplete = styled(AccountAutocomplete)`
  width: 100%;
`;

export default ClientTransactionsTable;
