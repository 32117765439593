import React, { memo, useMemo } from 'react';

//  helpers
import useTranslation from '@hooks/useTranslation';
import { styled } from 'styled-components';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { DateHelpers } from '@helpers/date';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';
import { ForgotUsernameRequestFromQuery } from 'api/orac/forgotUsernameRequestsAPI';

// components
import Link from '@common_components/Texts/Link';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from '../../../../Tooltips/EllipsisTooltip';
import NewMessageMarker from 'components/Additional/Messaging/NewMessageMarker';
import ForgotUsernameRequestStatus from 'components/Additional/Statuses/ForgotUsernameRequestStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

export type ActionKeys = 'forgot_username_request_view';

interface IProps
  extends RequiredPropsForTableModel<ForgotUsernameRequestFromQuery> {
  size?: SizeType;
  onActionsClick?: (
    key: ActionKeys,
    record: ForgotUsernameRequestFromQuery,
  ) => void;
}

const ForgotUsernameRequestsTable = memo(
  ({ size, onActionsClick, ...rest }: IProps) => {
    const { t } = useTranslation('crm');

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          width: 210,
          key: 'date',
          title: t('forgot_username_requests.table.date'),
          render: (record: ForgotUsernameRequestFromQuery) => (
            <DivAlignCenter>
              <StyledNewMessageMarker />
              {DateHelpers.formatDateToString(
                record.createdAt,
                DEFAULT_DATE_TIME_FORMAT,
              )}
            </DivAlignCenter>
          ),
        },

        {
          width: 200,
          key: 'name',
          title: t('forgot_username_requests.table.name'),
          render: (record: ForgotUsernameRequestFromQuery) => {
            const name = (
              <EllipsisTooltip
                title={record.name}
                maxTextContainerWidth="200px"
              >
                {record.name}
              </EllipsisTooltip>
            );
            return !onActionsClick ? (
              name
            ) : (
              <Link
                onClick={() =>
                  onActionsClick('forgot_username_request_view', record)
                }
              >
                {name}
              </Link>
            );
          },
        },

        {
          width: 200,
          key: 'client_group_name',
          title: t('forgot_username_requests.table.client_group_name'),
          render: (record: ForgotUsernameRequestFromQuery) => (
            <EllipsisTooltip
              title={record.clientGroupName}
              maxTextContainerWidth="200px"
            >
              {record.clientGroupName}
            </EllipsisTooltip>
          ),
        },
        {
          width: 230,
          key: 'status',
          title: t('forgot_username_requests.table.status'),
          render: (record: ForgotUsernameRequestFromQuery) => (
            <ForgotUsernameRequestStatus status={record.status} />
          ),
        },
      ];

      return result;
    }, [onActionsClick]);

    return <Table {...rest} size={size} columns={columns} />;
  },
);

const StyledNewMessageMarker = styled(NewMessageMarker)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default ForgotUsernameRequestsTable;
