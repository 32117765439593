import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { AutocompleteInitialValue } from '../../../../../components/Fields/Autocomplete';
import { FILTER_BAR_FIELDS_GRID_SIZES } from 'constants/grids';

// components
import CurrencySelect from '../../../../../components/Forms/FormComponents/SelectInputs/CurrencySelect';
import FinancialTypeSelect from '../../../../../components/Forms/FormComponents/SelectInputs/FinancialTypeSelect';
import ReconciliationSelect from '../../../../../components/Forms/FormComponents/SelectInputs/ReconciliationSelect';
import BankAccountStatusSelect from 'components/Forms/FormComponents/SelectInputs/BankAccountStatusSelect';
import AccountCategoryTreeSelect from '../../../../../components/Forms/FormComponents/Autocompletes/Finance/AccountCategoryTreeSelect';
import { SearchInput } from 'components/Fields/SearchInput';
import { Col, Row, Typography } from 'antd';

interface IProps {
  accountCategory: string;
  initialAccountCategoryOption?: AutocompleteInitialValue;
  handleChange: (key: string, value: any) => void;
}

const FilterBar = ({
  accountCategory,
  initialAccountCategoryOption,
  handleChange,
}: IProps) => {
  const { t } = useTranslation('finance');

  const handleOnSelectAccountCategory = (
    _: number,
    selectedCategory?: { title: string },
  ) => {
    handleFilterChange(
      'account-category-filter',
      selectedCategory?.title || '',
    );
  };

  const handleFilterChange = (filterKey: string, value: string) => {
    handleChange(filterKey, value || '');
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
          <Row>
            <Col span={24}>
              <Typography.Text>{t('accounts.table.search_by')}</Typography.Text>
            </Col>
            <Col span={24}>
              <SearchInput
                size="large"
                placeholder={t('accounts.table.search_placeholder')}
                onSearch={(searchQuery: string) =>
                  handleChange('search', searchQuery)
                }
              />
            </Col>
          </Row>
        </Col>

        <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
          <Row>
            <Col span={24}>
              <Typography.Text>
                {t('accounts.table.search_by_account_category_code')}
              </Typography.Text>
            </Col>
            <Col span={24}>
              <SearchInput
                size="large"
                placeholder={t(
                  'accounts.table.search_account_category_code_placeholder',
                )}
                onSearch={(searchQuery: string) =>
                  handleChange('accountCategoryCode', searchQuery)
                }
              />
            </Col>
          </Row>
        </Col>

        <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
          <Row>
            <Col span={24}>
              <Typography.Text>
                {t('accounts.table.filter_by_account_category')}
              </Typography.Text>
            </Col>
            <Col span={24}>
              <AccountCategoryTreeSelect
                value={accountCategory}
                initialOption={initialAccountCategoryOption}
                onClear={() =>
                  handleFilterChange('account-category-filter', '')
                }
                style={{ width: '100%' }}
                size="large"
                placeholder={t('accounts.table.type_category_name')}
                onSelect={handleOnSelectAccountCategory}
              />
            </Col>
          </Row>
        </Col>

        <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
          <Row>
            <Col span={24}>
              <Typography.Text>
                {t('accounts.table.filter_by_reconciliation')}
              </Typography.Text>
            </Col>
            <Col span={24}>
              <ReconciliationSelect
                size="large"
                onClear={() =>
                  handleFilterChange('external-reconciliation-filter', '')
                }
                placeholder={t('accounts.table.type_reconciliation')}
                onSelect={(value: string) =>
                  handleFilterChange(
                    'external-reconciliation-filter',
                    value || '',
                  )
                }
              />
            </Col>
          </Row>
        </Col>

        <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
          <Row>
            <Col span={24}>
              <Typography.Text>
                {t('accounts.table.filter_by_financial_type')}
              </Typography.Text>
            </Col>
            <Col span={24}>
              <FinancialTypeSelect
                size="large"
                onClear={() => handleFilterChange('financial-type-filter', '')}
                placeholder={t('accounts.table.type_financial_type_name')}
                onSelect={(value: string) =>
                  handleFilterChange('financial-type-filter', value || '')
                }
              />
            </Col>
          </Row>
        </Col>

        <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
          <Row>
            <Col span={24}>
              <Typography.Text>
                {t('accounts.table.filter_by_currency')}
              </Typography.Text>
            </Col>
            <Col span={24}>
              <StyledCurrencySelect
                size="large"
                onClear={() => handleFilterChange('currency-filter', '')}
                onSelect={(newValue: string) =>
                  handleFilterChange('currency-filter', newValue)
                }
                placeholder={t('accounts.table.type_currency_name')}
              />
            </Col>
          </Row>
        </Col>

        <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
          <Row>
            <Col span={24}>
              <Typography.Text>
                {t('accounts.table.filter_by_status')}
              </Typography.Text>
            </Col>
            <Col span={24}>
              <StyledBankAccountStatusSelect
                placeholder={t('accounts.table.type_account_status')}
                onChange={(value) => handleFilterChange('status', value)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const StyledCurrencySelect = styled(CurrencySelect)`
  width: 100%;
`;

const StyledBankAccountStatusSelect = styled(BankAccountStatusSelect)`
  width: 100%;
`;

export default FilterBar;
