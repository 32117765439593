import { FormValuesModel } from 'components/Forms/TemplateForms/CRM/ApplicationDocumentationForm';
import { AdditionalFieldHelpers } from '@helpers/additionalField';
import { AdditionalFieldType, OnboardType } from '../../../enums/crm/crm';
import {
  IOnboardingAnswer,
  IOnboardingAnswerResponse,
} from '../../../typings/crm/onboardingAnswer';
import { FormValuesModel as AdditionalDocumentModalFormValuesModel } from '../../../components/Modals/TemplateModalDialogs/CRM/AdditionalDocumentModalDialog';
import { FormValuesModel as UpdateAnswerOfAdditionalFieldFormValuesModel } from '../../../components/Modals/TemplateModalDialogs/CRM/AdditionalFieldAnswerModalDialog';
import {
  CreateAnswerOperation,
  RemoveAnswerOperation,
  FetchOnboardingAnswersResponseModel,
  ReviewAnswerOperation,
  ReviewApplicationDocumentationBodyModel,
  UpdateAnswerOperation,
  FillAnswerOperation,
} from '../../../api/crm/onboarding/onboardingAnswerAPI';

type IOnboardingAnswersResponse = {
  answers: { key: string; answers: IOnboardingAnswer[] }[];
};

function transformOptionsToOptionsData(
  values: AdditionalDocumentModalFormValuesModel,
) {
  switch (values.type) {
    case AdditionalFieldType.Form: {
      return {
        formDocumentId: values.options?.formDocument
          ? (values.options?.formDocument[0].id as string)
          : '',
      };
    }
    case AdditionalFieldType.Select: {
      return {
        selectOptions: values.options?.selectOptionsValues?.map((option) => ({
          valueJSON: JSON.stringify(option),
          type: 'string',
        })),
      };
    }
    case AdditionalFieldType.Document: {
      return {
        documentRequiresCertification:
          values.options?.documentRequiresCertification,
      };
    }

    default:
      return undefined;
  }
}

const onboardingAnswerAdapter = {
  fetchLatestOnboardingAnswers: (
    response: IOnboardingAnswersResponse,
  ): IOnboardingAnswerResponse => {
    const result: { [key: string]: IOnboardingAnswer[] } = {};

    response.answers.forEach((element) => {
      result[element.key] = element.answers;
    });

    return Object.keys(result).reduce((fields, key) => {
      return {
        ...fields,
        [key]: result[key][result[key].length - 1],
      };
    }, {});
  },

  fetchOnboardingAnswers: (
    response: FetchOnboardingAnswersResponseModel,
  ): IOnboardingAnswerResponse => {
    const result: { [key: string]: IOnboardingAnswer } = {};

    const { relationship_keyRelationshipTemplate } = response;
    if (relationship_keyRelationshipTemplate) {
      relationship_keyRelationshipTemplate.data.forEach((e) => {
        result[e.field.name] = {
          _id: e._id,
          itemId: e.itemId,
          itemType: e.itemType,
          clientGroupId: e.clientGroupId,
          keyRelationshipTemplateId: 'todo',

          field: {
            name: e.field.name,
            type: e.field.type,
            description: e.field.description,
            valueJSON: e.answer?.valueJSON,
          },

          review: e.review
            ? {
                isValid: e.review.isValid,
                comment: e.review.comment,
              }
            : undefined,
        } as IOnboardingAnswer;
      });
    }

    return result;
  },

  reviewApplicationDocumentation: (
    formValues: FormValuesModel,
    itemId: string,
    itemType: OnboardType,
  ): ReviewApplicationDocumentationBodyModel => {
    const { additionalFields } = formValues;
    const operations: ReviewAnswerOperation[] = additionalFields
      .filter((e) => !!e.answer && !e.isFinalized)
      .map((e) => {
        return {
          type: 'review',
          payload: {
            id: e._id,
            review: !e.notReviewed
              ? {
                  isValid: !!e.isApproved,
                  comment: e.reviewComment,
                }
              : null,
          },
        };
      });

    const result: ReviewApplicationDocumentationBodyModel = {
      itemId,
      itemType,
      operations,
    };

    switch (formValues.reviewProcess.type) {
      case 'contact-review':
        result.clientChangeRequestId = formValues.reviewProcess.entryId;
        break;

      case 'client-group-review':
        result.clientGroupId = formValues.reviewProcess.entryId;
        break;
    }

    return result;
  },

  addRequiredDocument: (
    formValues: AdditionalDocumentModalFormValuesModel,
  ): ReviewApplicationDocumentationBodyModel => {
    const createOperation: CreateAnswerOperation = {
      type: 'create',
      payload: {
        answer: null,
        field: {
          type: formValues.type,
          name: formValues.name,
          description: formValues.description,
          options: transformOptionsToOptionsData(formValues),
        },
      },
    };

    const result: ReviewApplicationDocumentationBodyModel = {
      itemId: formValues.itemId,
      itemType: formValues.itemType,
      operations: [createOperation],
    };

    switch (formValues.reviewProcess.type) {
      case 'contact-review':
        result.clientChangeRequestId = formValues.reviewProcess.entryId;
        break;

      case 'client-group-review':
        result.clientGroupId = formValues.reviewProcess.entryId;
        break;
    }

    return result;
  },

  removeRequiredDocument: (
    formValues: FormValuesModel,
    fieldId: string,
  ): ReviewApplicationDocumentationBodyModel => {
    const removeOperation: RemoveAnswerOperation = {
      type: 'remove',
      payload: {
        id: fieldId,
      },
    };

    const result: ReviewApplicationDocumentationBodyModel = {
      itemId: formValues.itemId,
      itemType: formValues.itemType,
      operations: [removeOperation],
    };

    switch (formValues.reviewProcess.type) {
      case 'contact-review':
        result.clientChangeRequestId = formValues.reviewProcess.entryId;
        break;

      case 'client-group-review':
        result.clientGroupId = formValues.reviewProcess.entryId;
        break;
    }

    return result;
  },

  fillAnswer: (
    formValues: UpdateAnswerOfAdditionalFieldFormValuesModel,
  ): ReviewApplicationDocumentationBodyModel => {
    const { additionalField, reviewProcess, selectedEntry } = formValues;
    const operation: FillAnswerOperation = {
      type: 'answer',
      payload: {
        id: additionalField.id,
        answer: {
          isProvided: additionalField.isNotProvidedReason ? false : true,
          valueJSON: additionalField.isNotProvidedReason
            ? undefined
            : AdditionalFieldHelpers.formatToJSON(
                additionalField.type,
                additionalField.value,
              ),
          reason: additionalField.isNotProvidedReason
            ? additionalField.notProvidedReason
            : undefined,
        },
      },
    };

    const result: ReviewApplicationDocumentationBodyModel = {
      itemId: selectedEntry.id,
      itemType: selectedEntry.type,
      operations: [operation],
    };

    switch (reviewProcess.type) {
      case 'contact-review':
        result.clientChangeRequestId = reviewProcess.entryId;
        break;

      case 'client-group-review':
        result.clientGroupId = reviewProcess.entryId;
        break;
    }

    return result;
  },

  updateRequiredDocument: (
    formValues: AdditionalDocumentModalFormValuesModel,
  ): ReviewApplicationDocumentationBodyModel => {
    const removeOperation: UpdateAnswerOperation = {
      type: 'update',
      payload: {
        id: formValues.fieldId as string,
        field: {
          type: formValues.type,
          name: formValues.name,
          description: formValues.description,
          options: transformOptionsToOptionsData(formValues),
        },
        // Admin cannot update answer
        answer: null,
      },
    };

    const result: ReviewApplicationDocumentationBodyModel = {
      itemId: formValues.itemId,
      itemType: formValues.itemType,
      operations: [removeOperation],
    };

    switch (formValues.reviewProcess.type) {
      case 'contact-review':
        result.clientChangeRequestId = formValues.reviewProcess.entryId;
        break;

      case 'client-group-review':
        result.clientGroupId = formValues.reviewProcess.entryId;
        break;
    }

    return result;
  },
};

export { onboardingAnswerAdapter };
