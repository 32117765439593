import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { Moment } from 'moment';
import { TWO_COL_GRID_SIZES } from 'constants/grids';
import { GenderOptions, SourceOfWealthCategories } from 'enums/crm/crm';
import { FormValuesModel as EmailFormValuesModel } from '../EmailAddressForm';
import { FormValuesModel as PhoneNumberItemFormValue } from '../PhoneNumberForm';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import SectionIntro from '@common_components/Texts/SectionIntro';
import FormDatePicker from '@common_components/Form/FormDatePicker';
import GenderFormSelect from 'components/Forms/FormComponents/SelectInputs/GenderFormSelect';
import CountryFormSelect from 'components/Forms/FormComponents/SelectInputs/CountryFormSelect';
import PhoneNumberSection from './PhoneNumberSection';
import EmailAddressSection from './EmailAddressSection';
import TrueFalseFormSelect from 'components/Forms/FormComponents/SelectInputs/TrueFalseFormSelect';
import NationalityFormSelect from 'components/Forms/FormComponents/SelectInputs/NationalityFormSelect';
import SourceOfWealthFormSelect from 'components/Forms/FormComponents/SelectInputs/SourceOfWealthFormSelect';
import { Col, Row } from 'antd';

// icons
import TwitterIcon from '../../../../../resources/images/social-media-icons/twitter.png';
import FacebookIcon from '../../../../../resources/images/social-media-icons/facebook.png';
import LinkedInIcon from '../../../../../resources/images/social-media-icons/linkedIn.png';

export type FormValuesModel = {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: GenderOptions | null;
  dateOfBirth: Moment | null;
  countryOfBirth: string | null;
  nationality: string[] | null;
  isInvitationPermitted: boolean | null;
  isVendor: boolean | null;
  sourceOfWealthCategories: SourceOfWealthCategories[] | null;
  sourceOfWealthDescription: string;
  emails: EmailFormValuesModel[];
  phoneNumbers: PhoneNumberItemFormValue[];
  socialMedia: {
    twitterLink: string;
    facebookLink: string;
    linkedInLink: string;
  };
  primary: {
    email: string | null;
    phoneNumber: string | null;
  };
  hasAssociationWithAuthUser: boolean;
};

const ContactDetailsForm = memo(() => {
  const { t } = useTranslation('crm');

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.new.firstName')}
            name="firstName"
            component={FormInput}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.new.middleName')}
            name="middleName"
            component={FormInput}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.new.lastName')}
            name="lastName"
            component={FormInput}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.new.gender')}
            name="gender"
            component={GenderFormSelect}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.new.dateOfBirth')}
            name="dateOfBirth"
            component={StyledFormDatePicker}
            additionalProps={{
              disabledDate: (d: any) => !d || d.isAfter(new Date()),
            }}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.new.countryOfBirth')}
            name="countryOfBirth"
            component={CountryFormSelect}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.new.nationality')}
            name="nationality"
            component={NationalityFormSelect}
            additionalProps={{ mode: 'multiple' }}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.new.invitation_permitted')}
            name="isInvitationPermitted"
            component={TrueFalseFormSelect}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.new.is_vendor')}
            name="isVendor"
            component={TrueFalseFormSelect}
          />
        </Col>
      </Row>
      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('contacts.new.emails')}
      />
      <EmailAddressSection />
      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('contacts.new.phoneNumbers')}
      />
      <PhoneNumberSection />
      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('contacts.new.additional_details')}
      />
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.new.sourceOfWealthCategoriesLabel')}
            name="sourceOfWealthCategories"
            component={SourceOfWealthFormSelect}
            additionalProps={{ mode: 'multiple' }}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.new.sourceOfWealth')}
            name="sourceOfWealthDescription"
            component={FormInput}
          />
        </Col>
      </Row>
      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('contacts.new.socialMedia')}
      />
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <SocialMediaIcon src={TwitterIcon} alt="Twitter icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name="socialMedia.twitterLink"
            component={FormInput}
            placeholder="https://twitter.com/username"
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <SocialMediaIcon src={FacebookIcon} alt="Facebook icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name="socialMedia.facebookLink"
            component={FormInput}
            placeholder="https://www.facebook.com/username"
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <SocialMediaIcon src={LinkedInIcon} alt="LinkedIn icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name="socialMedia.linkedInLink"
            component={FormInput}
            placeholder="https://www.linkedin.com/in/username"
          />
        </Col>
      </Row>
    </>
  );
});

const StyledFormDatePicker = styled(FormDatePicker)`
  width: 100%;
`;

const SocialMediaIcon = styled.img`
  width: 40px;
  height: auto;
`;

export default ContactDetailsForm;
