import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { ICurrency, INewCurrency } from '../../../typings/finance/currency';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';

interface ISearchCurrenciesParams extends FetchParamsModel {
  currencyQuery?: string;
  isAccount: 'all' | 'true' | 'false' | boolean;
  isExternal: 'all' | 'true' | 'false' | boolean;
  isActive: 'all' | 'true' | 'false' | boolean;
}

interface UpdateCurrencyBody {
  isActive: boolean;
  isAccount: boolean;
}

const currenciesAPI = {
  searchCurrencies: (params: ISearchCurrenciesParams) => {
    return APIService.get<FetchResponseModel<ICurrency>>(
      `${APIConfig.accountingApi}/currencies`,
      {
        params: { ...params },
      },
    ).then(({ data }) => data);
  },

  updateCurrency: (body: UpdateCurrencyBody, currencyId: number) => {
    return APIService.patch<FetchResponseModel<ICurrency>>(
      `${APIConfig.accountingApi}/currencies/${currencyId}`,
      body,
    ).then(({ data }) => data);
  },

  getCurrencyRateHome: (currencyId: number) => {
    return APIService.get(
      `${APIConfig.accountingApi}/currencies/rates/${currencyId}/home`,
      { params: { currencyId } },
    ).then(({ data }) => data.rate as number);
  },

  createCurrency: (newCurrency: INewCurrency) => {
    return new Promise((resolve) => setTimeout(() => resolve(true), 1000));
  },
};

export { currenciesAPI };
