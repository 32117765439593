import React, { memo, useCallback, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { darkTheme } from '../../../../../../resources/theme/styled';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { FormValuesModel, RelationshipWithNoteFormValuesModel } from '../';

// components
import IconSVG from '../../../../../DesignSystem/Core/IconSVG';
import AddEditNote from './AddEditNote';
import SectionIntro from '../../../../../DesignSystem/Common/Texts/SectionIntro';
import FormErrorTooltip from '../../../../../Forms/FormErrorTooltip';
import RelationshipForm from '../../../../../Forms/TemplateForms/CRM/RelationshipForm';
import HideIfDisabledForm from '../../../../../Forms/HideIfDisabledForm';
import { Button, Card, Col, Row } from 'antd';
import { ReactComponent as PlusIcon } from '../../../../../../resources/icons/remix-icons/add-line.svg';

const InnerForm = memo(() => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  // TODO: improve the logic, currently this is a template string that combines relationship template id and node id
  const selectedRelationshipTemplates = useMemo<string[]>(() => {
    return values && values.relationships
      ? values.relationships.reduce<string[]>((acc, next) => {
          if (next.relationshipTemplate.id && next.child.id) {
            acc.push(`${next.relationshipTemplate.id}-${next.child.id}`);
          }

          return acc;
        }, [])
      : [];
  }, [values.relationships]);

  const renderRelationships = useCallback(
    (fieldArrayRenderProps: FieldArrayRenderProps) => {
      const newRelationship: RelationshipWithNoteFormValuesModel = {
        id: null,

        relationshipTemplate: {
          id: null,
          childRelationshipLabel: '',
        },

        parent: {
          id: '',
          type: values.entryType,
        },

        child: {
          id: null,
          type: null,
          label: '',
        },

        additionalFields: [],
      };

      return (
        <>
          {values.relationships &&
            values.relationships.map((_, i) => (
              <StyledCard key={i}>
                <StyledRow justify="end">
                  <Col>
                    <AddEditNote fieldName={`relationships.${i}`} />
                  </Col>
                </StyledRow>
                <RelationshipForm
                  entryId={values.entryId}
                  fieldName={`relationships.${i}`}
                  entryType={values.entryType}
                  removeCallback={() => fieldArrayRenderProps.remove(i)}
                  relationshipIndex={i + 1}
                />
              </StyledCard>
            ))}

          <HideIfDisabledForm hideIfSubmitting>
            <StyledAddButton
              onClick={() => fieldArrayRenderProps.push(newRelationship)}
            >
              <StyledIconSVG
                component={PlusIcon}
                color={darkTheme.whiteColor}
              />
              {t('entity.relationships.relationship_modal.add_new_button')}
            </StyledAddButton>
          </HideIfDisabledForm>
        </>
      );
    },
    [values, selectedRelationshipTemplates],
  );

  return (
    <>
      <SectionIntro
        title={t('entity.relationships.relationship_modal.section_title')}
        titleVariant="h4"
      />
      <StyledErrorWrapper>
        <FormErrorTooltip<FormValuesModel> errorKey="relationships" />
      </StyledErrorWrapper>
      <FieldArray name="relationships" render={renderRelationships} />
    </>
  );
});

const StyledAddButton = styled(Button)`
  display: flex;
  align-items: center;
  padding-top: 0;
`;

const StyledCard = styled(Card)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const StyledErrorWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXXs};
`;

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default InnerForm;
