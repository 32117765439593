import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../../hooks/useFetch';
import useTranslation from '../../../../../hooks/useTranslation';
import { contactsAPI } from '../../../../../api/crm/contact/contactsAPI';
import { UserFullModel } from '../../../../../typings/ORAC/users';
import { ContactHelpers } from '@helpers/crm/contact';
import {
  ContactPhoneNumber,
  IContactModel,
} from '../../../../../typings/crm/contact';

// components
import Link from '../../../../../components/Typography/Link';
import VerifiedStatusText from '../../../../../components/Typography/VerifiedStatusText';
import ClientLockoutsStatus from 'components/Additional/Statuses/ClientLockoutsStatus';
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';
import { Typography } from 'antd';

interface IProps {
  user: UserFullModel;
}

const UserInfo = ({ user }: IProps) => {
  const { t } = useTranslation('orac');

  const { response: contact } = useFetch<IContactModel>(
    () => contactsAPI.findContact(user._id),
    [user._id],
  );

  const getPhoneNumber = (contact: IContactModel) => {
    const renderPhoneContainer = (
      phoneNumber: ContactPhoneNumber | undefined,
    ) => (
      <>
        <FieldValue>
          <StyledText>{phoneNumber?.number}</StyledText>
        </FieldValue>
        <StatusContainer>
          <VerifiedStatusText
            isVerified={phoneNumber?.verification?.isVerified}
          />
        </StatusContainer>
      </>
    );
    if (contact.phoneNumbers.length && contact.phoneNumbers.length === 1) {
      return renderPhoneContainer(contact.phoneNumbers[0]);
    } else {
      const primaryPhone = contact.phoneNumbers?.find(
        ({ isPrimary }) => isPrimary,
      );
      return renderPhoneContainer(primaryPhone);
    }
  };

  const renderEmail = () => {
    return (
      <>
        <FieldValue>
          <StyledText>{user?.email}</StyledText>
        </FieldValue>
        <StatusContainer>
          <VerifiedStatusText isVerified={user.emailConfirmed} />
        </StatusContainer>
      </>
    );
  };

  const descriptionsData = useMemo<DescriptionItem[]>(
    () => [
      { label: t('users.view.id'), description: user._id },
      {
        label: t('users.view.firstName'),
        description: contact && contact.firstName,
      },
      {
        label: t('users.view.middleName'),
        description: contact && contact.middleName,
      },
      {
        label: t('users.view.lastName'),
        description: contact && contact.lastName,
      },
      {
        label: t('users.view.contact'),
        description: contact ? (
          <Link to={`/crm/contacts/edit?id=${contact._id}`}>
            {ContactHelpers.getContactName(contact)}
          </Link>
        ) : null,
      },
      { label: t('users.view.username'), description: user.username },
      { label: t('users.view.email'), description: renderEmail() },
      {
        label: t('users.view.phone'),
        description: contact && getPhoneNumber(contact),
      },
      {
        label: t('users.view.status'),
        description: <ClientLockoutsStatus status={user.status} />,
      },
      // No such data in response
      // { label: t('users.view.created_at'), description: DateHelpers.formatDateToString(user.createdAt as string, DEFAULT_FULL_DATE_FORMAT) },
      // { label: t('users.view.updated_at'), description: DateHelpers.formatDateToString(user.updatedAt as string, DEFAULT_FULL_DATE_FORMAT) },
    ],
    [contact, user],
  );

  return (
    <StyledDescriptionSection
      data={descriptionsData}
      bordered={false}
      background="transparent"
    />
  );
};

const StyledDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-container {
    border-bottom: 1px solid ${({ theme }) => theme.tableBorderColor};
    padding-bottom: ${({ theme }) => theme.paddingXs};
  }

  .ant-descriptions-item-label {
    display: flex;
    align-items: end;
  }

  &.ant-descriptions-middle .ant-descriptions-row > td {
    padding-bottom: ${({ theme }) => theme.paddingXs};
  }
`;

const StyledText = styled(Typography.Text)`
  font-weight: 400;
`;

const StatusContainer = styled.div`
  display: inline;
  margin-left: ${({ theme }) => theme.marginSm};
`;

const FieldValue = styled.span`
  color: ${({ theme }) => theme.textLightColor2};
  font-weight: 600;
`;

export default UserInfo;
