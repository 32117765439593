import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { UnlockUserValidationSchema } from 'validations/orac/users';

// components
import InnerForm from './InnerForm';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export type FormValuesModel = {
  reason: string;
};

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {}

const UnlockModalDialog = ({
  isVisible,
  onSubmit,
  initialValues,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation(['orac', 'common']);
  return (
    <Form
      enableReinitialize
      confirmExitWithoutSaving={false}
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={UnlockUserValidationSchema}
      renderForm={
        <ModalDialog
          width={800}
          title={t('users.view.lockout_details_table.confirmation_modal.title')}
          isVisible={isVisible}
          closeCallback={closeCallback}
          submitButtonProps={{ text: t('confirm', { ns: 'common' }) }}
          cancelButtonProps={{ text: t('cancel', { ns: 'common' }) }}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default UnlockModalDialog;
