import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { FormikProps } from 'formik';
import { NoteAssociation } from '../../../../../typings/note/note';
import { AutocompleteOption } from '@core_components/Autocomplete';
import { DocumentFieldValueModel } from 'components/Forms/FormComponents/DocumentsField';
import { NewNoteValidationSchema } from '../../../../../validations/notes';

// components
import InnerForm from './InnerForm';
import AsyncButton from '@common_components/Buttons/AsyncButton';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title?: string;
  loading?: boolean;
  hideNoteTypeField?: boolean;
  hideAddSameNoteToField?: boolean;
  onDelete?: (form: FormikProps<FormValuesModel>) => Promise<void> | void;
}

export type FormValuesModel = {
  text: string;
  eventDate: any;
  type?: string;
  crmItems?: NoteAssociation[];
  documents?: DocumentFieldValueModel[];
  crmItemsInitialValue?: AutocompleteOption[];
};

const NoteModalDialog = ({
  title,
  isVisible,
  closeCallback,
  onSubmit,
  hideNoteTypeField,
  hideAddSameNoteToField,
  loading,
  initialValues,
  disabled,
  onDelete,
}: IProps) => {
  const { t } = useTranslation(['crm', 'common']);

  return (
    <Form
      disabled={disabled}
      enableReinitialize
      confirmExitWithoutSaving
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={NewNoteValidationSchema}
      renderForm={(form) => (
        <ModalDialog
          width={800}
          title={title || t('entity.notes.addNote')}
          isVisible={isVisible}
          closeCallback={closeCallback}
          submitButtonProps={{ hidden: disabled }}
          extendedFooterButtons={
            onDelete ? (
              <AsyncButton
                danger
                type="bordered"
                size="large"
                onClick={() => onDelete(form)}
              >
                {t('delete', { ns: 'common' })}
              </AsyncButton>
            ) : undefined
          }
        >
          <LoadingWrapper loading={!!loading}>
            <InnerForm
              showNoteType={!hideNoteTypeField}
              showAddSameNoteToField={!hideAddSameNoteToField}
            />
          </LoadingWrapper>
        </ModalDialog>
      )}
    />
  );
};

export default NoteModalDialog;
