import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { styled } from 'styled-components';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { DateHelpers } from '@helpers/date';
import { ClientLockoutStatus } from 'enums/orac/orac';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { ClientLockoutFromQuery } from 'api/orac/clientLockoutsAPI';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';

// components
import TableActions from 'components/Tables/TableActions';
import ClientLockoutsStatus from 'components/Additional/Statuses/ClientLockoutsStatus';
import FilterDropdownOverlay from 'components/Tables/FilterDropdownOverlay';
import ContactFormAutocomplete from 'components/Forms/FormComponents/Autocompletes/CRM/ContactFormAutocomplete';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<ClientLockoutFromQuery> {
  filters: Record<string, any>;
  onActionsClick: (type: string, data: string) => void;
  onFilterChange: (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => void;
}

export type TableActions = 'details';

const ClientLockoutsTable = ({
  filters,
  onActionsClick,
  onFilterChange,
  ...rest
}: IProps) => {
  const { t } = useTranslation('orac');
  const history = useHistory();

  const ClientLockoutStatuses = useMemo(
    () => [
      {
        title: t(
          `client_lockout_details.statuses.${ClientLockoutStatus.ACTIVE}`,
        ),
        value: ClientLockoutStatus.ACTIVE,
      },
      {
        title: t(
          `client_lockout_details.statuses.${ClientLockoutStatus.LOCKED}`,
        ),
        value: ClientLockoutStatus.LOCKED,
      },
    ],
    [],
  );

  const renderActionsComponent = (record: ClientLockoutFromQuery) => {
    return (
      <TableActions
        onSelect={(key: TableActions) => onActionsClick(key, record.userId)}
        menu={[
          {
            key: 'details',
            name:
              record.lockoutStatus === ClientLockoutStatus.LOCKED
                ? t('client_lockout_details.table.unlock_button')
                : t('client_lockout_details.table.view_button'),
          },
        ]}
      />
    );
  };

  const columns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        key: 'username',
        width: 170,
        title: t('client_lockout_details.table.username'),
        render: (record: ClientLockoutFromQuery) => (
          <div>{record.username}</div>
        ),
      },
      {
        key: 'contact',
        width: 170,
        title: t('client_lockout_details.table.contactName'),
        render: (record: ClientLockoutFromQuery) => (
          <Typography.Link
            onClick={() =>
              history.push({
                pathname: RoutePaths.CRM_Contacts_Edit,
                search: `?id=${record.contactId}`,
              })
            }
          >
            {record.contactName}
          </Typography.Link>
        ),
        // filter
        filterMultiple: true,
        filteredValue: filters.contactIds,
        filterDropdown: (filterProps: FilterDropdownProps) => {
          return (
            <FilterDropdownOverlay filterProps={filterProps}>
              <StyledContactAutocomplete
                mode="multiple"
                value={filterProps.selectedKeys as any}
                onChange={(value) => filterProps.setSelectedKeys(value as any)}
              />
            </FilterDropdownOverlay>
          );
        },
      },
      {
        key: 'reason',
        width: 280,
        title: t('client_lockout_details.table.reason'),
        render: (record: ClientLockoutFromQuery) => record.lockoutReason,
      },
      {
        key: 'timestamp',
        width: 170,
        title: t('client_lockout_details.table.timestamp'),
        render: (record: ClientLockoutFromQuery) => (
          <div>
            {DateHelpers.formatDateToString(
              record.lockoutTimestamp,
              DEFAULT_DATE_TIME_FORMAT,
            )}
          </div>
        ),
      },
      {
        key: 'lockout_status',
        width: 170,
        title: t('client_lockout_details.table.status'),
        render: (record: ClientLockoutFromQuery) => (
          <ClientLockoutsStatus
            status={record.lockoutStatus as ClientLockoutStatus}
          />
        ),
        filteredValue: filters.status,
        filterSearch: true,
        filterMultiple: true,
        filters: ClientLockoutStatuses.map((e) => ({
          text: e.title,
          value: e.value,
        })),
      },
      {
        key: 'actions',
        width: 150,
        align: 'right',
        render: renderActionsComponent,
      },
    ];
  }, [filters, history]);

  return <Table columns={columns} onFilterChange={onFilterChange} {...rest} />;
};

const StyledContactAutocomplete = styled(ContactFormAutocomplete)`
  width: 100%;
`;

export default ClientLockoutsTable;
