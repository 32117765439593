import React from 'react';

// components
import { Tag as TagAntD, TagProps } from 'antd';

interface IProps extends TagProps {
  tag: string;
  onRemove?: (tag: string) => void;
}

const Tag = ({ tag, closable, onRemove }: IProps) => {
  return (
    <TagAntD closable={closable} onClose={() => onRemove && onRemove(tag)}>
      {tag}
    </TagAntD>
  );
};

export default Tag;
