import React, { memo, useCallback, useMemo, useState } from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';
import { contactsAPI } from 'api/crm/contact/contactsAPI';
import { FormikHelpers } from 'formik';
import { IContactModel } from 'typings/crm/contact';
import { ContactHelpers } from '@helpers/crm/contact';
import { contactsAdapter } from 'apiAdapters/crm/contact/contactsAdapter';
import { Divider, message } from 'antd';
import { ContactDetailsFormValidationSchema } from 'validations/crm/contacts';

// components
import Form from '@core_components/Form';
import SectionIntro from '@common_components/Texts/SectionIntro';
import DeleteContact from './DeleteContact';
import FormEditModeButtons from '@common_components/Form/FormEditModeButtons';
import ContactDetailsForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/CRM/ContactDetailsForm';

interface IProps {
  contact: IContactModel;
  updateContactData: (updatedModel: IContactModel) => void;
}

const ContactInformation = memo(({ contact, updateContactData }: IProps) => {
  const { t } = useTranslation('crm');
  const [isDisabled, setIsDisabled] = useState(true);
  const [hasUpdateAccess, hasDeleteAccess] = useUserAccess([
    'CRM_Contacts_Details_DetailsTab_Update',
    'CRM_Contacts_Details_DetailsTab_Delete',
  ]);

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    return contactsAdapter.formatContactDataFromQueryToForm(
      contact,
      !!contact?.associations?.auth,
    );
  }, [contact]);

  const handleSubmit = useCallback(
    async (
      values: FormValuesModel,
      helpers: FormikHelpers<FormValuesModel>,
    ) => {
      const errors = await ContactHelpers.validatePhoneNumbers(
        values.phoneNumbers.map((phone) => phone.number),
      );
      if (errors) {
        values.phoneNumbers.forEach((phone, index) => {
          if (errors[phone.number]) {
            helpers.setFieldError(
              `phoneNumbers.${index}.number`,
              errors[phone.number].errorMessage,
            );
          }
        });
      } else {
        const formattedBody =
          contactsAdapter.formatContactDataFromFormToQuery(values);
        const response = await contactsAPI.editContact(
          contact._id,
          formattedBody,
        );
        message.success(t('contacts.new.editSuccess'));
        setIsDisabled(true);
        updateContactData(response);
      }
    },
    [contact, updateContactData],
  );

  return (
    <>
      {initialFormValues && (
        <Form
          disabled={isDisabled}
          enableReinitialize
          confirmExitWithoutSaving
          onSubmit={handleSubmit}
          initialValues={initialFormValues}
          validationSchema={ContactDetailsFormValidationSchema}
          renderForm={
            <>
              {hasUpdateAccess && (
                <FormEditModeButtons
                  isDisabled={isDisabled}
                  setDisabledStatus={setIsDisabled}
                />
              )}
              <SectionIntro
                titleVariant="h4"
                title={t('contacts.new.contactDetails')}
              />
              <ContactDetailsForm />
            </>
          }
        />
      )}
      <Divider />
      {hasDeleteAccess && <DeleteContact contact={contact} />}
    </>
  );
});

export default ContactInformation;
