import React, { useMemo, useState } from 'react';

// helpers
import usersAPI from 'api/orac/usersAPI';
import ApiConfig from 'config/api';
import useTranslation from '../../../../hooks/useTranslation';
import { UserTypes } from 'enums/admin/orac';
import { NewUserModel } from 'typings/ORAC/users';
import { UserFormValuesModel } from 'components/Forms/TemplateForms/ORAC/UserForm';

// components
import UserModalDialog from 'components/Modals/TemplateModalDialogs/ORAC/Users/UserModalDialog';
import { Button, message } from 'antd';
interface IProps {
  onAdd?: () => void;
}

const AddUser = ({ onAdd }: IProps) => {
  const { t } = useTranslation('orac');
  const [isModalVisible, setVisibleModal] = useState(false);

  const initialFormValues = useMemo(() => {
    if (!isModalVisible) {
      return null;
    }

    return {
      type: null,
      contactId: null,
      firstName: null,
      middleName: null,
      lastName: null,
      availableEmails: null,
      availablePhoneNumbers: null,
      selectedEmail: null,
      selectedPhoneNumber: null,
    };
  }, [isModalVisible]);

  const closeCallback = (wasAdded?: boolean) => {
    if (wasAdded) {
      onAdd && onAdd();
    }

    setVisibleModal(false);
  };

  const handleSubmit = async (values: UserFormValuesModel) => {
    const formattedBody: NewUserModel = {
      isAdmin: values.type === UserTypes.Admin,
      email: values.selectedEmail || '',
      phoneNumber: values.selectedPhoneNumber || '',
      contactId: values.contactId || '',
      callbackUrl: `${ApiConfig.clientUrl}/auth/activate-account`,
      parameterName: 'token',
    };
    await usersAPI.createUser(formattedBody);
    message.success(t('users.add.success'));
  };

  return (
    <>
      <Button type="primary" onClick={() => setVisibleModal(true)}>
        {t('users.add.title')}
      </Button>
      <UserModalDialog
        title={t('users.add.title')}
        isVisible={isModalVisible}
        closeCallback={closeCallback}
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default AddUser;
