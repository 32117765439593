import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '../../../../../hooks/useTranslation';
import { enumToMap } from '@helpers/utils';
import { DateHelpers } from '@helpers/date';
import { Transaction } from '../../../../../typings/banking/transactions';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import {
  TransactionReviewStatuses,
  TransactionTypes,
} from '../../../../../enums/banking/transactions';

// components
import AmountField from 'components/Forms/FormComponents/AmountField';
import TableActions from '../../../TableActions';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import TransactionStatus from '../../../../Typography/TransactionStatus';
import DrillDownFilterIcon from 'components/Additional/DrillDownFilterIcon';
import TemplateAutocomplete from 'components/Forms/FormComponents/Autocompletes/Banking/TemplateAutocomplete';
import FilterDropdownOverlay from 'components/Tables/FilterDropdownOverlay';
import BeneficiaryAutocomplete from 'components/Forms/FormComponents/Autocompletes/Finance/BeneficiaryAutocomplete';
import OrderingCustomerAutocomplete from 'components/Forms/FormComponents/Autocompletes/Finance/OrderingCustomerAutocomplete';
import Table, {
  RequiredPropsForTableModel,
  SortProps,
  TableColumnModel,
} from '@core_components/Table';
import { Col, DatePicker, Row } from 'antd';

export type TableActions = 'view' | 'send_to_rdc';

interface IProps extends RequiredPropsForTableModel<Transaction> {
  filters: Record<string, any>;
  onSortChange?: (sorter: SortProps) => void;
  onFilterChange?: (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => void;
  onActionsClick?: (key: TableActions, record: Transaction) => void;
}

const TransactionsTable = memo(
  ({ onActionsClick, onFilterChange, filters, ...rest }: IProps) => {
    const { t } = useTranslation(['banking', 'common']);
    const [hasEditAccess, hasStartRDCAccess] = useUserAccess([
      'Banking_Transactions_Update',
      'Banking_Transactions_SendToRDC',
    ]);

    const renderActionsComponent = (record: Transaction) => {
      if (!onActionsClick) {
        return null;
      }

      const labelOfViewAction = !hasEditAccess
        ? t('banking.transactions.table.actions.view')
        : record.reviewStatus === TransactionReviewStatuses.Suspense ||
            record.reviewStatus === TransactionReviewStatuses.PendingTransmit
          ? t('banking.transactions.table.actions.edit')
          : t('banking.transactions.table.actions.view');

      return (
        <TableActions
          onSelect={(key: TableActions) => onActionsClick(key, record)}
          menu={[
            {
              key: 'view',
              name: labelOfViewAction,
            },

            {
              key: 'send_to_rdc',
              name: t('banking.transactions.table.actions.send_to_rdc'),
              hidden:
                !hasStartRDCAccess ||
                record.reviewStatus !== TransactionReviewStatuses.PendingRDC,
            },
          ]}
        />
      );
    };

    const columns = useMemo(() => {
      function handleAmountChange(
        field: 'from' | 'to',
        value: number,
        filterProps: FilterDropdownProps,
        currentValue: number[] | undefined,
      ) {
        const newValue = currentValue
          ? [...currentValue]
          : [undefined, undefined];

        switch (field) {
          case 'from':
            {
              newValue[0] = value;
              if (typeof newValue[1] !== 'undefined' && value > newValue[1]) {
                newValue[1] = value;
              }
            }
            break;

          case 'to':
            {
              newValue[1] = value;
              if (typeof newValue[0] !== 'undefined' && value < newValue[0]) {
                newValue[0] = value;
              }
            }
            break;
        }

        filterProps.setSelectedKeys(newValue as any);
      }

      const result: TableColumnModel[] = [
        {
          key: 'transactionId',
          width: 200,
          title: t('banking.transactions.table.transaction_id'),
          render: (record: Transaction) =>
            record.type === TransactionTypes.Outgoing
              ? record.transactionNumber
              : '',
        },

        {
          key: 'type',
          width: 200,
          title: t('banking.transactions.table.type'),
          render: (record: Transaction) =>
            t(`banking.transactions.transaction_types.${record.type}`),
          filteredValue: filters.type,
          filterSearch: true,
          filterMultiple: false,
          filters: Array.from(enumToMap(TransactionTypes)).map((e) => ({
            text: t(`banking.transactions.transaction_types.${e[1]}`),
            value: e[1],
          })),
        },

        {
          key: 'status',
          width: 200,
          title: t('banking.transactions.table.status'),
          render: (record: Transaction) => (
            <TransactionStatus status={record.reviewStatus} />
          ),
          // --- filter ---
          filteredValue: filters.status,
          filterSearch: true,
          filters: Array.from(enumToMap(TransactionReviewStatuses)).map(
            (el) => ({
              text: t(
                `banking.transactions.transaction_review_statuses.${el[0]}`,
              ),
              value: el[1],
            }),
          ),
        },

        {
          key: 'requested_date',
          width: 200,
          title: t('banking.transactions.table.requested_date'),
          render: (record: Transaction) =>
            DateHelpers.formatTimestampToString(record.creationDate),
          // --- sorter ---
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          showSorterTooltip: false,
          // --- filter ---
          filteredValue: filters.requestedDate,
          filterDropdown: (filterProps: FilterDropdownProps) => {
            return (
              <FilterDropdownOverlay filterProps={filterProps}>
                <StyledRangePicker
                  allowClear
                  size="large"
                  value={filterProps.selectedKeys as any}
                  onChange={(value) =>
                    filterProps.setSelectedKeys(value as any)
                  }
                />
              </FilterDropdownOverlay>
            );
          },
        },

        {
          key: 'value_date',
          width: 200,
          title: t('banking.transactions.table.value_date'),
          render: (record: Transaction) =>
            DateHelpers.formatTimestampToString(record.valueDate),
          // --- sorter ---
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          showSorterTooltip: false,
          // --- filter --
          filteredValue: filters.valueDate,
          filterDropdown: (filterProps: FilterDropdownProps) => {
            return (
              <FilterDropdownOverlay filterProps={filterProps}>
                <StyledRangePicker
                  allowClear
                  size="large"
                  value={filterProps.selectedKeys as any}
                  onChange={(value) =>
                    filterProps.setSelectedKeys(value as any)
                  }
                />
              </FilterDropdownOverlay>
            );
          },
        },

        {
          width: 300,
          title: t('banking.transactions.table.ordering_customer'),
          key: 'ordering_customer',
          render: (record: Transaction) => {
            return (
              <DivAlignCenter>
                <EllipsisTooltip
                  title={record.orderingCustomer}
                  maxTextContainerWidth="270px"
                >
                  {record.orderingCustomer}
                </EllipsisTooltip>
                {onFilterChange ? (
                  <DrillDownFilterIcon
                    onClick={() =>
                      onFilterChange({
                        ordering_customer: [record.orderingCustomer],
                      } as any)
                    }
                  />
                ) : null}
              </DivAlignCenter>
            );
          },
          filteredValue: filters.orderingCustomer,
          filterDropdown: (filterProps: FilterDropdownProps) => (
            <FilterDropdownOverlay filterProps={filterProps}>
              <StyledOrderingCustomerAutocomplete
                mode="multiple"
                value={filterProps.selectedKeys as any}
                onChange={(value) => filterProps.setSelectedKeys(value as any)}
              />
            </FilterDropdownOverlay>
          ),
        },

        {
          key: 'template',
          width: 300,
          title: t('banking.transactions.table.template'),
          render: (record: Transaction) => (
            <EllipsisTooltip
              title={record.template}
              maxTextContainerWidth="300px"
            >
              {record.template}
            </EllipsisTooltip>
          ),
          filteredValue: filters.templateIds,
          filterDropdown: (filterProps: FilterDropdownProps) => {
            return (
              <FilterDropdownOverlay filterProps={filterProps}>
                <StyledTemplateAutocomplete
                  mode="multiple"
                  value={filterProps.selectedKeys as any}
                  onChange={(value) =>
                    filterProps.setSelectedKeys(value as any)
                  }
                />
              </FilterDropdownOverlay>
            );
          },
        },

        {
          width: '300px',
          title: t('banking.transactions.table.beneficiary'),
          key: 'beneficiary',
          render: (record: Transaction) =>
            record.beneficiary ? (
              <DivAlignCenter>
                <EllipsisTooltip
                  title={record.beneficiary}
                  maxTextContainerWidth="270px"
                >
                  {record.beneficiary}
                </EllipsisTooltip>
                {onFilterChange ? (
                  <DrillDownFilterIcon
                    onClick={() =>
                      onFilterChange({
                        beneficiary: [record.beneficiary],
                      } as any)
                    }
                  />
                ) : null}
              </DivAlignCenter>
            ) : null,
          filteredValue: filters.beneficiary,
          filterDropdown: (filterProps: FilterDropdownProps) => {
            return (
              <FilterDropdownOverlay filterProps={filterProps}>
                <StyledBeneficiaryAutocomplete
                  mode="multiple"
                  value={filterProps.selectedKeys as any}
                  onChange={(value) =>
                    filterProps.setSelectedKeys(value as any)
                  }
                />
              </FilterDropdownOverlay>
            );
          },
        },

        {
          width: '300px',
          title: t('banking.transactions.table.amount'),
          key: 'amount',
          render: (record: Transaction) => record.amount,
          filteredValue: filters.amount,
          filterDropdown: (filterProps: FilterDropdownProps) => {
            const value = filterProps.selectedKeys as any;
            return (
              <FilterDropdownOverlay filterProps={filterProps}>
                <Row wrap={false} justify="space-between" align="middle">
                  <Col flex="48%">
                    <StyledInputNumber
                      size="large"
                      placeholder={t('enter_from', { ns: 'common' })}
                      value={value ? value[0] : undefined}
                      onChange={(newValue) =>
                        handleAmountChange('from', newValue, filterProps, value)
                      }
                    />
                  </Col>
                  <Col flex="48%">
                    <StyledInputNumber
                      size="large"
                      placeholder={t('enter_to', { ns: 'common' })}
                      value={value ? value[1] : undefined}
                      onChange={(newValue) =>
                        handleAmountChange('to', newValue, filterProps, value)
                      }
                    />
                  </Col>
                </Row>
              </FilterDropdownOverlay>
            );
          },
        },

        {
          width: '150px',
          key: 'actions',
          align: 'right',
          render: renderActionsComponent,
        },
      ];

      return result;
    }, [
      onActionsClick,
      onFilterChange,
      filters,
      hasEditAccess,
      hasStartRDCAccess,
    ]);

    return (
      <Table {...rest} onFilterChange={onFilterChange} columns={columns} />
    );
  },
);

const StyledInputNumber = styled(AmountField)`
  width: 100%;
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;

const StyledOrderingCustomerAutocomplete = styled(OrderingCustomerAutocomplete)`
  width: 100%;
`;

const StyledBeneficiaryAutocomplete = styled(BeneficiaryAutocomplete)`
  width: 100%;
`;

const StyledTemplateAutocomplete = styled(TemplateAutocomplete)`
  width: 100%;
`;

export default TransactionsTable;
