import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { ClientLockoutStatus } from 'enums/orac/orac';
import { ClientLockoutFromQuery } from 'api/orac/clientLockoutsAPI';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';

// components
import Button from '@core_components/Button';
import ClientLockoutsStatus from 'components/Additional/Statuses/ClientLockoutsStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

interface IProps extends RequiredPropsForTableModel<ClientLockoutFromQuery> {
  onActionsClick: (type: string, data: string) => void;
}

const LockoutDetailsTable = ({ onActionsClick, ...rest }: IProps) => {
  const { t } = useTranslation('orac');

  const columns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        key: 'type',
        title: t('users.view.lockout_details_table.type'),
        render: (record: ClientLockoutFromQuery) => (
          <div>{t(`client_lockout_details.types.${record.lockoutType}`)}</div>
        ),
      },
      {
        key: 'timestamp',
        title: t('users.view.lockout_details_table.timestamp'),
        render: (record: ClientLockoutFromQuery) => (
          <div>
            {DateHelpers.formatDateToString(
              record.lockoutTimestamp,
              DEFAULT_DATE_TIME_FORMAT,
            )}
          </div>
        ),
      },
      {
        key: 'status',
        title: t('users.view.lockout_details_table.status'),
        render: (record: ClientLockoutFromQuery) => (
          <ClientLockoutsStatus
            status={record.lockoutStatus as ClientLockoutStatus}
          />
        ),
      },
      {
        key: 'actions',
        render: (record: ClientLockoutFromQuery) =>
          record.lockoutStatus === ClientLockoutStatus.LOCKED ? (
            <Button onClick={() => onActionsClick('unlock', record.id)}>
              {t('users.view.lockout_details_table.unlock_button')}
            </Button>
          ) : null,
      },
    ];
  }, []);

  return <Table columns={columns} {...rest} />;
};

export default LockoutDetailsTable;
