import React, { useEffect, useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { StateModel } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { approvalAPI } from '../../../../api/crm/onboarding/approvalAPI';
import { ApprovalHelpers } from '@helpers/approval/approval';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ApprovalsMatrix from '../ApprovalsMatrix';
import WorkflowActions from '../WorkflowActions';

interface IProps {
  workflowId: string;
  onUpdate: () => void;
}

const SeniorManagementWorkflow = ({ workflowId, onUpdate }: IProps) => {
  const currentUserId = useSelector<StateModel, string>(
    (state) => state.auth.profileData?._id || '',
  );

  const [updateMatrixTrigger, updateMatrix] = useState({});
  const [shouldApproveOrReject, setShouldApproveOrReject] =
    useState<boolean>(false);

  const { response, loading } = useFetch(
    () =>
      workflowId
        ? approvalAPI.fetchSeniorManagamentWorkflowStatuses(workflowId)
        : null,
    [updateMatrixTrigger, workflowId],
  );

  useEffect(() => {
    if (response) {
      const approvalsArray = response?.approvalProgressStateSets
        ? response.approvalProgressStateSets.map((e) => e.progressStateItems)
        : [];

      const result = ApprovalHelpers.userHasPendingApproval(
        currentUserId,
        approvalsArray,
        response?.status,
      );

      setShouldApproveOrReject(result);
    }
  }, [response?.approvalProgressStateSets]);

  const handleApprove = async (reason: string) => {
    await approvalAPI.approveSeniorManagamentWorkflow(workflowId, reason);
    onUpdate();
    updateMatrix({});
  };

  const handleReject = async (reason: string) => {
    await approvalAPI.rejectSeniorManagamentWorkflow(workflowId, reason);
    onUpdate();
    updateMatrix({});
  };

  return (
    <LoadingWrapper loading={loading}>
      {!!response && (
        <ApprovalsMatrix
          approvalProgressStateSets={response.approvalProgressStateSets}
        />
      )}
      <WorkflowActions
        canReject={shouldApproveOrReject}
        canApprove={shouldApproveOrReject}
        onApprove={handleApprove}
        onReject={handleReject}
      />
    </LoadingWrapper>
  );
};

export default SeniorManagementWorkflow;
