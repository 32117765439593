export interface IApiInstance {
  authPublicApi: string;
  authApi: string;
  crmApi: string;
  accountingApi: string;
  dmsApi: string;
  notesApi: string;
  notificationApi: string;
  messagingApi: string;
  approvalApi: string;
  azureInstrumentationKey: string;
  clientUrl: string;
  oracApi: string;
  amlApi: string;
}

const dev: IApiInstance = {
  authPublicApi:
    'https://api-dev.tenetos.dev/facade-admin/public/authentication',
  authApi: 'https://api-dev.tenetos.dev/facade-admin/admin/authentication',
  oracApi: 'https://api-dev.tenetos.dev/facade-admin/admin/orac',
  crmApi: 'https://api-dev.tenetos.dev/facade-admin/admin/crm',
  amlApi: 'https://api-dev.tenetos.dev/facade-admin/admin/aml',
  approvalApi: 'https://api-dev.tenetos.dev/facade-admin/admin/approval',
  accountingApi: 'https://api-dev.tenetos.dev/facade-admin/admin/accounting',
  dmsApi: 'https://api-dev.tenetos.dev/facade-admin/admin/documents',
  notesApi: 'https://api-dev.tenetos.dev/facade-admin/admin/notes',
  notificationApi:
    'https://api-dev.tenetos.dev/facade-admin/admin/notification',
  messagingApi: 'https://api-dev.tenetos.dev/facade-admin/admin/messaging',
  clientUrl: 'https://ops-dev.tenetos.dev',
  azureInstrumentationKey: 'dc4c8f6c-ecd6-4e20-8b24-8ec7f86a041b',
};

const qa: IApiInstance = {
  authPublicApi:
    'https://api-qa.tenetos.dev/facade-admin/public/authentication',
  authApi: 'https://api-qa.tenetos.dev/facade-admin/admin/authentication',
  oracApi: 'https://api-qa.tenetos.dev/facade-admin/admin/orac',
  crmApi: 'https://api-qa.tenetos.dev/facade-admin/admin/crm',
  amlApi: 'https://api-qa.tenetos.dev/facade-admin/admin/aml',
  approvalApi: 'https://api-qa.tenetos.dev/facade-admin/admin/approval',
  accountingApi: 'https://api-qa.tenetos.dev/facade-admin/admin/accounting',
  dmsApi: 'https://api-qa.tenetos.dev/facade-admin/admin/documents',
  notesApi: 'https://api-qa.tenetos.dev/facade-admin/admin/notes',
  notificationApi: 'https://api-qa.tenetos.dev/facade-admin/admin/notification',
  messagingApi: 'https://api-qa.tenetos.dev/facade-admin/admin/messaging',
  clientUrl: 'https://ops-qa.tenetos.dev',
  azureInstrumentationKey: '06f2f2e2-dd40-46d4-8353-ced4ace2208c',
};

const stg: IApiInstance = {
  authPublicApi:
    'https://apim-tenetos-stg.azure-api.net/facade-admin/public/authentication',
  authApi:
    'https://apim-tenetos-stg.azure-api.net/facade-admin/admin/authentication',
  oracApi: 'https://apim-tenetos-stg.azure-api.net/facade-admin/admin/orac',
  crmApi: 'https://apim-tenetos-stg.azure-api.net/facade-admin/admin/crm',
  amlApi: 'https://apim-tenetos-stg.azure-api.net/facade-admin/admin/aml',
  approvalApi:
    'https://apim-tenetos-stg.azure-api.net/facade-admin/admin/approval',
  accountingApi:
    'https://apim-tenetos-stg.azure-api.net/facade-admin/admin/accounting',
  dmsApi: 'https://apim-tenetos-stg.azure-api.net/facade-admin/admin/documents',
  notesApi: 'https://apim-tenetos-stg.azure-api.net/facade-admin/admin/notes',
  notificationApi:
    'https://apim-tenetos-stg.azure-api.net/facade-admin/admin/notification',
  messagingApi: 'https://api-dev.tenetos.dev/facade-admin/admin/messaging',
  clientUrl: 'https://tenetos-ui.azureedge.net',
  azureInstrumentationKey: '22d74794-e7fe-4948-9f6c-7c27f19e19b9',
};

const uat: IApiInstance = {
  authPublicApi:
    'https://ops-api-uat.tenetos.dev/facade-admin/public/authentication',
  authApi: 'https://ops-api-uat.tenetos.dev/facade-admin/admin/authentication',
  oracApi: 'https://ops-api-uat.tenetos.dev/facade-admin/admin/orac',
  crmApi: 'https://ops-api-uat.tenetos.dev/facade-admin/admin/crm',
  amlApi: 'https://ops-api-uat.tenetos.dev/facade-admin/admin/aml',
  approvalApi: 'https://ops-api-uat.tenetos.dev/facade-admin/admin/approval',
  accountingApi:
    'https://ops-api-uat.tenetos.dev/facade-admin/admin/accounting',
  dmsApi: 'https://ops-api-uat.tenetos.dev/facade-admin/admin/documents',
  notesApi: 'https://ops-api-uat.tenetos.dev/facade-admin/admin/notes',
  notificationApi:
    'https://ops-api-uat.tenetos.dev/facade-admin/admin/notification',
  messagingApi: 'https://ops-api-uat.tenetos.dev/facade-admin/admin/messaging',
  clientUrl: 'https://ops-uat.tenetos.dev',
  azureInstrumentationKey: '1e2bd0e9-6603-4f6d-9e91-8a4426fc7513',
};

const prod: IApiInstance = {
  authPublicApi: 'https://ops-api.tenet.ky/facade-admin/public/authentication',
  authApi: 'https://ops-api.tenet.ky/facade-admin/admin/authentication',
  oracApi: 'https://ops-api.tenet.ky/facade-admin/admin/orac',
  crmApi: 'https://ops-api.tenet.ky/facade-admin/admin/crm',
  amlApi: 'https://ops-api.tenet.ky/facade-admin/admin/aml',
  approvalApi: 'https://ops-api.tenet.ky/facade-admin/admin/approval',
  accountingApi: 'https://ops-api.tenet.ky/facade-admin/admin/accounting',
  dmsApi: 'https://ops-api.tenet.ky/facade-admin/admin/documents',
  notesApi: 'https://ops-api.tenet.ky/facade-admin/admin/notes',
  notificationApi: 'https://ops-api.tenet.ky/facade-admin/admin/notification',
  messagingApi: 'https://ops-api.tenet.ky/facade-admin/admin/messaging',
  clientUrl: 'https://ops.tenet.ky',
  azureInstrumentationKey: 'eba60924-301a-4cf2-9b5f-ae11c4a7787c',
};

let config: IApiInstance = dev;

switch (process.env.REACT_APP_STAGE) {
  case 'local':
    config = dev;
    break;

  case 'dev':
    config = dev;
    break;

  case 'qa':
    config = qa;
    break;

  case 'stg':
    config = stg;
    break;

  case 'uat':
    config = uat;
    break;

  case 'prod':
    config = prod;
    break;
}

export default config;
