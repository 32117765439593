import React, { memo, useMemo } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '../../../../../hooks/useTranslation';
import { IEntity } from '../../../../../typings/crm/entity';
import { RoutePaths } from '../../../../../routes/routes';
import { DateHelpers } from '@helpers/date';
import { OnboardType } from '../../../../../enums/crm/crm';
import { EntityHelpers } from '@helpers/crm/entity';
import { IContactModel } from '../../../../../typings/crm/contact';
import { ContactHelpers } from '@helpers/crm/contact';
import { clientGroupsAPI } from 'api/crm/clientGroup/clientGroupsAPI';
import { DEFAULT_DATE_TIME_FORMAT } from '../../../../../constants/global';

// components
import Link from '../../../../../components/Typography/Link';
import TagsContainer from './TagsContainer';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ClientGroupStatus from '../../../../../components/Additional/Statuses/ClientGroupStatus';
import DescriptionSection from '../../../../../components/DesignSystem/Core/DescriptionSection';
import ClientGroupReviewStatus from '../../../../../components/Additional/Statuses/ClientGroupReviewStatus';

interface IProps {
  clientGroupId: string;
}

const ClientGroupDetailsSection = memo(({ clientGroupId }: IProps) => {
  const { t } = useTranslation('crm');

  const { response, loading } = useFetch(
    () => clientGroupsAPI.fetchClientGroupById(clientGroupId),
    [clientGroupId],
  );

  const data = useMemo(() => {
    const clientGroup = response?.clientGroup;
    function getDirectClientValue() {
      return clientGroup?.directClientType === OnboardType.Contact ? (
        <Link
          to={{
            pathname: RoutePaths.CRM_Contacts_Edit,
            search: `?id=${clientGroup.directClientItemId}`,
          }}
        >
          {ContactHelpers.getContactName(
            clientGroup.directClient as IContactModel,
          )}
        </Link>
      ) : (
        <Link
          to={{
            pathname: RoutePaths.CRM_Entities_Edit,
            search: `?id=${clientGroup?.directClientItemId}`,
          }}
        >
          {EntityHelpers.getEntityName(clientGroup?.directClient as IEntity)}
        </Link>
      );
    }

    return clientGroup
      ? [
          {
            label: t('client_group.info.id'),
            description: clientGroup.numericId,
          },
          {
            label: t('client_group.info.name'),
            description: clientGroup.clientGroupName,
          },
          {
            label: t('client_group.info.direct_client'),
            description: getDirectClientValue(),
          },
          {
            label: t('client_group.info.direct_client_type'),
            description: t(`application.type.${clientGroup.directClientType}`),
          },
          {
            label: t('client_group.info.status'),
            description: <ClientGroupStatus status={clientGroup.status} />,
          },
          {
            label: t('client_group.info.review_status'),
            description: (
              <ClientGroupReviewStatus status={clientGroup.reviewStatus} />
            ),
          },
          {
            label: t('client_group.info.created_at'),
            description: DateHelpers.formatDateToString(
              clientGroup._audit.createdAt,
              DEFAULT_DATE_TIME_FORMAT,
            ),
          },
          {
            label: t('client_group.info.tags'),
            description: <TagsContainer clientGroupData={clientGroup} />,
          },
        ]
      : [];
  }, [response]);

  return (
    <LoadingWrapper loading={loading}>
      <DescriptionSection
        data={data}
        size="small"
        bordered={false}
        background="none"
      />
    </LoadingWrapper>
  );
});

export default ClientGroupDetailsSection;
