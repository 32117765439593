import React, { memo, useCallback, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { ForgotUsernameRequestStatuses } from 'components/Additional/Statuses/ForgotUsernameRequestStatus';
import {
  ForgotUsernameRequestFromQuery,
  forgotUsernameRequestsAPI,
} from 'api/orac/forgotUsernameRequestsAPI';

// components
import SectionIntro from '@common_components/Texts/SectionIntro';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import NewMessagesBadge from 'components/Additional/Messaging/NewMessagesBadge';
import {
  default as ForgotUsernameRequestsTemplateTable,
  ActionKeys,
} from 'components/Tables/TableTemplates/CRM/ForgotUsernameRequestsTable';

const ForgotUsernameRequestsTable = memo(() => {
  const { t } = useTranslation('crm');
  const history = useHistory();
  const [page, setPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      forgotUsernameRequestsAPI.fetchForgotUsernameRequests({
        page,
        limit: 5,
        statuses: [
          ForgotUsernameRequestStatuses.InProgress,
          ForgotUsernameRequestStatuses.Pending,
        ].join(','),
      }),
    [page],
  );

  const handleActionsClick = useCallback(
    (key: ActionKeys, record: ForgotUsernameRequestFromQuery) => {
      switch (key) {
        case 'forgot_username_request_view':
          {
            history.push({
              pathname: RoutePaths.ORAC_ForgotUsernameRequests_Details,
              search: `?id=${record.id}`,
            });
          }
          break;
      }
    },
    [history],
  );

  return (
    <>
      <SectionIntro
        titleVariant="h5"
        title={
          <DivAlignCenter>
            {t('forgot_username_requests.title')}
            {response?.total ? (
              <NewMessagesBadge count={response.total} />
            ) : null}
          </DivAlignCenter>
        }
      />
      <LoadingWrapper loading={!response}>
        {!response?.data.length ? (
          t('forgot_username_requests.no_data_message')
        ) : (
          <ForgotUsernameRequestsTemplateTable
            data={response.data}
            total={response.total}
            current={page}
            size="small"
            loading={loading}
            onPaginationChange={setPage}
            onActionsClick={handleActionsClick}
          />
        )}
      </LoadingWrapper>
    </>
  );
});

export default ForgotUsernameRequestsTable;
