import * as yup from 'yup';
import { TransactionTypes } from '../../../enums/banking/transactions';
import { MAX_TRANSACTION_AMOUNT } from 'constants/transactions';
import {
  NULLABLE_STRING_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
} from 'validations/common';

const SubmitBeneficiaryDataValidationSchema = yup.object().shape({
  accountNumber: STRING_VALIDATION_RULE.required('This is required field'),
  accountName: STRING_VALIDATION_RULE.required('This is required field'),
  country: NULLABLE_STRING_VALIDATION_RULE.required('This is required field'),
  city: STRING_VALIDATION_RULE.max(256),
  district: STRING_VALIDATION_RULE.max(50),
  postalCode: STRING_VALIDATION_RULE.max(32),
  address1: STRING_VALIDATION_RULE.max(256).required('This is required field'),
});

const SuspenseBeneficiaryDataValidationSchema = yup.object().shape({
  accountNumber: STRING_VALIDATION_RULE,
  accountName: STRING_VALIDATION_RULE.required('This is required field'),
  country: NULLABLE_STRING_VALIDATION_RULE,
  city: STRING_VALIDATION_RULE.max(256),
  district: STRING_VALIDATION_RULE.max(50),
  postalCode: STRING_VALIDATION_RULE.max(32),
  address1: STRING_VALIDATION_RULE.max(256),
});

const SubmitBeneficiaryCustomerDataValidationSchema = yup.object().shape({
  accountNumber: STRING_VALIDATION_RULE.max(
    34,
    'Account number should be less or equal to 34 characters long',
  ).required('This field is required'),
  accountName: STRING_VALIDATION_RULE.max(
    128,
    'Account name should be less or equal to 128 characters long',
  ).required('This field is required'),
  country: NULLABLE_STRING_VALIDATION_RULE.required('This is required field'),
  city: STRING_VALIDATION_RULE.max(
    20,
    'City should be less or equal to 20 characters long',
  ),
  district: STRING_VALIDATION_RULE.max(
    20,
    'District should be less or equal to 20 characters long',
  ),
  postalCode: STRING_VALIDATION_RULE.max(
    10,
    'Postal Code should be less or equal to 10 characters long',
  ),
  address1: STRING_VALIDATION_RULE.max(
    50,
    'Address should be less or equal to 50 characters long',
  ).required('This field is required'),
  repair: yup.boolean(),
  repairedBeneficiary: yup
    .string()
    .max(
      34,
      'Repaired Beneficiary should be less or equal to 34 characters long',
    )
    .nullable()
    .when('repair', {
      is: true,
      then: (schema) => schema.required('This field is required'),
    }),

  fee: yup
    .boolean()
    .nullable()
    .when('repair', {
      is: true,
      then: (schema) => schema.required('This field is required'),
    }),
});

const SuspenseBeneficiaryCustomerDataValidationSchema = yup.object().shape({
  accountNumber: STRING_VALIDATION_RULE.max(34),
  accountName: STRING_VALIDATION_RULE.max(
    128,
    'Account name should be less or equal to 128 characters long',
  ),
  country: NULLABLE_STRING_VALIDATION_RULE,
  city: STRING_VALIDATION_RULE.max(256),
  district: STRING_VALIDATION_RULE.max(50),
  postalCode: STRING_VALIDATION_RULE.max(32),
  address1: STRING_VALIDATION_RULE.max(
    50,
    'Address should be less or equal to 50 characters long',
  ),
  repair: yup.boolean(),
  repairedBeneficiary: yup
    .string()
    .nullable()
    .when('repair', {
      is: true,
      then: (schema) => schema.required('This field is required'),
    }),

  fee: yup
    .boolean()
    .nullable()
    .when('repair', {
      is: true,
      then: (schema) => schema.required('This field is required'),
    }),
});

const BankInfoDataValidationSchema = yup.object().shape({
  code: STRING_VALIDATION_RULE.max(
    256,
    'Bank Code should be less or equal to 256 characters long',
  ),
  name: STRING_VALIDATION_RULE.max(
    256,
    'Bank Name should be less or equal to 256 characters long',
  ),
  country: NULLABLE_STRING_VALIDATION_RULE,
  address: STRING_VALIDATION_RULE.max(
    256,
    'Bank Address should be less or equal to 256 characters long',
  ),
  city: STRING_VALIDATION_RULE.max(
    256,
    'Bank City should be less or equal to 256 characters long',
  ),
  district: STRING_VALIDATION_RULE.max(
    50,
    'Bank District should be less or equal to 50 characters long',
  ),
  postalCode: STRING_VALIDATION_RULE.max(
    32,
    'Bank Postal Code should be less or equal to 32 characters long',
  ),
});

export const SubmitIncomingTransactionValidationSchema = yup.object().shape({
  general: yup.object().shape({
    senderReference: yup
      .string()
      .trim()
      .max(
        16,
        '20: SenderReference must be less or equal to 16 characters long',
      )
      .required('This field is required'),
    bankOperationsCode: NULLABLE_STRING_VALIDATION_RULE.required(
      'This field is required',
    ),
    valueDate: yup.date().nullable().required('This field is required'),
    currency: NULLABLE_STRING_VALIDATION_RULE.required(
      'This field is required',
    ),
    settledAmount: yup
      .number()
      .nullable()
      .max(
        MAX_TRANSACTION_AMOUNT,
        '32A3: SettledAmount should be less or equal to 1 trillion',
      )
      .required('This field is required'),
    detailsOfCharges: NULLABLE_STRING_VALIDATION_RULE.required(
      'This is required field',
    ),
    documents: yup.array(),
  }),

  other: yup.object().shape({
    incomingTransactionCurrency: NULLABLE_STRING_VALIDATION_RULE,
    instructedAmount: yup
      .number()
      .max(
        MAX_TRANSACTION_AMOUNT,
        '33B2: InstructedAmount should be less or equal to 1 trillion',
      )
      .nullable(),
  }),

  orderingCustomer: SubmitBeneficiaryDataValidationSchema,
  sendingInstitution: BankInfoDataValidationSchema,
  orderingInstitution: BankInfoDataValidationSchema,
  senderCorrespondent: BankInfoDataValidationSchema,
  receiverCorrespondent: BankInfoDataValidationSchema,
  thirdReimbursement: BankInfoDataValidationSchema,
  intermediaryInstitution: BankInfoDataValidationSchema,
  accountWithInstitution: BankInfoDataValidationSchema,
  beneficiaryCustomer: SubmitBeneficiaryCustomerDataValidationSchema,

  remittanceInformation: yup.object().shape({
    line1: STRING_VALIDATION_RULE.max(
      140,
      'Line 1 should be less or equal to 140 characters long',
    ),
    line2: STRING_VALIDATION_RULE.max(
      140,
      'Line 2 should be less or equal to 140 characters long',
    ),
    line3: STRING_VALIDATION_RULE.max(
      140,
      'Line 3 should be less or equal to 140 characters long',
    ),
    line4: STRING_VALIDATION_RULE.max(
      140,
      'Line 4 should be less or equal to 140 characters long',
    ),
  }),

  senderToReceiver: yup.object().shape({
    line1: STRING_VALIDATION_RULE.max(35),
    line2: STRING_VALIDATION_RULE.max(35),
    line3: STRING_VALIDATION_RULE.max(35),
    line4: STRING_VALIDATION_RULE.max(35),
    line5: STRING_VALIDATION_RULE.max(35),
    line6: STRING_VALIDATION_RULE.max(35),
  }),
});

export const SuspenseIncomingTransactionValidationSchema = yup.object().shape({
  general: yup.object().shape({
    senderReference: yup
      .string()
      .trim()
      .max(
        16,
        '20: SenderReference must be less or equal to 16 characters long',
      )
      .required('This field is required'),
    bankOperationsCode: NULLABLE_STRING_VALIDATION_RULE,
    valueDate: yup.date().nullable().required('This field is required'),
    currency: NULLABLE_STRING_VALIDATION_RULE.required(
      'This field is required',
    ),
    settledAmount: yup
      .number()
      .nullable()
      .max(
        MAX_TRANSACTION_AMOUNT,
        '32A3: SettledAmount should be less or equal to 1 trillion',
      )
      .required('This field is required'),
    detailsOfCharges: NULLABLE_STRING_VALIDATION_RULE,
    documents: yup.array(),
  }),

  other: yup.object().shape({
    incomingTransactionCurrency: NULLABLE_STRING_VALIDATION_RULE,
    instructedAmount: yup
      .number()
      .max(
        MAX_TRANSACTION_AMOUNT,
        '33B2: InstructedAmount should be less or equal to 1 trillion',
      )
      .nullable(),
  }),

  orderingCustomer: SuspenseBeneficiaryDataValidationSchema,
  sendingInstitution: BankInfoDataValidationSchema,
  orderingInstitution: BankInfoDataValidationSchema,
  senderCorrespondent: BankInfoDataValidationSchema,
  receiverCorrespondent: BankInfoDataValidationSchema,
  thirdReimbursement: BankInfoDataValidationSchema,
  intermediaryInstitution: BankInfoDataValidationSchema,
  accountWithInstitution: BankInfoDataValidationSchema,
  beneficiaryCustomer: SuspenseBeneficiaryCustomerDataValidationSchema,

  remittanceInformation: yup.object().shape({
    line1: STRING_VALIDATION_RULE.max(35),
    line2: STRING_VALIDATION_RULE.max(35),
    line3: STRING_VALIDATION_RULE.max(35),
    line4: STRING_VALIDATION_RULE.max(35),
  }),

  senderToReceiver: yup.object().shape({
    line1: STRING_VALIDATION_RULE.max(35),
    line2: STRING_VALIDATION_RULE.max(35),
    line3: STRING_VALIDATION_RULE.max(35),
    line4: STRING_VALIDATION_RULE.max(35),
    line5: STRING_VALIDATION_RULE.max(35),
    line6: STRING_VALIDATION_RULE.max(35),
  }),
});

export const TransmitOutgoingTransactionValidationSchema = yup.object().shape({
  general: yup.object().shape({
    senderReference: yup
      .string()
      .trim()
      .max(
        16,
        '20: SenderReference must be less or equal to 16 characters long',
      )
      .required('This field is required'),
  }),
});

export const TransactionValidationSchema = yup.object().shape({
  submitAction: NULLABLE_STRING_VALIDATION_RULE,
  transactionId: yup.number().nullable(),
  transactionType: STRING_VALIDATION_RULE,
  incomingTransaction: yup
    .object()
    .nullable()
    .when(['transactionType', 'submitAction'], (data: any) => {
      if (data[0] === TransactionTypes.Incoming) {
        if (data[1] === 'submit') {
          return SubmitIncomingTransactionValidationSchema;
        } else {
          return SuspenseIncomingTransactionValidationSchema;
        }
      }

      return yup.object().nullable();
    }),
  outgoingTransaction: yup
    .object()
    .nullable()
    .when(['transactionType', 'submitAction'], (data: any) => {
      if (data[0] === TransactionTypes.Outgoing && data[1] === 'transmit') {
        return TransmitOutgoingTransactionValidationSchema;
      }

      return yup.object().nullable();
    }),
});
