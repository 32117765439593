import React from 'react';

// components
import ClientLockoutDetails from 'modules/ORAC/ClientLockoutDetails';

const ClientLockoutDetailsPage = () => {
  return <ClientLockoutDetails />;
};

export default ClientLockoutDetailsPage;
