import React, { memo, useMemo } from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useUserAccess from '../../../../../hooks/useUserAccess';
import useTranslation from '../../../../../hooks/useTranslation';
import TableActions, { ITableMenuItem } from '../../../TableActions';
import { enumToMap } from '@helpers/utils';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { DateHelpers } from '@helpers/date';
import { AccountsHelpers } from '../../../../../helpers/finance/accounts';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { TransactionWithComplianceInfoModel } from '../../../../../typings/compliance/transactions';
import {
  ComplianceReviewTypes,
  ReviewProcessStatuses,
} from '../../../../../enums/compliance/reviewConfiguration';

// constants
import { DEFAULT_DATE_TIME_FORMAT } from '../../../../../constants/global';

// components
import Link from '@common_components/Texts/Link';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import DrillDownFilterIcon from 'components/Additional/DrillDownFilterIcon';
import FilterDropdownOverlay from 'components/Tables/FilterDropdownOverlay';
import RDCReviewProcessStatuses from 'components/Additional/Statuses/RDCReviewProcessStatuses';
import BankingBankAccountsAutocomplete from 'components/Forms/FormComponents/Autocompletes/Finance/BankingBankAccountsAutocomplete';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { DatePicker, Typography } from 'antd';

interface IProps
  extends RequiredPropsForTableModel<TransactionWithComplianceInfoModel> {
  actions?: ITableMenuItem<ActionTypes>[];
  onActionsClick?: (key: ActionTypes, value: string) => void;
  filters: Record<string, any>;
  onFilterChange?: (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => void;
}

export type ActionTypes = 'view_details';

const TransactionsTable = memo(
  ({ actions, onActionsClick, filters, onFilterChange, ...rest }: IProps) => {
    const history = useHistory();
    const { t } = useTranslation('compliance');
    const [hasTransactionDetailsAccess] = useUserAccess([
      'Compliance_Transactions_Details_View',
    ]);

    const renderTransactionName = (
      record: TransactionWithComplianceInfoModel,
    ) => {
      let label = '';

      const transaction =
        record.reviewType === ComplianceReviewTypes.OutgoingTransaction &&
        record.outgoingTransaction
          ? record.outgoingTransaction
          : record.reviewType === ComplianceReviewTypes.IncomingTransaction &&
              record.incomingTransaction
            ? record.incomingTransaction
            : null;

      if (!transaction) {
        return null;
      }

      const amountString = `${AccountsHelpers.formatAmountToLocaleString(transaction.transaction.settledAmount)} ${
        transaction.transaction.currencyIsoCode
      }`;
      label = t('transactions.transaction_name', {
        amount: amountString,
        from: AccountsHelpers.generateAccountFullName(
          transaction.orderingAccount.accountName,
          transaction.orderingAccount.accountNumber,
          '',
        ),
        to: AccountsHelpers.generateAccountFullName(
          transaction.beneficiaryAccount.accountName,
          transaction.remittance.repair
            ? transaction.remittance.repairedBeneficiary
            : transaction.beneficiaryAccount.accountNumber,
          '',
        ),
      });

      if (!hasTransactionDetailsAccess) {
        return label;
      }

      return (
        <Typography.Link
          onClick={() =>
            onActionsClick && onActionsClick('view_details', record.id)
          }
        >
          {label}
        </Typography.Link>
      );
    };

    const renderAccountName = (record: TransactionWithComplianceInfoModel) => {
      const transaction =
        record.reviewType === ComplianceReviewTypes.OutgoingTransaction &&
        record.outgoingTransaction
          ? record.outgoingTransaction
          : record.reviewType === ComplianceReviewTypes.IncomingTransaction &&
              record.incomingTransaction
            ? record.incomingTransaction
            : null;

      if (!transaction) {
        return null;
      }

      const account =
        record.reviewType == ComplianceReviewTypes.OutgoingTransaction
          ? transaction.orderingAccount
          : transaction.beneficiaryAccount;

      return (
        <DivAlignCenter>
          <Link
            onClick={() =>
              history.push({
                pathname: RoutePaths.Banking_BankAccounts,
                search: `?accountNumber=${account.accountNumber}`,
              })
            }
          >
            <EllipsisTooltip
              maxTextContainerWidth="300px"
              title={account.accountName}
            >
              {account.accountName}
            </EllipsisTooltip>
          </Link>
          {onFilterChange ? (
            <DrillDownFilterIcon
              onClick={() =>
                onFilterChange({ accountName: [account.accountNumber] } as any)
              }
            />
          ) : null}
        </DivAlignCenter>
      );
    };

    const renderValueDate = (record: TransactionWithComplianceInfoModel) => {
      const transaction =
        record.reviewType === ComplianceReviewTypes.OutgoingTransaction &&
        record.outgoingTransaction
          ? record.outgoingTransaction
          : record.reviewType === ComplianceReviewTypes.IncomingTransaction &&
              record.incomingTransaction
            ? record.incomingTransaction
            : null;

      if (!transaction) {
        return null;
      }

      return transaction.transaction.valueDate
        ? DateHelpers.formatTimestampToUTCString(
            +transaction.transaction.valueDate,
          )
        : '';
    };

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          key: 'transaction_id',
          width: 200,
          title: t('transactions.transaction_id'),
          render: (record: TransactionWithComplianceInfoModel) =>
            record.outgoingTransaction
              ? record.outgoingTransaction.transaction.transactionNumber
              : '',
        },
        {
          key: 'transaction_status',
          width: 200,
          title: t('transactions.transaction_status'),
          render: (record: TransactionWithComplianceInfoModel) => (
            <RDCReviewProcessStatuses status={record.status} />
          ),
          // filter
          filteredValue: filters.status,
          filterSearch: true,
          filters: Array.from(enumToMap(ReviewProcessStatuses)).map((el) => ({
            text: t(`review_process_statuses.${el[0]}`),
            value: el[1],
          })),
        },
        {
          key: 'transaction_type',
          width: 200,
          title: t('transactions.transaction_type'),
          render: (record: TransactionWithComplianceInfoModel) =>
            t(`review_types.${ComplianceReviewTypes[record.reviewType]}`),
          // filter
          filteredValue: filters.type,
          filterSearch: true,
          filters: [
            ComplianceReviewTypes.IncomingTransaction,
            ComplianceReviewTypes.OutgoingTransaction,
          ].map((e) => ({
            text: t(`review_types.${ComplianceReviewTypes[e]}`),
            value: e,
          })),
        },
        {
          width: '370px',
          title: t('transactions.transaction'),
          key: 'transaction',
          render: renderTransactionName,
        },
        {
          key: 'value_date',
          width: 200,
          title: t('transactions.value_date'),
          render: renderValueDate,
          // filter
          filteredValue: filters.valueDate,
          filterDropdown: (filterProps: FilterDropdownProps) => {
            return (
              <FilterDropdownOverlay filterProps={filterProps}>
                <StyledRangePicker
                  allowClear
                  size="large"
                  value={filterProps.selectedKeys as any}
                  onChange={(value) =>
                    filterProps.setSelectedKeys(value as any)
                  }
                />
              </FilterDropdownOverlay>
            );
          },
        },
        {
          key: 'transaction_date',
          width: 200,
          title: t('transactions.transaction_date'),
          render: (record: TransactionWithComplianceInfoModel) =>
            moment.unix(record.createdAt).format(DEFAULT_DATE_TIME_FORMAT),
          // filter
          filteredValue: filters.transactionDate,
          filterDropdown: (filterProps: FilterDropdownProps) => {
            return (
              <FilterDropdownOverlay filterProps={filterProps}>
                <StyledRangePicker
                  allowClear
                  size="large"
                  value={filterProps.selectedKeys as any}
                  onChange={(value) =>
                    filterProps.setSelectedKeys(value as any)
                  }
                />
              </FilterDropdownOverlay>
            );
          },
        },
        {
          key: 'accountName',
          width: 300,
          title: t('transactions.account_name'),
          render: renderAccountName,
          filteredValue: filters.accountName,
          filterDropdown: (filterProps: FilterDropdownProps) => {
            return (
              <FilterDropdownOverlay filterProps={filterProps}>
                <StyledClientBankAccountsAutocomplete
                  mode="multiple"
                  value={filterProps.selectedKeys as any}
                  onChange={(value) =>
                    filterProps.setSelectedKeys(value as any)
                  }
                  isActive="all"
                  isFrozen="all"
                />
              </FilterDropdownOverlay>
            );
          },
        },
      ];

      if (actions?.length) {
        result.push({
          key: 'actions',
          align: 'right',
          dataIndex: 'id',
          render: (id: string) => (
            <TableActions
              menu={actions}
              onSelect={(key) => onActionsClick && onActionsClick(key, id)}
            />
          ),
        });
      }

      return result;
    }, [actions, filters, onFilterChange, hasTransactionDetailsAccess]);

    return (
      <Table {...rest} onFilterChange={onFilterChange} columns={columns} />
    );
  },
);

const StyledRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;

const StyledClientBankAccountsAutocomplete = styled(
  BankingBankAccountsAutocomplete,
)`
  width: 100%;
`;

export default TransactionsTable;
