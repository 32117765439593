import React, { useEffect, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { darkTheme } from '@resources/theme/styled';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { StateModel } from '../../../../redux/reducers';
import { IDocumentFile } from '../../../../typings/documents/documents';
import { generateUniqId } from '@helpers/utils';
import { removeFile, uploadFiles } from '../../../../redux/actions/upload';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as UploadStateModel } from '../../../../redux/reducers/upload';

// components
import Text from '@core_components/Text';
import DocumentLink from 'components/Typography/DocumentLink';
import DocumentItem from './DocumentItem';
import HideIfDisabledForm from '../../HideIfDisabledForm';
import UploadDocumentButton from './UploadDocumentButton';
import { Col, Row } from 'antd';

export interface DocumentFieldValueModel {
  id: string;
  name: string;
  fileId: string;
  file: IDocumentFile | null;
  audit?: {
    uploadedBy: {
      id: string;
      name: string;
    };
    uploadDate: string;
  };
}

export interface LocalDocumentValueModel {
  label: string;
  localDocumentId: string | null;
  isUploaded: boolean;

  // Data base ids
  file: IDocumentFile | null;
  fileId: string | null;
  documentId: string | null;
  audit?: {
    uploadedBy: {
      id: string;
      name: string;
    };
    uploadDate: string;
  };
}

interface IProps {
  name: string;
  value?: DocumentFieldValueModel[];
  onChange: (value: DocumentFieldValueModel[]) => void;
  isMultiple?: boolean;
  tags?: string[];
  disabled?: boolean;
  association?: any;
  formDocument?: any;
  showDocumentAudit?: boolean;
}

const DocumentsField = ({
  name,
  value,
  tags = [],
  association,
  onChange,
  isMultiple = true,
  disabled,
  formDocument,
  showDocumentAudit,
}: IProps) => {
  const history = useHistory();
  const { t } = useTranslation('crm');
  const dispatch = useDispatch();
  const uploadState = useSelector<StateModel, UploadStateModel>(
    (state) => state.upload,
  );
  const [isUploadActive, setIsUploadActive] = useState(false);
  const [localFieldValue, setLocalFieldValue] = useState<
    LocalDocumentValueModel[]
  >([]);

  // Set initial local value
  useEffect(() => {
    if (!localFieldValue.length && Array.isArray(value) && value?.length) {
      const formattedValue: LocalDocumentValueModel[] = value.map((e) => ({
        localDocumentId: null,
        label: e.name,
        isUploaded: true,
        documentId: e.id,
        fileId: e.fileId,
        file: e.file as any,
        audit: e.audit,
      }));

      setLocalFieldValue(formattedValue);
    } else if ((!value || !value.length) && localFieldValue.length) {
      setLocalFieldValue([]);
    }
  }, [value]);

  useEffect(() => {
    const { isFinished, files } = uploadState;

    if (isFinished && isUploadActive) {
      const newFieldValue = localFieldValue.map((e) => {
        if (e.isUploaded) {
          return e;
        } else {
          if (e.localDocumentId && files[e.localDocumentId]) {
            const { dataBaseEntry } = files[e.localDocumentId];

            return {
              ...e,
              isUploaded: true,
              documentId: dataBaseEntry?._id as string,
              fileId: dataBaseEntry?.files[0].id as string,
              file: dataBaseEntry?.files.length ? dataBaseEntry.files[0] : null,
            };
          } else {
            // Impossible case
            return e;
          }
        }
      });

      setLocalFieldValue(newFieldValue);
      setFormFieldValue(newFieldValue);
      setIsUploadActive(false);
    }
  }, [uploadState.isFinished, isUploadActive]);

  const setFormFieldValue = (newValue: LocalDocumentValueModel[]) => {
    const formattedValue: DocumentFieldValueModel[] = newValue.map((e) => ({
      id: e.documentId as string,
      name: e.label,
      fileId: e.fileId as string,
      file: e.file,
    }));

    onChange(formattedValue);
  };

  const handleFilesSelect = (files: File[]) => {
    if (!files.length) {
      return;
    }
    const formattedDocuments = files.map((file) => ({
      id: generateUniqId(),
      originalName: file.name,
      name: file.name,
      tags,
      file,
      associations: [],
    }));

    const formattedLocalValue: LocalDocumentValueModel[] =
      formattedDocuments.map((e) => ({
        label: e.name,
        localDocumentId: e.id,
        isUploaded: false,
        documentId: null,
        fileId: null,
        file: null,
      }));

    // TODOfix issue
    dispatch(uploadFiles([...formattedDocuments], association, name));
    setLocalFieldValue([...localFieldValue, ...formattedLocalValue]);
    setIsUploadActive(true);
  };

  const deleteDocumentByIndex = (documentIndex: number, documentId: string) => {
    const documentsCopy = [...localFieldValue];
    documentsCopy.splice(documentIndex, 1);

    dispatch(removeFile(documentId));

    setLocalFieldValue(documentsCopy);
    setFormFieldValue(documentsCopy);
  };

  const renderDocuments = (documents: LocalDocumentValueModel[]) => {
    if (!documents.length) {
      return null;
    }

    return documents.map((doc, i) => (
      <div key={`${doc.localDocumentId}-${doc.documentId}`}>
        {showDocumentAudit && doc.audit ? (
          <>
            <Row>
              <Col flex="150px">
                <Text>{t('client_group.approval.provided_by')}</Text>
              </Col>
              <Col flex="auto">
                <Text
                  variant="link1"
                  onClick={() =>
                    history.push({
                      pathname: RoutePaths.ORAC_User_Details,
                      search: `?id=${doc.audit?.uploadedBy?.id}`,
                    })
                  }
                >
                  {doc.audit.uploadedBy.name}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col flex="150px">
                <Text>{t('client_group.approval.upload_date')}</Text>
              </Col>
              <Col flex="auto">
                <Text>{doc.audit.uploadDate}</Text>
              </Col>
            </Row>
          </>
        ) : null}
        <DocumentItem
          document={doc}
          onDelete={
            !disabled
              ? () => deleteDocumentByIndex(i, doc.documentId as string)
              : undefined
          }
        />
      </div>
    ));
  };

  return (
    <>
      <HideIfDisabledForm>
        {formDocument && (
          <CurrentDocumentWrapper>
            <StyledText
              variant="body1"
              color={darkTheme.colorLight}
            >{`${t('documents.additional_field.current_document')}:`}</StyledText>
            <StyledDocumentLink
              documentId={formDocument.id}
              fileId={formDocument.files[0].id}
              text={formDocument.files[0].name}
              fileName={formDocument.files[0].name}
            />
          </CurrentDocumentWrapper>
        )}
        {!disabled && (
          <UploadDocumentButtonWrapper>
            <UploadDocumentButton
              multiple={isMultiple}
              disabled={!isMultiple && localFieldValue.length === 1}
              onFilesSelect={handleFilesSelect}
            />
          </UploadDocumentButtonWrapper>
        )}
      </HideIfDisabledForm>

      {renderDocuments(localFieldValue)}
    </>
  );
};

const UploadDocumentButtonWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const StyledDocumentLink = styled(DocumentLink)`
  display: block;
  width: max-content;
  margin: ${({ theme }) => theme.marginXs} 0;
`;

const CurrentDocumentWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: ${({ theme }) => theme.marginXs};
  }
`;

// TODO: Remove this later
const StyledText = styled(Text)`
  font-size: 0.875rem;
`;

export default DocumentsField;
