import React, { useEffect, useState } from 'react';

// helpers
import useQueryParam from '@hooks/useQueryParam';
import useUserAccess from '../../../../hooks/useUserAccess';
import useTranslation from '../../../../hooks/useTranslation';
import { UserFullModel } from '../../../../typings/ORAC/users';

// components
import Tabs, { TabModel } from '../../../../components/Tabs';
import UserInfo from './UserInfo';

import RolesTab from './RolesTab';
import GroupsTab from './GroupsTab';
import SecurityTab from './SecurityTab';
import PermissionsTab from './PermissionsTab';
import ForbiddenPermissionsTab from './ForbiddenPermissionsTab';

import { CardWrapper } from '../../../../layouts/PageLayout';

interface IProps {
  user: UserFullModel;
}

const UserDetails = ({ user }: IProps) => {
  const { t } = useTranslation('orac');

  const tabFromURL = useQueryParam({ param: 'tab' });
  const [activeTab, setActiveTab] = useState('groups');

  useEffect(() => {
    if (tabFromURL) {
      setActiveTab(tabFromURL);
    }
  }, [tabFromURL]);

  const [
    hasGroupsAccess,
    hasGroupUpdateAccess,
    hasGroupRolesAccess,
    hasGroupRolePermissionsAccess,
    hasRolesAccess,
    hasPermissionsAccess,
    hasPermissionsUpdateAccess,
    hasForbiddenPermissionsAccess,
    hasForbiddenPermissionUpdateAccess,
  ] = useUserAccess([
    'ORAC_Users_Details_Groups_View',
    'ORAC_Users_Details_Groups_Update',
    'ORAC_Users_Details_Groups_Roles_View',
    'ORAC_Users_Details_Groups_Roles_Permissions_View',
    'ORAC_Users_Details_Roles_View',
    'ORAC_Users_Details_Permissions_View',
    'ORAC_Users_Details_Permissions_Update',
    'ORAC_Users_Details_ForbiddenPermissions_View',
    'ORAC_Users_Details_ForbiddenPermissions_Update',
  ]);

  const tabs: TabModel[] = [
    {
      key: 'groups',
      title: t('users.view.groups'),
      hidden: !hasGroupsAccess,
      content: (
        <GroupsTab
          userId={user._id}
          access={{
            canEditGroup: hasGroupUpdateAccess,
            canViewRoles: hasGroupRolesAccess,
            canViewPermissions: hasGroupRolePermissionsAccess,
          }}
        />
      ),
    },

    {
      key: 'roles',
      title: t('users.view.roles'),
      hidden: !hasRolesAccess,
      content: <RolesTab userId={user._id} />,
    },

    {
      key: 'permissions',
      title: t('users.view.permissions'),
      hidden: !hasPermissionsAccess,
      content: (
        <PermissionsTab
          userId={user._id}
          access={{ canUpdate: hasPermissionsUpdateAccess }}
        />
      ),
    },
    {
      key: 'forbidden-permissions',
      title: t('users.view.forbidden_permissions'),
      hidden: !hasForbiddenPermissionsAccess,
      content: (
        <ForbiddenPermissionsTab
          userId={user._id}
          access={{ canUpdate: hasForbiddenPermissionUpdateAccess }}
        />
      ),
    },

    {
      key: 'security',
      title: t('users.view.security'),
      content: <SecurityTab user={user} />,
    },
  ];

  return (
    <>
      <CardWrapper>
        <UserInfo user={user} />
      </CardWrapper>

      <CardWrapper>
        <Tabs tabs={tabs} activeKey={activeTab} onChange={setActiveTab} />
      </CardWrapper>
    </>
  );
};

export default UserDetails;
