import React, { memo, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { forgotUsernameRequestsAPI } from 'api/orac/forgotUsernameRequestsAPI';
import { forgotUsernameRequestAdapter } from 'apiAdapters/orac/forgotUsernameRequestsAdapter';
import { ForgotUsernameRequestStatuses } from 'components/Additional/Statuses/ForgotUsernameRequestStatus';

// components
import FilterBar from './FilterBar';
import { Divider } from 'antd';
import { default as TemplateForgotUsernameRequestsTable } from 'components/Tables/TableTemplates/ORAC/ForgotUsernameRequestsTable';

const ForgotUsernameRequestsTable = memo(() => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<{
    search: string;
    status: ForgotUsernameRequestStatuses | undefined;
  }>({
    search: '',
    status: undefined,
  });

  const { response, loading } = useFetch(
    () =>
      forgotUsernameRequestsAPI.fetchForgotUsernameRequests({
        page,
        limit: DEFAULT_TABLE_LIMIT,
        name: filters.search,
        statuses: filters.status,
      }),
    [page, filters],
  );

  const handleFilterChange = (key: string, value: unknown | undefined) => {
    switch (key) {
      case 'search':
        setFilters((prev) => ({
          ...prev,
          search: value && typeof value === 'string' ? value.trim() : '',
        }));
        break;

      case 'status':
        setFilters((prev) => ({
          ...prev,
          status: value as ForgotUsernameRequestStatuses,
        }));
        break;
    }
  };

  const onFilterChange = (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => {
    if (Object.keys(filters).length) {
      Object.keys(filters).forEach((key) =>
        handleFilterChange(key, filters[key] as any),
      );
    }
  };

  return (
    <>
      <FilterBar onFilterChange={handleFilterChange} />
      <Divider />
      <TemplateForgotUsernameRequestsTable
        data={forgotUsernameRequestAdapter.formatRequestsToTableFormat(
          response?.data || [],
        )}
        total={response?.total || 0}
        current={page}
        loading={loading}
        onFilterChange={onFilterChange}
        onPaginationChange={setPage}
        filters={filters}
      />
    </>
  );
});

export default ForgotUsernameRequestsTable;
