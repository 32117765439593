import React, { memo, useState } from 'react';

// helpers
import useFetch from '../../../../../../../hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { Moment } from 'moment';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { DEFAULT_TABLE_LIMIT } from '../../../../../../../constants/global';
import { FILTER_BAR_FIELDS_GRID_SIZES } from 'constants/grids';
import {
  PendingTransactionFromQuery,
  transactionsAPI,
} from '../../../../../../../api/transactions/transactionsAPI';

// components
import Text from '@core_components/Text';
import ClientTransferModal from '../../../../../../../components/Modals/TemplateModalDialogs/Accounting/Transfers/ClientTransferModal';
import { SearchInput } from 'components/Fields/SearchInput';
import { Col, Divider, Row } from 'antd';
import ClientTransactionsTable, {
  ActionKeys,
} from '../../../../../../../components/Tables/TableTemplates/Accounting/ClientTransactionsTable';

interface IProps {
  clientGroupNumericId: number;
}

const PendingTransactionsTab = memo(({ clientGroupNumericId }: IProps) => {
  const { t } = useTranslation('crm');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [selectedTransaction, setSelectedTransaction] =
    useState<PendingTransactionFromQuery | null>(null);

  const [filterValues, setFilterValues] = useState<{
    requestedDate: [Moment, Moment] | undefined;
    valueDate: [Moment, Moment] | undefined;
    status: string[] | undefined;
    transactionType: number[] | undefined;
    currency: number[] | undefined;
    amount: number[] | undefined;
    fromAccount: string | undefined;
  }>({
    requestedDate: undefined,
    valueDate: undefined,
    status: undefined,
    transactionType: undefined,
    currency: undefined,
    amount: undefined,
    fromAccount: undefined,
  });

  const { response, loading } = useFetch(
    () =>
      clientGroupNumericId
        ? transactionsAPI.fetchClientPendingTransactions({
            page: currentPage,
            limit: DEFAULT_TABLE_LIMIT,
            search: searchQuery ? searchQuery.trim() : undefined,
            clientGroupId: String(clientGroupNumericId),
            fromRequestedDate:
              filterValues.requestedDate && filterValues.requestedDate[0]
                ? filterValues.requestedDate[0].startOf('day').unix()
                : undefined,
            toRequestedDate:
              filterValues.requestedDate && filterValues.requestedDate[1]
                ? filterValues.requestedDate[1].endOf('day').unix()
                : undefined,
            fromValueDate:
              filterValues.valueDate && filterValues.valueDate[0]
                ? filterValues.valueDate[0].startOf('day').unix()
                : undefined,
            toValueDate:
              filterValues.valueDate && filterValues.valueDate[1]
                ? filterValues.valueDate[1].endOf('day').unix()
                : undefined,
            statusGroups: filterValues.status
              ? filterValues.status.join(',')
              : undefined,
            sourceTypes: filterValues.transactionType
              ? filterValues.transactionType.join(',')
              : '',
            currencies: filterValues.currency
              ? filterValues.currency.join(',')
              : '',
            fromAmount:
              filterValues.amount && filterValues.amount[0]
                ? AccountsHelpers.convertAmountFromIntToBigInt(
                    filterValues.amount[0],
                  )
                : undefined,
            toAmount:
              filterValues.amount && filterValues.amount[1]
                ? AccountsHelpers.convertAmountFromIntToBigInt(
                    filterValues.amount[1],
                  )
                : undefined,
            accountNumber: filterValues.fromAccount
              ? filterValues.fromAccount
              : undefined,
          })
        : null,
    [clientGroupNumericId, searchQuery, filterValues, currentPage],
  );

  const handleActionsClick = (
    key: ActionKeys,
    record: PendingTransactionFromQuery,
  ) => {
    switch (key) {
      case 'view': {
        setSelectedTransaction(record);
      }
    }
  };

  const handleFilterChange = (key: string, value: unknown | undefined) => {
    switch (key) {
      case 'requested_date':
        setFilterValues((prev) => ({
          ...prev,
          requestedDate: (value as [Moment, Moment]) || undefined,
        }));
        break;

      case 'value_date':
        setFilterValues((prev) => ({
          ...prev,
          valueDate: (value as [Moment, Moment]) || undefined,
        }));
        break;

      case 'status':
        setFilterValues((prev) => ({
          ...prev,
          status: (value as string[]) || undefined,
        }));
        break;

      case 'from_account':
        setFilterValues((prev) => ({
          ...prev,
          fromAccount: (value as string) || undefined,
        }));
        break;

      case 'type':
        setFilterValues((prev) => ({
          ...prev,
          transactionType: (value as number[]) || undefined,
        }));
        break;

      case 'currency':
        setFilterValues((prev) => ({
          ...prev,
          currency: (value as number[]) || undefined,
        }));
        break;

      case 'amount':
        setFilterValues((prev) => ({
          ...prev,
          amount: (value as number[]) || undefined,
        }));
        break;

      case 'clientGroup':
        setFilterValues((prev) => ({
          ...prev,
          clientGroup: (value as string) || undefined,
        }));
        break;
    }
  };

  const onFilterChange = (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => {
    if (Object.keys(filters).length) {
      Object.keys(filters).forEach((key) =>
        handleFilterChange(key, filters[key] as any),
      );
    }
  };

  return (
    <>
      <Row>
        <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
          <Text>{t('client_group.transactions.search_by')}</Text>
          <SearchInput
            size="large"
            onSearch={setSearchQuery}
            placeholder={t('client_group.transactions.search_placeholder')}
          />
        </Col>
      </Row>
      <Divider />
      <ClientTransactionsTable
        clientGroupId={clientGroupNumericId}
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={currentPage}
        onPaginationChange={setCurrentPage}
        onActionsClick={handleActionsClick}
        filters={filterValues}
        onFilterChange={onFilterChange}
      />
      <ClientTransferModal
        clientGroupId={clientGroupNumericId}
        transactionId={selectedTransaction?.id || null}
        isVisible={!!selectedTransaction}
        closeCallback={() => setSelectedTransaction(null)}
      />
    </>
  );
});

export default PendingTransactionsTab;
