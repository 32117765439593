import React, { useMemo, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import ErrorHandlerService from '@services/error-handler/service';
import { message } from 'antd';
import { DateHelpers } from '@helpers/date';
import { compareObjects } from '@helpers/utils';
import { ReviewDetailsModel } from 'api/crm/clientGroup/clientGroupsAPI';
import { YYYYMMDD_TIME_FORMAT } from 'constants/global';
import { HighlightFieldStatuses } from '@core_components/FormField';
import { ReviewIdentificationDocumentsValidationSchema } from 'validations/crm/clientGroups';
import {
  FetchContactWithChangeRequestResponse,
  changeRequestsAPI,
} from 'api/crm/changeRequests/changeRequestsAPI';
import {
  AddressModel,
  ExpiringDocument,
  ReviewOfAddressDocument,
  ReviewOfExpiringDocument,
  expiringDocumentsAPI,
} from 'api/crm/expiringDocuments/expiringDocumentsAPI';

// components
import Form from '@core_components/Form';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import RequireAdditionalInformationModalDialog from 'components/Modals/TemplateModalDialogs/CRM/RequireAdditionalInformationModalDialog';
import IdentificationForm, {
  AddressWithReviewFieldsModel,
  FormValuesModel,
  PassportWithReviewFieldsModel,
} from 'components/Forms/TemplateForms/CRM/ContactKYCForms/IdentificationForm';
import ActionButtons, {
  FormValuesModel as ActionButtonsFormValuesModel,
} from 'components/Forms/TemplateForms/CRM/ApplicationDocumentationForm/ActionButtons';

interface IProps {
  data: FetchContactWithChangeRequestResponse;
  canReview: boolean;
  updateChangeRequestCallback: () => void;
}

interface FormValuesWithReviewFieldsModel
  extends FormValuesModel,
    ActionButtonsFormValuesModel {}

const IdentificationTab = ({
  data,
  canReview,
  updateChangeRequestCallback,
}: IProps) => {
  const { t } = useTranslation(['crm', 'common']);
  const [requireAdditionalInfoModalData, setRequireAdditionalInfoModalData] =
    useState<ReviewDetailsModel[] | null>(null);

  const { response: contactDocuments, loading: contactDocumentsLoader } =
    useFetch(
      () => expiringDocumentsAPI.fetchIdentificationDocuments(data.contact._id),
      [data.contact._id, data.clientChangeRequest],
    );

  const {
    response: changeRequestDocuments,
    loading: changeRequestDocumentsLoader,
  } = useFetch(
    () =>
      data.clientChangeRequest
        ? expiringDocumentsAPI.fetchIdentificationDocuments(
            data.clientChangeRequest._id,
          )
        : null,
    [data.clientChangeRequest],
  );

  const initialValues = useMemo<{
    initialFormValues: FormValuesWithReviewFieldsModel;
    modifiedValues?: Record<string, HighlightFieldStatuses>;
  } | null>(() => {
    const formatPassportsToFormItems = (
      passports: ExpiringDocument[],
    ): PassportWithReviewFieldsModel[] => {
      return passports.map((e) => ({
        _id: e._id,
        number: e.details.passportDetails.number || '',
        country: e.details.passportDetails.issuingCountry || '',
        issuedAt: e.details.passportDetails.issuedAt
          ? DateHelpers.formatDateToUTC(e.details.passportDetails.issuedAt)
          : null,
        expirationDate: e.expirationDate
          ? DateHelpers.formatDateToUTC(e.expirationDate)
          : null,
        document: e.details.passportDetails.documents.map((doc) => ({
          id: doc.id,
          name: doc.files[0].name,
          fileId: doc.files[0].id,
          file: null,
          audit: {
            uploadedBy: {
              id: doc.creatorId,
              name: doc.creatorName,
            },
            uploadDate: DateHelpers.formatDateToString(doc.createdAt),
          },
        })),
        notReviewed: !e.review,
        isApproved: e.review ? e.review.isValid : null,
        isRejected: e.review ? !e.review.isValid : null,
        reviewComment: e.review?.comment || '',
        canReview: e.status !== 'finalized',
      }));
    };

    const formatDataToFormValues = (data: {
      isPEP: boolean;
      isRegulated: boolean;
      nationality: string[];
      pepInformation: string;
      regulationCountry: string[];
      addresses: AddressModel[];
      passports: ExpiringDocument[];
    }): FormValuesModel => {
      let primaryAddressId = null;
      let addresses: AddressWithReviewFieldsModel[] = [];
      let passports: PassportWithReviewFieldsModel[] = [];

      if (data.addresses.length) {
        primaryAddressId =
          data.addresses.find((address) => address.isPrimary)?._id || null;
        addresses = data.addresses.map((address) => ({
          id: address._id,
          country: address.country,
          city: address.city,
          type: address.type,
          street: address.street,
          state: address.state,
          postal: address.postalCode,
          documents: address.documents?.map((doc) => ({
            id: doc.id,
            name: doc.files[0].name,
            fileId: doc.files[0].id,
            file: null,
            audit: {
              uploadedBy: {
                id: doc.creatorId,
                name: doc.creatorName,
              },
              uploadDate: DateHelpers.formatDateToString(doc.createdAt),
            },
          })),
          isPrimary: address.isPrimary,
          isCreated: true,
          canReview: address.status !== 'finalized',
          notReviewed: !address.review,
          isApproved: address.review ? address.review.isValid : null,
          isRejected: address.review ? !address.review.isValid : null,
          reviewComment: address.review?.comment || '',
        }));
      }

      if (data.passports.length) {
        passports = data.passports.length
          ? formatPassportsToFormItems(data.passports)
          : [];
      }

      return {
        isPEP: typeof data.isPEP === 'boolean' ? data.isPEP : null,
        isRegulated:
          typeof data?.isRegulated === 'boolean' ? data.isRegulated : null,
        nationality: data.nationality || [],
        pepInformation: data.pepInformation || '',
        regulationCountry: data.regulationCountry || [],
        addresses,
        passports,
        primaryAddressId,
      };
    };

    let formValuesFromActiveVersion: FormValuesModel | null = null;

    if (contactDocuments && changeRequestDocuments) {
      const identificationDataFromActiveVersion = data.contact;
      formValuesFromActiveVersion = formatDataToFormValues({
        isPEP: identificationDataFromActiveVersion.isPEP,
        isRegulated: identificationDataFromActiveVersion.isRegulated,
        nationality: identificationDataFromActiveVersion.nationality || [],
        pepInformation:
          identificationDataFromActiveVersion.pepInformation || '',
        regulationCountry:
          identificationDataFromActiveVersion.regulationCountry || [],
        addresses: contactDocuments.addresses.data.length
          ? contactDocuments.addresses.data
          : [],
        passports: contactDocuments.passportExpiringDocuments.data || [],
      });

      if (
        data.clientChangeRequest?.changedSteps?.identification &&
        data.clientChangeRequest.changes?.contactKYCChange.identification
      ) {
        let formValuesFromNewVersion: FormValuesModel | null = null;
        const identificationDataFromNewVersion =
          data.clientChangeRequest.changes.contactKYCChange.identification;

        formValuesFromNewVersion = formatDataToFormValues({
          isPEP: identificationDataFromNewVersion.isPEP,
          isRegulated: identificationDataFromNewVersion.isRegulated,
          nationality: identificationDataFromNewVersion.nationality || [],
          pepInformation: identificationDataFromNewVersion.pepInformation || '',
          regulationCountry:
            identificationDataFromNewVersion.regulationCountry || [],
          addresses: changeRequestDocuments.addresses.data.length
            ? changeRequestDocuments.addresses.data
            : [],
          passports:
            changeRequestDocuments.passportExpiringDocuments.data || [],
        });

        const modifiedValues =
          data.clientChangeRequest?.type == 'contact-update-kyc'
            ? compareObjects(
                {
                  ...formValuesFromActiveVersion,
                  passports: formValuesFromActiveVersion.passports.map((e) => ({
                    number: e.number,
                    country: e.country,
                    issuedAt: e.issuedAt
                      ? DateHelpers.formatDateToString(
                          e.issuedAt,
                          YYYYMMDD_TIME_FORMAT,
                        )
                      : null,
                    expirationDate: e.expirationDate
                      ? DateHelpers.formatDateToString(
                          e.expirationDate,
                          YYYYMMDD_TIME_FORMAT,
                        )
                      : null,
                    document: e.document,
                  })),
                  addresses: formValuesFromActiveVersion.addresses.map((e) => ({
                    country: e.country,
                    city: e.city,
                    type: e.type,
                    street: e.street,
                    state: e.state,
                    postal: e.postal,
                    documents: e.documents,
                  })),
                },
                {
                  ...formValuesFromNewVersion,
                  passports: formValuesFromNewVersion.passports.map((e) => ({
                    number: e.number,
                    country: e.country,
                    issuedAt: e.issuedAt
                      ? DateHelpers.formatDateToString(
                          e.issuedAt,
                          YYYYMMDD_TIME_FORMAT,
                        )
                      : null,
                    expirationDate: e.expirationDate
                      ? DateHelpers.formatDateToString(
                          e.expirationDate,
                          YYYYMMDD_TIME_FORMAT,
                        )
                      : null,
                    document: e.document,
                  })),
                  addresses: formValuesFromNewVersion.addresses.map((e) => ({
                    country: e.country,
                    city: e.city,
                    type: e.type,
                    street: e.street,
                    state: e.state,
                    postal: e.postal,
                    documents: e.documents,
                  })),
                },
              )
            : undefined;

        if (
          formValuesFromActiveVersion.addresses.length >
          formValuesFromNewVersion.addresses.length
        ) {
          const deletedAddresses = formValuesFromActiveVersion.addresses.slice(
            -(
              formValuesFromActiveVersion.addresses.length -
              formValuesFromNewVersion.addresses.length
            ),
          );
          formValuesFromNewVersion.addresses.push(...deletedAddresses);
        }

        if (
          formValuesFromActiveVersion.passports.length >
          formValuesFromNewVersion.passports.length
        ) {
          const deletedPassports = formValuesFromActiveVersion.passports.slice(
            -(
              formValuesFromActiveVersion.passports.length -
              formValuesFromNewVersion.passports.length
            ),
          );
          formValuesFromNewVersion.passports.push(...deletedPassports);
        }

        // we need to format dates to YYYY-MM-DD format to compare it correctly
        return {
          initialFormValues: {
            ...formValuesFromNewVersion,
            submitActionType: null,
          },
          modifiedValues,
        };
      } else {
        return {
          initialFormValues: {
            ...formValuesFromActiveVersion,
            passports: formatPassportsToFormItems(
              changeRequestDocuments.passportExpiringDocuments.data,
            ),
            submitActionType: null,
          },
        };
      }
    } else {
      return null;
    }
  }, [data, contactDocuments, changeRequestDocuments]);

  const handleSubmit = async (values: FormValuesWithReviewFieldsModel) => {
    try {
      const formattedReviews = values.passports.reduce<
        ReviewOfExpiringDocument[]
      >((acc, e) => {
        if (e.canReview) {
          acc.push({
            expiringDocumentId: e._id as string,
            review: !e.notReviewed
              ? {
                  isValid: !!e.isApproved,
                  comment: e.reviewComment,
                }
              : null,
          });
        }

        return acc;
      }, []);

      const formattedAddressesReviews = values.addresses.reduce<
        ReviewOfAddressDocument[]
      >((acc, e) => {
        if (e.canReview) {
          acc.push({
            documentId: e.id as string,
            review: !e.notReviewed
              ? {
                  isValid: !!e.isApproved,
                  comment: e.reviewComment,
                }
              : null,
          });
        }

        return acc;
      }, []);

      await expiringDocumentsAPI.reviewIdentificationDocuments({
        entityId: data.clientChangeRequest?._id as string,
        expiringDocuments: formattedReviews,
        addresses: formattedAddressesReviews,
      });
      message.success(t('success', { ns: 'common' }));

      if (values.submitActionType === 'start-approval-workflow') {
        await changeRequestsAPI.startApprovalWorkflowForChangeRequest(
          data.clientChangeRequest?._id || '',
        );
        updateChangeRequestCallback();
      } else if (values.submitActionType === 'require-additional-info') {
        const response =
          await changeRequestsAPI.fetchChangeRequestReviewResults(
            data.clientChangeRequest?._id || '',
          );
        if (response.length) {
          setRequireAdditionalInfoModalData(response);
        } else {
          message.error(t('client_group.approval.review_details_error'));
        }
      }
    } catch (e) {
      ErrorHandlerService.handleError(e);
    }
  };

  const handleRequireAdditionalInfoModalSubmit = async () => {
    await changeRequestsAPI.requireAdditionalInfoForChangeRequest(
      data.clientChangeRequest?._id || '',
    );
    updateChangeRequestCallback();
  };

  return (
    <>
      <LoadingWrapper
        loading={contactDocumentsLoader || changeRequestDocumentsLoader}
      >
        {initialValues && (
          <Form<FormValuesWithReviewFieldsModel>
            disabled={!canReview}
            onSubmit={handleSubmit}
            renderForm={
              <>
                <IdentificationForm />
                <HideIfDisabledForm>
                  <ActionButtons />
                </HideIfDisabledForm>
              </>
            }
            initialValues={initialValues.initialFormValues}
            highlightFields={initialValues.modifiedValues}
            validationSchema={ReviewIdentificationDocumentsValidationSchema}
          />
        )}
      </LoadingWrapper>
      <RequireAdditionalInformationModalDialog
        title={t('client_group.approval.review_details_modal.title')}
        data={requireAdditionalInfoModalData}
        isVisible={!!requireAdditionalInfoModalData}
        onSubmit={handleRequireAdditionalInfoModalSubmit}
        closeCallback={() => setRequireAdditionalInfoModalData(null)}
      />
    </>
  );
};

export default IdentificationTab;
