import React from 'react';

// helpers
import styled from 'styled-components';
import { accountsAPI } from '../../../../../../api/finance/account/accountsAPI';
import { IAccountModel } from '../../../../../../typings/finance/account';
import { AccountsHelpers } from '@helpers/finance/accounts';

// components
import EllipsisTooltip from '../../../../../Tooltips/EllipsisTooltip';
import { Typography } from 'antd';
import Autocomplete, {
  AutocompleteOption,
  AutocompleteProps,
} from '@core_components/Autocomplete';

export interface AccountAutocompleteProps
  extends AutocompleteProps<IAccountModel> {
  showBalance?: boolean;
  clientGroupId?: number;
  isFullAccountNumber?: boolean;
  minSearchLength?: number;
}

const AccountAutocomplete = ({
  isFullAccountNumber,
  clientGroupId,
  showBalance = true,
  minSearchLength = 4,
  ...rest
}: AccountAutocompleteProps) => {
  const fetchAccounts = async (searchText: string) => {
    const { data } = await accountsAPI.searchAccounts(
      searchText,
      true,
      isFullAccountNumber,
      clientGroupId,
    );

    return data.map<AutocompleteOption<IAccountModel>>(
      (account: IAccountModel) => {
        const { accountNumber, accountName, currencyCode, balance } = account;

        const name = AccountsHelpers.generateAccountFullName(
          accountName,
          accountNumber,
          currencyCode,
          showBalance
            ? AccountsHelpers.convertAmountFromBigIntToInt(balance)
            : undefined,
        );

        return {
          id: accountNumber,
          label: (
            <EllipsisTooltip
              placement="right"
              overlayStyle={{ maxWidth: '300px' }}
              title={
                <>
                  <StyledParagraph>{accountNumber}</StyledParagraph>
                  <StyledParagraph>{accountName}</StyledParagraph>
                  {showBalance ? (
                    <StyledParagraph>{`${currencyCode} ${AccountsHelpers.convertAmountBigIntToLocaleString(balance)}`}</StyledParagraph>
                  ) : null}
                </>
              }
            >
              {name}
            </EllipsisTooltip>
          ),
          model: account,
        };
      },
    );
  };

  return (
    <Autocomplete
      {...rest}
      fetchData={fetchAccounts}
      minSearchLength={minSearchLength}
    />
  );
};

const StyledParagraph = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
`;

export default AccountAutocomplete;
