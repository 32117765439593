import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { Moment } from 'moment';
import { TWO_COL_GRID_SIZES } from 'constants/grids';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import FormDatePicker from '@common_components/Form/FormDatePicker';
import CountryFormSelect from 'components/Forms/FormComponents/SelectInputs/CountryFormSelect';
import ValueChangedStatus from 'components/Forms/FormComponents/ValueChangedStatus';
import DocumentsField, {
  DocumentFieldValueModel,
} from 'components/Forms/FormComponents/DocumentsField';
import { Card, Col, Row } from 'antd';

export type FormValuesModel = {
  _id?: string;
  number: string;
  country: string;
  issuedAt: Moment | null;
  expirationDate: Moment | null;
  document: DocumentFieldValueModel[];
};

interface IProps {
  index: number;
  fieldName: string;
  disabled?: boolean;
  showPassportAuditData?: boolean;
}

const PassportForm = ({
  fieldName,
  disabled,
  index,
  showPassportAuditData,
}: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <StyledCard
      title={t('contacts.kyc_form.identification.passport_item_title', {
        number: index,
      })}
    >
      <Row gutter={[16, 16]} align="middle">
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.kyc_form.identification.passport_number')}
            name={`${fieldName}.number`}
            component={FormInput}
            disabled={disabled}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.kyc_form.identification.passport_country')}
            name={`${fieldName}.country`}
            component={CountryFormSelect}
            disabled={disabled}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.kyc_form.identification.issue_date')}
            name={`${fieldName}.issuedAt`}
            component={StyledDatePicker}
            disabled={disabled}
            additionalProps={{
              disabledDate: (d: any) => !d || d.isAfter(new Date()),
            }}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.kyc_form.identification.expiration_date')}
            name={`${fieldName}.expirationDate`}
            component={StyledDatePicker}
            disabled={disabled}
            additionalProps={{
              disabledDate: (d: any) => !d || d.isBefore(new Date()),
            }}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={
              <>
                {t('contacts.kyc_form.identification.passport_item_title')}{' '}
                <ValueChangedStatus name={`${fieldName}.document`} />
              </>
            }
            name={`${fieldName}.document`}
            component={DocumentsField}
            disabled={disabled}
            additionalProps={{
              showDocumentAudit: showPassportAuditData,
            }}
          />
        </Col>
      </Row>
    </StyledCard>
  );
};

const StyledDatePicker = styled(FormDatePicker)`
  width: 100%;
`;

const StyledCard = styled(Card)`
  margin: ${({ theme }) => theme.marginMd} 0;
  .ant-card-head {
    border-bottom: 1px solid ${({ theme }) => theme.cardBorderColor};
  }

  .ant-card-extra {
    text-transform: none;
  }
`;

export default PassportForm;
