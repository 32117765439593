import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { useFormikContext } from 'formik';
import { AutocompleteEventOption } from '@core_components/Autocomplete';
import { SelectOption, SelectProps } from '@core_components/Select';
import { ContactDataAutocompleteFromQuery } from 'api/crm/contact/contactsAPI';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import FormSelect from '@common_components/Form/FormSelect';
import UserTypeFormSelect from 'components/Forms/FormComponents/SelectInputs/UserTypeFormSelect';
import ContactFormAutocomplete, {
  IProps as ContactAutocompleteProps,
} from 'components/Forms/FormComponents/Autocompletes/CRM/ContactFormAutocomplete';

export type UserFormValuesModel = {
  type: string | null;
  contactId: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  availableEmails: string[] | null;
  availablePhoneNumbers: string[] | null;
  selectedEmail: string | null;
  selectedPhoneNumber: string | null;
};

const UserForm = () => {
  const { t } = useTranslation('orac');
  const { values, setValues } = useFormikContext<UserFormValuesModel>();

  const emailAddressOptions = useMemo<SelectOption[]>(() => {
    if (!values.availableEmails) {
      return [];
    }

    return values.availableEmails.map((email) => ({
      id: email,
      label: email,
    }));
  }, [values.availableEmails]);

  const phoneNumberOptions = useMemo<SelectOption[]>(() => {
    if (!values.availablePhoneNumbers) {
      return [];
    }

    return values.availablePhoneNumbers.map((phoneNumber) => ({
      id: phoneNumber,
      label: phoneNumber,
    }));
  }, [values.availablePhoneNumbers]);

  const handleContactFieldChange = (
    newValue: string,
    option?:
      | AutocompleteEventOption<ContactDataAutocompleteFromQuery>
      | AutocompleteEventOption<ContactDataAutocompleteFromQuery>[],
  ) => {
    if (!newValue) {
      setValues({
        ...values,
        contactId: null,
        firstName: null,
        middleName: null,
        lastName: null,
        availableEmails: null,
        availablePhoneNumbers: null,
        selectedEmail: null,
        selectedPhoneNumber: null,
      });
    } else if (!Array.isArray(option) && option?.model) {
      const { model } = option;
      setValues({
        ...values,
        contactId: model._id,
        firstName: model.firstName || null,
        middleName: model.middleName || null,
        lastName: model.lastName || null,
        availableEmails: model.emails.map(({ address }) => address),
        availablePhoneNumbers: model.phoneNumbers.map(({ number }) => number),
        selectedEmail: null,
        selectedPhoneNumber: null,
      });
    }
  };

  return (
    <>
      <FormField
        name="type"
        label={t('users.form.type')}
        component={UserTypeFormSelect}
      />

      <FormField<ContactAutocompleteProps>
        name="contactId"
        label={t('users.form.contact')}
        component={ContactFormAutocomplete}
        additionalProps={{
          isAssociated: false,
          shouldDisableContactsWithoutEmailAndPhone: true,
          onChange: (newValue, option) =>
            handleContactFieldChange(newValue as string, option),
        }}
      />

      <FormField
        disabled
        name="firstName"
        label={t('users.form.firstName')}
        component={FormInput}
      />

      <FormField
        disabled
        name="middleName"
        label={t('users.form.middleName')}
        component={FormInput}
      />

      <FormField
        disabled
        name="lastName"
        label={t('users.form.lastName')}
        component={FormInput}
      />

      <FormField<SelectProps>
        disabled={!values.contactId}
        name="selectedEmail"
        label={t('users.form.email')}
        component={FormSelect}
        additionalProps={{
          options: emailAddressOptions,
        }}
      />

      <FormField<SelectProps>
        disabled={!values.contactId}
        name="selectedPhoneNumber"
        label={t('users.form.phone')}
        component={FormSelect}
        additionalProps={{
          options: phoneNumberOptions,
        }}
      />
    </>
  );
};

export default UserForm;
