import React, { memo } from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';

// components
import NewMessagesTable from 'modules/CRM/Dashboard/NewMessagesTable';
import UpdatePersonalDetailsTable from 'modules/CRM/Dashboard/UpdatePersonalDetailsTable';
import ForgotUsernameRequestsTable from 'modules/CRM/Dashboard/ForgotUsernameRequestsTable';
import { Col, Row } from 'antd';
import { CardWrapper } from 'layouts/PageLayout';

const CRMDashboardPage = memo(() => {
  const [hasMessagesAccess] = useUserAccess([
    'CRM_Dashboard_MessagesWidget_View',
  ]);

  return (
    <Row gutter={[24, 24]}>
      {hasMessagesAccess && (
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <CardWrapper noMargin>
            <NewMessagesTable />
          </CardWrapper>
        </Col>
      )}
      <Col xl={12} lg={24} md={24} sm={24} xs={24}>
        <CardWrapper noMargin>
          <ForgotUsernameRequestsTable />
        </CardWrapper>
      </Col>
      <Col xl={12} lg={24} md={24} sm={24} xs={24}>
        <CardWrapper noMargin>
          <UpdatePersonalDetailsTable />
        </CardWrapper>
      </Col>
    </Row>
  );
});

export default CRMDashboardPage;
