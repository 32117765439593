import React, { useEffect, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import { StateModel } from '../../../../redux/reducers';
import { approvalAPI } from '../../../../api/crm/onboarding/approvalAPI';
import { useSelector } from 'react-redux';
import { ApprovalHelpers } from '@helpers/approval/approval';
import { approvalWorkflowAPI } from '../../../../api/approval/approvalManagement/approvalWorkflowAPI';
import { ApprovalWorkflowInstanceModel } from '../../../../typings/approval/approvalWorkflow';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import WorkflowActions from '../WorkflowActions';
import ApprovalsMatrix from '../ApprovalsMatrix';
import { Typography } from 'antd';

interface IComplianceWorkflowStatusProps {
  workflowId: string;
  workflow?: ApprovalWorkflowInstanceModel;
  hasComplianceInfo: boolean;
  onUpdate: () => void;
}

const ComplianceWorkflowStatus = ({
  workflow,
  workflowId,
  hasComplianceInfo,
  onUpdate,
}: IComplianceWorkflowStatusProps) => {
  const currentUserId = useSelector<StateModel, string>(
    (state) => state.auth.profileData?._id || '',
  );
  const { t } = useTranslation('crm');
  const [updateTrigger, setUpdateTrigger] = useState({});
  const [shouldApproveOrReject, setShouldApproveOrReject] =
    useState<boolean>(false);

  const { response, loading } = useFetch(
    () =>
      !workflow
        ? approvalWorkflowAPI.fetchApprovalWorkflowInstanceById(workflowId)
        : null,
    [updateTrigger, workflowId],
  );

  const workflowInstance = workflow || response;

  useEffect(() => {
    if (workflow || response) {
      const approvalsArray = workflowInstance?.approvalProgressStateSets
        ? workflowInstance.approvalProgressStateSets.map(
            (e) => e.progressStateItems,
          )
        : [];

      const result = ApprovalHelpers.userHasPendingApproval(
        currentUserId,
        approvalsArray,
        workflowInstance?.status as number,
      );

      setShouldApproveOrReject(result);
    }
  }, [response, workflow]);

  const handleApprove = async (reason: string) => {
    await approvalAPI.approveCompliance(workflowId, reason);
    setUpdateTrigger({});
    onUpdate();
  };

  const handleReject = async (reason: string) => {
    await approvalAPI.rejectCompliance(workflowId, reason);
    setUpdateTrigger({});
    onUpdate();
  };

  return (
    <LoadingWrapper loading={loading}>
      {workflowInstance && (
        <ApprovalsMatrix
          approvalProgressStateSets={workflowInstance.approvalProgressStateSets}
        />
      )}
      {shouldApproveOrReject && !hasComplianceInfo && (
        <StyledMessage>
          {t('client_group.workflow.no_compliance_info')}
        </StyledMessage>
      )}
      <WorkflowActions
        canReject={shouldApproveOrReject}
        canApprove={shouldApproveOrReject}
        disabled={!hasComplianceInfo}
        onApprove={handleApprove}
        onReject={handleReject}
      />
    </LoadingWrapper>
  );
};

const StyledMessage = styled(Typography.Text)`
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizeSm};
  color: ${({ theme }) => theme.textLightColor3} !important;
`;

export default ComplianceWorkflowStatus;
