import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '../../../../hooks/useTranslation';
import { darkTheme } from '@resources/theme/styled';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { DateHelpers } from '@helpers/date';
import { COUNTRY_CODES } from 'constants/countryCodes';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { TransactionModel } from 'typings/finance/transaction';
import { ApprovalWorkflowInstanceModel } from '../../../../typings/approval/approvalWorkflow';
import {
  AccountInfo,
  ExternalTransactionBank,
  TransactionReviewTarget,
} from 'typings/compliance/transactions';

// components
import Text from '@core_components/Text';
import Link from '@common_components/Texts/Link';
import DocumentLink from 'components/Typography/DocumentLink';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import TransactionStatus from 'components/Typography/TransactionStatus';
import ViewApprovalMatrix from './ViewApprovalMatrix';
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';
import { Divider } from 'antd';

interface IProps {
  transaction: TransactionModel;
  outgoingTransaction: TransactionReviewTarget;
  workflow: ApprovalWorkflowInstanceModel | null;
}

const ValueWrapper = memo(
  ({
    children,
    maxTextContainerWidth = '240px',
  }: {
    maxTextContainerWidth?: string;
    children: React.ReactNode;
  }) => {
    return (
      <EllipsisTooltip
        maxTextContainerWidth={maxTextContainerWidth}
        title={children}
      >
        {children}
      </EllipsisTooltip>
    );
  },
);
const OutgoingTransactionDetails = memo(
  ({ transaction, outgoingTransaction, workflow }: IProps) => {
    const history = useHistory();
    const { t } = useTranslation(['banking', 'compliance', 'common']);
    const [hasORACUserDetailsAccess] = useUserAccess([
      'ORAC_Users_Details_View',
    ]);

    const data = useMemo<DescriptionItem[]>(() => {
      function renderFromAccount(data: AccountInfo, reference: string) {
        return (
          <StyledInnerDescriptionSection
            size="small"
            bordered={false}
            background="none"
            data={[
              {
                label: t('transactions.account_name', { ns: 'compliance' }),
                description: <ValueWrapper>{data.accountName}</ValueWrapper>,
              },
              {
                label: t('transactions.account_number', { ns: 'compliance' }),
                description: <ValueWrapper>{data.accountNumber}</ValueWrapper>,
              },
              {
                label: t('transactions.address', { ns: 'compliance' }),
                description: <ValueWrapper>{data.address1}</ValueWrapper>,
              },
              {
                label: t('transactions.from_reference', { ns: 'compliance' }),
                description: <ValueWrapper>{reference}</ValueWrapper>,
              },
            ]}
          />
        );
      }

      function renderBankInfo(data: ExternalTransactionBank) {
        return (
          <StyledInnerDescriptionSection
            size="small"
            bordered={false}
            background="none"
            data={[
              {
                label: t(
                  'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_code',
                ),
                description: <ValueWrapper>{data.code}</ValueWrapper>,
              },
              {
                label: t(
                  'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_name',
                ),
                description: <ValueWrapper>{data.name}</ValueWrapper>,
              },
              {
                label: t(
                  'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_country',
                ),
                description: (
                  <ValueWrapper>
                    {data.countryCode ? COUNTRY_CODES[data.countryCode] : ''}
                  </ValueWrapper>
                ),
              },
              {
                label: t(
                  'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_address',
                ),
                description: <ValueWrapper>{data.address1}</ValueWrapper>,
              },
              {
                label: t(
                  'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_city',
                ),
                description: <ValueWrapper>{data.city}</ValueWrapper>,
              },
              {
                label: t(
                  'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_district',
                ),
                description: <ValueWrapper>{data.district}</ValueWrapper>,
              },
              {
                label: t(
                  'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_postal_code',
                ),
                description: <ValueWrapper>{data.postCode}</ValueWrapper>,
              },
            ]}
          />
        );
      }

      function renderCustomerInfo(data: AccountInfo) {
        return (
          <StyledInnerDescriptionSection
            size="small"
            bordered={false}
            background="none"
            data={[
              {
                label: t(
                  'banking.transactions.add_transaction.form_fields.customer_section.account_number',
                ),
                description: <ValueWrapper>{data.accountNumber}</ValueWrapper>,
              },
              {
                label: t(
                  'banking.transactions.add_transaction.form_fields.customer_section.account_name',
                ),
                description: <ValueWrapper>{data.accountName}</ValueWrapper>,
              },
              {
                label: t(
                  'banking.transactions.add_transaction.form_fields.customer_section.country',
                ),
                description: data.country ? (
                  <ValueWrapper>{COUNTRY_CODES[data.country]}</ValueWrapper>
                ) : (
                  ''
                ),
              },
              {
                label: t(
                  'banking.transactions.add_transaction.form_fields.customer_section.address',
                ),
                description: <ValueWrapper>{data.address1}</ValueWrapper>,
              },
              {
                label: t(
                  'banking.transactions.add_transaction.form_fields.customer_section.city',
                ),
                description: <ValueWrapper>{data.city}</ValueWrapper>,
              },
              {
                label: t(
                  'banking.transactions.add_transaction.form_fields.customer_section.district',
                ),
                description: <ValueWrapper>{data.district}</ValueWrapper>,
              },
              {
                label: t(
                  'banking.transactions.add_transaction.form_fields.customer_section.post_code',
                ),
                description: <ValueWrapper>{data.postCode}</ValueWrapper>,
              },
            ]}
          />
        );
      }

      return [
        {
          label: t('transactions.transaction_id', { ns: 'compliance' }),
          description: (
            <Text>{outgoingTransaction.transaction.transactionNumber}</Text>
          ),
        },
        {
          label: t('transactions.purpose', { ns: 'compliance' }),
          description: (
            <ValueWrapper maxTextContainerWidth="320px">
              {transaction.purpose}
            </ValueWrapper>
          ),
        },

        {
          label: t('transactions.ordering_customer', { ns: 'compliance' }),
          description: renderFromAccount(
            outgoingTransaction.orderingAccount,
            outgoingTransaction?.transaction?.senderReference || '',
          ),
        },

        {
          label: t('transactions.beneficiary_customer', { ns: 'compliance' }),
          description: (
            <>
              <Text variant="h5" color={darkTheme.whiteColor}>
                {t(
                  'banking.transactions.add_transaction.intermediary_institution_section_title',
                )}
              </Text>
              {outgoingTransaction.correspondentInstitution
                ?.intermediaryInstitution ? (
                renderBankInfo(
                  outgoingTransaction.correspondentInstitution
                    .intermediaryInstitution,
                )
              ) : (
                <Text color={darkTheme.textLightColor4}>
                  {t('value_is_not_provided', { ns: 'common' })}
                </Text>
              )}
              <StyledDivider />

              <Text variant="h5" color={darkTheme.whiteColor}>
                {t(
                  'banking.transactions.add_transaction.account_with_institution_section_title',
                )}
              </Text>
              {outgoingTransaction.correspondentInstitution
                ?.accountWithInstitution ? (
                renderBankInfo(
                  outgoingTransaction.correspondentInstitution
                    .accountWithInstitution,
                )
              ) : (
                <Text color={darkTheme.textLightColor4}>
                  {t('value_is_not_provided', { ns: 'common' })}
                </Text>
              )}
              <StyledDivider />

              <Text variant="h5" color={darkTheme.whiteColor}>
                {t(
                  'banking.transactions.add_transaction.beneficiary_customer_section_title',
                )}
              </Text>
              {outgoingTransaction.beneficiaryAccount ? (
                renderCustomerInfo(outgoingTransaction.beneficiaryAccount)
              ) : (
                <Text color={darkTheme.textLightColor4}>
                  {t('value_is_not_provided', { ns: 'common' })}
                </Text>
              )}
              <StyledDivider />
              <Text variant="h5" color={darkTheme.whiteColor}>
                {t(
                  'banking.transactions.add_transaction.remittance_information_section_title',
                )}
              </Text>
              {outgoingTransaction.remittance ? (
                <StyledInnerDescriptionSection
                  size="small"
                  bordered={false}
                  background="none"
                  data={[
                    {
                      label: t(
                        'banking.transactions.add_transaction.form_fields.line',
                        { number: 1 },
                      ),
                      description: (
                        <ValueWrapper>
                          {
                            outgoingTransaction.remittance
                              .remittanceInformationLine1
                          }
                        </ValueWrapper>
                      ),
                    },
                    {
                      label: t(
                        'banking.transactions.add_transaction.form_fields.line',
                        { number: 2 },
                      ),
                      description: (
                        <ValueWrapper>
                          {
                            outgoingTransaction.remittance
                              .remittanceInformationLine2
                          }
                        </ValueWrapper>
                      ),
                    },
                    {
                      label: t(
                        'banking.transactions.add_transaction.form_fields.line',
                        { number: 3 },
                      ),
                      description: (
                        <ValueWrapper>
                          {
                            outgoingTransaction.remittance
                              .remittanceInformationLine3
                          }
                        </ValueWrapper>
                      ),
                    },
                    {
                      label: t(
                        'banking.transactions.add_transaction.form_fields.line',
                        { number: 4 },
                      ),
                      description: (
                        <ValueWrapper>
                          {
                            outgoingTransaction.remittance
                              .remittanceInformationLine4
                          }
                        </ValueWrapper>
                      ),
                    },
                  ]}
                />
              ) : (
                <Text color={darkTheme.textLightColor4}>
                  {t('value_is_not_provided', { ns: 'common' })}
                </Text>
              )}
            </>
          ),
        },

        {
          label: t('transactions.amount', { ns: 'compliance' }),
          description: (
            <Text>
              {outgoingTransaction.transaction.currencyIsoCode}{' '}
              {outgoingTransaction.transaction.settledAmount
                ? AccountsHelpers.formatAmountToLocaleString(
                    outgoingTransaction.transaction.settledAmount,
                  )
                : null}
            </Text>
          ),
        },

        {
          label: t('transactions.value_date', { ns: 'compliance' }),
          description: (
            <Text>
              {outgoingTransaction.transaction.valueDate
                ? DateHelpers.formatTimestampToString(
                    outgoingTransaction.transaction.valueDate as any,
                  )
                : null}
            </Text>
          ),
        },

        {
          label: t('transactions.requested_by', { ns: 'compliance' }),
          description: (
            <Text>
              {hasORACUserDetailsAccess ? (
                <Link
                  onClick={() =>
                    history.push({
                      pathname: RoutePaths.ORAC_User_Details,
                      search: `?id=${transaction.creatorUserId}`,
                    })
                  }
                >
                  {transaction.creatorUserName}
                </Link>
              ) : (
                transaction.creatorUserName
              )}
              {`, ${DateHelpers.formatTimestampToString(transaction.createdOn)}`}
            </Text>
          ),
        },

        {
          label: t('transactions.transaction_status', { ns: 'compliance' }),
          description: <TransactionStatus status={transaction.status} />,
        },

        {
          label: t('transactions.related_documents', { ns: 'compliance' }),
          description: (
            <>
              {outgoingTransaction.documents &&
              outgoingTransaction.documents.length
                ? outgoingTransaction.documents.map(
                    ({ dmsId, name, linkToDownload }) => (
                      <div key={dmsId}>
                        <DocumentLink
                          text={name}
                          fileId={linkToDownload}
                          documentId={dmsId}
                        />
                      </div>
                    ),
                  )
                : null}
            </>
          ),
        },
        {
          label: t('transactions.client_approval', { ns: 'compliance' }),
          description: (
            <ViewApprovalMatrix
              workflowId={transaction.workflowId}
              workflowType="client"
              clientGroupNumericId={
                outgoingTransaction.transaction.orderingAccountClientId
              }
            />
          ),
        },
        {
          label: t('transactions.admin_approval', { ns: 'compliance' }),
          description: workflow ? (
            <ViewApprovalMatrix
              workflowId={workflow.id}
              workflowType="admin"
              clientGroupNumericId={null}
            />
          ) : (
            <Text color={darkTheme.warningColor}>
              {t('transactions.not_exist_approval_workflow_message', {
                ns: 'compliance',
              })}
            </Text>
          ),
        },
      ];
    }, [transaction, outgoingTransaction, hasORACUserDetailsAccess]);

    return <StyledDescriptionSection data={data} />;
  },
);

const StyledDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-label {
    width: 200px !important;
    max-width: 200px !important;
  }
`;

const StyledInnerDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-label {
    width: 160px !important;
    max-width: 160px !important;
  }
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.marginXs} 0`};
`;

export default OutgoingTransactionDetails;
