import React from 'react';

// helpers
import { clientGroupsAPI, TagModel } from 'api/crm/clientGroup/clientGroupsAPI';

// components
import Autocomplete, {
  AutocompleteOption,
  AutocompleteProps,
} from '@core_components/Autocomplete';

type IProps = AutocompleteProps<TagModel>;

const TagsAutocomplete = (props: IProps) => {
  const fetchTags = async (_: string) => {
    const { data } = await clientGroupsAPI.fetchTags();

    return data.map<AutocompleteOption<TagModel>>((tag: TagModel) => {
      return {
        id: tag.slug,
        label: tag.name,
        model: tag,
      };
    });
  };

  return <Autocomplete {...props} fetchData={fetchTags} />;
};

export default TagsAutocomplete;
