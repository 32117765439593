import React from 'react';

// helpers
import { OnboardType } from '../../../../../enums/crm/crm';
import { FormValuesModel as NoteFormValuesModel } from '../NoteModalDialog';
import { AddNewRelationshipFormValidationSchema } from '../../../../../validations/crm/entities';
import { FormValuesModel as RelationshipFormValuesModel } from '../../../../Forms/TemplateForms/CRM/RelationshipForm';

// components
import InnerForm from './InnerForm';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export interface RelationshipWithNoteFormValuesModel
  extends RelationshipFormValuesModel {
  note?: NoteFormValuesModel;
}

export type FormValuesModel = {
  entryId: string;
  entryName: string;
  entryType: OnboardType;
  relationships: RelationshipWithNoteFormValuesModel[];
};

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
}

const AddRelationshipModalDialog = ({
  title,
  isVisible,
  initialValues,
  onSubmit,
  closeCallback,
}: IProps) => {
  return (
    <Form
      enableReinitialize
      confirmExitWithoutSaving
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={AddNewRelationshipFormValidationSchema}
      renderForm={
        <ModalDialog
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default AddRelationshipModalDialog;
