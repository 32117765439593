import React, { memo, useMemo, useRef } from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '../../../../../hooks/useTranslation';
import { TransactionTypes } from '../../../../../enums/banking/transactions';
import { TransactionValidationSchema } from '../../../../../validations/accounting/transactions';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import TransactionForm, {
  FormValuesModel as TransactionFormValuesModel,
} from '../../../../Forms/TemplateForms/Banking/TransactionForm';
import { Button, Col, Modal, Row } from 'antd';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
  mode: 'create' | 'update' | 'view';
  onReturnClick?: () => Promise<void>;
  onRejectOutgoingTransactionClick?: () => Promise<void>;
}

export type FormValuesModel = TransactionFormValuesModel;

const TransactionModalDialog = memo(
  ({
    title,
    isVisible,
    closeCallback,
    initialValues,
    onSubmit,
    mode,
    onReturnClick,
    onRejectOutgoingTransactionClick,
  }: IProps) => {
    const { t } = useTranslation(['banking', 'common']);
    const formRef = useRef<any>();
    const [
      hasReturnAccess,
      hasRejectAccess,
      hasTransmitAccess,
      hasSubmitAccess,
    ] = useUserAccess([
      'Banking_Transactions_Return',
      'Banking_Transactions_Reject',
      'Banking_Transactions_Transmit',
      'Banking_Transactions_Submit',
    ]);

    const onSaveClick = async () => {
      await formRef.current.setFieldValue('submitAction', 'save');
      formRef.current.handleSubmit();
    };

    const onSubmitClick = async () => {
      await formRef.current.setFieldValue('submitAction', 'submit');
      formRef.current.handleSubmit();
    };

    const handleReturnClick = async () => {
      if (onReturnClick) {
        Modal.confirm({
          title: t(
            'banking.transactions.add_transaction.return_transaction.title',
          ),
          content: t(
            'banking.transactions.add_transaction.return_transaction.description',
          ),
          okType: 'danger',
          okText: t('yes', { ns: 'common' }),
          cancelText: t('no', { ns: 'common' }),
          onOk: onReturnClick,
        });
      }
    };

    const handleTransmitClick = async () => {
      await formRef.current.setFieldValue('submitAction', 'transmit');
      formRef.current.handleSubmit();
    };

    const extendedFooterButtons = useMemo<React.ReactNode | undefined>(() => {
      if (!initialValues) {
        return undefined;
      }

      const result = [];

      if (initialValues.transactionType === TransactionTypes.Outgoing) {
        if (hasRejectAccess) {
          result.push(
            <Col>
              <Button
                key="submit_btn"
                danger
                size="large"
                onClick={onRejectOutgoingTransactionClick}
              >
                {t(
                  'banking.transactions.add_transaction.add_modal.reject_button',
                )}
              </Button>
            </Col>,
          );
        }

        if (hasTransmitAccess) {
          result.push(
            <Col>
              <Button
                key="return_btn"
                size="large"
                type="primary"
                onClick={handleTransmitClick}
              >
                {t(
                  'banking.transactions.add_transaction.add_modal.transmit_button',
                )}
              </Button>
            </Col>,
          );
        }
      } else {
        if (mode === 'update') {
          if (hasSubmitAccess) {
            result.push(
              <Col>
                <Button
                  key="submit_btn"
                  type="primary"
                  size="large"
                  onClick={onSubmitClick}
                  disabled={!hasSubmitAccess}
                >
                  {t(
                    'banking.transactions.add_transaction.add_modal.submit_button',
                  )}
                </Button>
              </Col>,
            );
          }

          if (hasReturnAccess) {
            result.push(
              <Col>
                <Button
                  key="return_btn"
                  danger
                  size="large"
                  onClick={handleReturnClick}
                  disabled={!hasReturnAccess}
                >
                  {t(
                    'banking.transactions.add_transaction.return_transaction.return_button',
                  )}
                </Button>
              </Col>,
            );
          }
        } else {
          result.push(
            <Col>
              <Button
                key="submit_btn"
                type="primary"
                size="large"
                onClick={onSubmitClick}
                disabled={!hasSubmitAccess}
              >
                {t(
                  'banking.transactions.add_transaction.add_modal.submit_button',
                )}
              </Button>
            </Col>,
          );
        }
      }

      return result.length ? (
        <Row gutter={[16, 16]}>{result.length == 1 ? result[0] : result}</Row>
      ) : undefined;
    }, [mode, initialValues]);

    return (
      <Form<FormValuesModel>
        disabled={mode === 'view'}
        confirmExitWithoutSaving
        enableReinitialize
        innerRef={formRef}
        onSubmit={onSubmit}
        initialValues={isVisible ? initialValues : null}
        validationSchema={TransactionValidationSchema}
        renderForm={
          <ModalDialog
            title={title}
            isVisible={isVisible}
            closeCallback={closeCallback}
            hideFooterButtons={!initialValues || mode === 'view'}
            extendedFooterButtonsPosition="after"
            extendedFooterButtons={extendedFooterButtons}
            submitButtonProps={
              mode !== 'view'
                ? {
                    hidden:
                      initialValues?.transactionType ===
                      TransactionTypes.Outgoing,
                    onClick: onSaveClick,
                    text:
                      mode === 'create'
                        ? t(
                            'banking.transactions.add_transaction.add_modal.suspense_button',
                          )
                        : t(
                            'banking.transactions.add_transaction.edit_modal.save_button',
                          ),
                  }
                : { hidden: mode === 'view' }
            }
          >
            <LoadingWrapper loading={!initialValues}>
              {!!initialValues && <TransactionForm />}
            </LoadingWrapper>
          </ModalDialog>
        }
      />
    );
  },
);

export default TransactionModalDialog;
