import APIConfig from '../../../config/api';
import { Moment } from 'moment';
import { GraphData } from '../../../components/Charts/GraphChart';
import { APIService } from '../../axiosInstance';
import { IRelationshipModel } from '../../../typings/crm/entity';
import { RelationshipsGraph } from '../../../typings/crm/relationships-graph';
import { relationshipAdapter } from '../../../apiAdapters/crm/relationship/relationshipAdapter';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';
import {
  AdditionalFieldType,
  AssociateTypes,
  CrmItemType,
} from '../../../enums/crm/crm';
import {
  CreateRelationshipsBodyModel,
  ModifyRelationshipsBodyModel,
  UpdateRelationshipBodyModel,
} from '../../../typings/crm/relationships';

export interface IAdditionalFieldAnswerModel {
  name: string;
  type: AdditionalFieldType;
  valueJSON: string;
}

export interface IEntityRelationshipReqBody {
  relationshipTemplateId: string;

  parent: RelationshipEntryModel;
  child: RelationshipEntryModel;

  additionalFields: IAdditionalFieldAnswerModel[];

  notes: string;

  startDate: Moment | null;
  endDate: Moment | null;
}

export interface RelationshipEntryModel {
  type: AssociateTypes;
  id: string;
}

interface FetchRelationshipsParamsModel extends FetchParamsModel {
  itemId?: string;
  relationshipTemplateId?: string;
}

interface FetchCrmItemsParams extends FetchParamsModel {
  search?: string;
  isVendor?: boolean;
  includeClientGroups?: boolean;
}

interface FetchCrmItemsWithClientGroupsParams extends FetchParamsModel {
  search?: string;
}

export interface CrmItemModel {
  _id: string;
  name: string;
  type: CrmItemType;
}

interface FetchResponseCrmItemsModel {
  result: CrmItemModel[];
}

export type ClientGroupShortForCRMItemFromQuery = {
  _id: string;
  status: string;
  numericId: number;
  shortName: string;
  reviewStatus: string;
  clientGroupName: string;
  directClientItemId: string;
};

export type CRMItemWithClientGroupsFromQuery = {
  _id: string;
  type: CrmItemType;
  name: string;
  username: string;
  clientGroups: FetchResponseModel<ClientGroupShortForCRMItemFromQuery>;
};

const relationshipAPI = {
  fetchRelationships: (params: FetchRelationshipsParamsModel) => {
    return APIService.get<FetchResponseModel<IRelationshipModel>>(
      `${APIConfig.crmApi}/relationships`,
      { params },
    ).then(({ data }) => data);
  },

  getRelationshipTree: (
    directClientId: string,
    relationshipScopeId?: string,
    applicantOrganizationId?: string,
    applicantContactId?: string,
  ) => {
    return APIService.get<RelationshipsGraph>(
      `${APIConfig.crmApi}/relationships/${directClientId}/structure`,
      {
        params: { relationshipScopeId },
      },
    ).then<GraphData>(({ data }) =>
      relationshipAdapter.fetchClientGroupRelationshipsGraph(
        data,
        directClientId,
        applicantOrganizationId,
        applicantContactId,
      ),
    );
  },

  getCrmItems: (params: FetchCrmItemsParams) => {
    return APIService.get<FetchResponseCrmItemsModel>(
      `${APIConfig.crmApi}/crm-item/autocomplete`,
      { params },
    ).then(({ data }) => data);
  },

  fetchCRMItemsWithClientGroups: (
    params: FetchCrmItemsWithClientGroupsParams,
  ) => {
    return APIService.get<{ result: CRMItemWithClientGroupsFromQuery[] }>(
      `${APIConfig.crmApi}/crm-item/autocomplete-with-client-groups`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchClientGroupsForEntry: (crmItemsId: string, params: FetchParamsModel) => {
    return APIService.get<
      FetchResponseModel<ClientGroupShortForCRMItemFromQuery>
    >(`${APIConfig.crmApi}/crm-item/${crmItemsId}/related-client-groups`, {
      params,
    }).then(({ data }) => data);
  },

  createRelationships: (body: CreateRelationshipsBodyModel) => {
    return APIService.post(`${APIConfig.crmApi}/relationships`, body);
  },

  updateRelationshipById: (
    relationshipId: string,
    body: UpdateRelationshipBodyModel,
  ) => {
    return APIService.put(
      `${APIConfig.crmApi}/relationships/${relationshipId}`,
      body,
    );
  },

  deleteRelationshipById: (relationshipId: string) => {
    return APIService.delete(
      `${APIConfig.crmApi}/relationships/${relationshipId}`,
    );
  },

  updateRelationships: (
    body: ModifyRelationshipsBodyModel,
    clientGroupId: string,
  ) => {
    return APIService.post(
      `${APIConfig.crmApi}/client-group-profile/${clientGroupId}/batch`,
      body,
    );
  },
};

export { relationshipAPI };
