import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { ClientLockoutStatus } from 'enums/orac/orac';

// components
import StatusLabel from '../../../Typography/StatusLabel';

const STATUS_MAP = {
  info: [],
  warning: [],
  success: [ClientLockoutStatus.ACTIVE],
  error: [ClientLockoutStatus.LOCKED],
};

interface IProps {
  status: ClientLockoutStatus;
}

const ClientLockoutsStatus = memo(({ status }: IProps) => {
  const { t } = useTranslation('orac');
  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      text={t(`client_lockout_details.statuses.${ClientLockoutStatus[status]}`)}
    />
  );
});

export default ClientLockoutsStatus;
