import React from 'react';

// helpers
import useUserAccess from '../../../../../hooks/useUserAccess';
import useTranslation from '../../../../../hooks/useTranslation';
import { FormikProps } from 'formik';
import { OnboardType } from '../../../../../enums/crm/crm';
import { RELATIONSHIP_TEMPLATE_INVITED_BY_ID } from '../../../../../constants/crm';
import { EditRelationshipFormValidationSchema } from '../../../../../validations/crm/entities';
import { FormValuesModel as NoteFormValuesModel } from '../NoteModalDialog';
import { FormValuesModel as RelationshipFormValuesModel } from '../../../../Forms/TemplateForms/CRM/RelationshipForm';

// components
import InnerForm from './InnerForm';
import ConfirmDeleteButton from '../../../../Buttons/ConfirmDeleteButton';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export interface RelationshipWithNoteFormValuesModel
  extends RelationshipFormValuesModel {
  note?: NoteFormValuesModel;
}

export type FormValuesModel = {
  delete?: boolean;
  entryId: string;
  entryType: OnboardType;
  entryName: string;
  relationship: RelationshipWithNoteFormValuesModel;
};

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
}

const EditRelationshipModalDialog = ({
  title,
  isVisible,
  initialValues,
  onSubmit,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('crm');
  const [hasDeleteAccess, hasEditAccess] = useUserAccess([
    'CRM_Relationships_Delete',
    'CRM_Relationships_Edit',
  ]);
  const hasAdditionalFields = isVisible
    ? !!initialValues?.relationship?.additionalFields?.length
    : false;

  const onDeleteClick = async (form: FormikProps<FormValuesModel>) => {
    await form.setFieldValue('delete', true);
    await form.submitForm();

    // TODO: for now this is quick fix, the problem is that modal dialog doesn't reset form after submittion by extended buttons
    await form.resetForm({
      values: {} as any,
      errors: {},
      touched: {},
      isSubmitting: false,
      isValidating: false,
      status: undefined,
      submitCount: 0,
    });

    closeCallback(true);
  };

  return (
    <Form
      enableReinitialize
      confirmExitWithoutSaving
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={EditRelationshipFormValidationSchema}
      renderForm={(form) => (
        <ModalDialog
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
          extendedFooterButtonsPosition="before"
          submitButtonProps={{ hidden: !hasAdditionalFields }}
          hideFooterButtons={
            !hasEditAccess ||
            initialValues?.relationship?.relationshipTemplate?.id ===
              RELATIONSHIP_TEMPLATE_INVITED_BY_ID
          }
          extendedFooterButtons={
            hasDeleteAccess && (
              <ConfirmDeleteButton
                title={t('entity.relationships.deleteConfirmation')}
                btnSize="large"
                deleteCallback={() => onDeleteClick(form)}
              />
            )
          }
        >
          <InnerForm canAddNote={hasAdditionalFields} />
        </ModalDialog>
      )}
    />
  );
};

export default EditRelationshipModalDialog;
