import React, { useMemo } from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { DEFAULT_DATE_FORMAT } from 'constants/global';
import { StyledComponentProps } from '../../../typings/common';

// components
import { Col, Layout, Row } from 'antd';

const { Footer } = Layout;

type IProps = StyledComponentProps;

const AppFooter = (props: IProps) => {
  const { t } = useTranslation('common');

  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  const buildDate = useMemo(() => {
    const buildMoment = moment(process.env.REACT_APP_BUILD_VERSION, 'YYYYMMDD');
    return buildMoment.isValid() ? buildMoment.format(DEFAULT_DATE_FORMAT) : '';
  }, []);

  return (
    <FooterWrapper {...props}>
      <Row justify="space-between" align="middle">
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          © {currentYear} Tenet Bank Ltd.
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <BuildTextWrapper>
            {t('app_version', {
              release: process.env.REACT_APP_RELEASE_VERSION || 'unknown',
              build: process.env.REACT_APP_BUILD_VERSION || 'unknown',
              buildDate,
            })}
          </BuildTextWrapper>
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={24} />
      </Row>
    </FooterWrapper>
  );
};

const FooterWrapper = styled(Footer)`
  color: ${({ theme }) => theme.textLightColor3};
  background: ${({ theme }) => theme.footerBackground};
  padding: ${({ theme }) => theme.containerPadding};
  margin-top: ${({ theme }) => theme.footerMargin};
`;

const BuildTextWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default AppFooter;
