import * as yup from 'yup';
import {
  STRING_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
} from 'validations/common';

export const NewUserValidationSchema = yup.object().shape({
  type: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  contactId: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  selectedEmail: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  selectedPhoneNumber: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
});

export const UpdateUserValidationSchema = yup.object().shape({
  name: STRING_VALIDATION_RULE.max(200, 'First name is too long').required(
    'This field is required',
  ),
});

export const UnlockUserValidationSchema = yup.object().shape({
  reason: STRING_VALIDATION_RULE.max(1000, {
    key: 'form:validation_messages.max_characters',
    values: { number: 1000 },
  }).required('form:validation_messages.required'),
});
