import React from 'react';

// helpers
import { styled } from 'styled-components';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import AddEditNote from './AddEditNote';
import RelationshipForm from '../../../../../Forms/TemplateForms/CRM/RelationshipForm';
import { Col, Row } from 'antd';

interface IProps {
  canAddNote: boolean;
}

const InnerForm = ({ canAddNote }: IProps) => {
  const { values } = useFormikContext<FormValuesModel>();
  return (
    <>
      {canAddNote ? (
        <StyledRow justify="end">
          <Col>
            <AddEditNote fieldName="relationship" />
          </Col>
        </StyledRow>
      ) : null}
      <RelationshipForm
        entryId={values.entryId}
        fieldName="relationship"
        entryType={values.entryType}
      />
    </>
  );
};

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default InnerForm;
