import React from 'react';

// helpers
import { NewUserValidationSchema } from 'validations/orac/users';

// components
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import UserForm, {
  UserFormValuesModel,
} from 'components/Forms/TemplateForms/ORAC/UserForm';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<UserFormValuesModel> {
  title: string;
}

const UserModalDialog = ({
  title,
  isVisible,
  initialValues,
  onSubmit,
  closeCallback,
}: IProps) => {
  return (
    <Form<UserFormValuesModel>
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={NewUserValidationSchema}
      confirmExitWithoutSaving
      renderForm={
        <ModalDialog
          title={title}
          width={900}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <UserForm />
        </ModalDialog>
      }
    />
  );
};

export default UserModalDialog;
