import * as yup from 'yup';
import { OnboardType } from 'enums/crm/crm';
import { RelationshipFormValidationSchema } from '../entities';
import { CRM_CONTACT_NAME_MAX_LENGTH, NAME_MAX_LENGTH } from 'constants/global';
import {
  CRM_NAME_VALIDATION_RULE,
  EMAIL_VALIDATION_RULE,
  NULLABLE_BOOLEAN_VALIDATION_RULE,
  NULLABLE_OBJECT_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
  PHONE_ASYNC_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
} from 'validations/common';

export const ApplicationDocumentationValidationSchema = yup.object().shape({
  additionalFields: yup.array().of(
    yup.object().shape({
      reviewComment: STRING_VALIDATION_RULE.max(1000, {
        key: 'form:validation_messages.max_characters',
        values: { number: 1000 },
      }).when('notReviewed', {
        is: false,
        then: (schema) =>
          schema.when('isFinalized', {
            is: false,
            then: (schema) =>
              schema.required('form:validation_messages.required'),
          }),
      }),
    }),
  ),
});

export const ReviewIdentificationDocumentsValidationSchema = yup
  .object()
  .shape({
    passports: yup.array().of(
      yup.object().shape({
        reviewComment: STRING_VALIDATION_RULE.max(1000, {
          key: 'form:validation_messages.max_characters',
          values: { number: 1000 },
        }).when('notReviewed', {
          is: false,
          then: (schema) =>
            schema.when('canReview', {
              is: true,
              then: (schema) =>
                schema.required('form:validation_messages.required'),
            }),
        }),
      }),
    ),
    addresses: yup.array().of(
      yup.object().shape({
        reviewComment: STRING_VALIDATION_RULE.max(1000, {
          key: 'form:validation_messages.max_characters',
          values: { number: 1000 },
        }).when('notReviewed', {
          is: false,
          then: (schema) =>
            schema.when('canReview', {
              is: true,
              then: (schema) =>
                schema.required('form:validation_messages.required'),
            }),
        }),
      }),
    ),
  });

export const AddAdditionalDocumentValidationSchema = yup.object().shape({
  name: STRING_VALIDATION_RULE.required('This field is required'),
  description: STRING_VALIDATION_RULE.required('This field is required'),
  type: STRING_VALIDATION_RULE.nullable().required('This field is required'),
  options: yup.object().when('type', {
    is: 'select',
    then: (schema) =>
      schema.shape({
        selectOptionsValues: yup
          .array()
          .min(1, 'This field is required')
          .nullable()
          .of(
            yup
              .mixed()
              .test(
                'minLength',
                'Answer should have at least 2 characters long.',
                (val) => {
                  return String(val).length > 2;
                },
              )
              .test(
                'maxLength',
                'Answer cannot be more than 30 characters long.',
                (val) => String(val).length < 30,
              ),
          ),
        formDocument: yup
          .array()
          .nullable()
          .min(1, 'This field is required')
          .max(1, 'Only one document is required'),
        documentRequiresCertification: yup.boolean(),
      }),
    otherwise: (schema) =>
      schema.shape({
        formDocument: yup
          .array()
          .nullable()
          .min(1, 'This field is required')
          .max(1, 'Only one document is required'),
        documentRequiresCertification: yup.boolean(),
      }),
  }),
});

export const RequestDataUpdateValidationSchema = yup.object().shape({
  message: STRING_VALIDATION_RULE.required('This field is required'),
});

export const SubmitClientGroupComplianceDataValidationSchema = yup
  .object()
  .shape({
    riskRatingMatrixDocument: yup
      .array()
      .min(1, 'form:validation_messages.required'),
    riskLevel: STRING_VALIDATION_RULE.required({
      key: 'form:validation_messages.required_with_label',
      values: { label: 'client_group.compliance.riskLevel' },
    }),
    dueDiligence: NULLABLE_BOOLEAN_VALIDATION_RULE.required({
      key: 'form:validation_messages.required_with_label',
      values: { label: 'client_group.compliance.dueDiligence' },
    }),
    dueDiligenceDetails: STRING_VALIDATION_RULE.when('dueDiligence', {
      is: true,
      then: (schema) =>
        schema.required({
          key: 'form:validation_messages.required_with_label',
          values: { label: 'client_group.compliance.dueDiligenceDetails' },
        }),
    }),
  });

export const UpdateAnswerOfAdditionalFieldValidationSchema = yup
  .object()
  .shape({
    additionalField: yup.object().shape({
      value: yup.mixed().when('isNotProvidedReason', {
        is: false,
        then: () =>
          yup.mixed().when('value', (value, schema) => {
            if (!value.length) {
              return schema.required('form:validation_messages.required');
            }

            switch (typeof value[0]) {
              case 'object':
                return Array.isArray(value[0])
                  ? yup.array().min(1, 'form:validation_messages.required')
                  : schema.required('form:validation_messages.required');
              case 'number':
                return yup
                  .number()
                  .nullable()
                  .required('form:validation_messages.required');
              case 'string':
                return STRING_VALIDATION_RULE.required(
                  'form:validation_messages.required',
                );
              default:
                return schema.required('form:validation_messages.required');
            }
          }),
      }),

      notProvidedReason: STRING_VALIDATION_RULE.when('isNotProvidedReason', {
        is: true,
        then: (schema) => schema.required('form:validation_messages.required'),
      }),
    }),
  });

export const ContactShortInfoFormValidationSchema = yup.object().shape({
  firstName: CRM_NAME_VALIDATION_RULE.max(CRM_CONTACT_NAME_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label:
        'client_group.structure.relationships.form_fields.individual.first_name',
      number: CRM_CONTACT_NAME_MAX_LENGTH,
    },
  }).required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'client_group.structure.relationships.form_fields.individual.first_name',
    },
  }),

  middleName: CRM_NAME_VALIDATION_RULE.max(CRM_CONTACT_NAME_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label:
        'client_group.structure.relationships.form_fields.individual.middle_name',
      number: CRM_CONTACT_NAME_MAX_LENGTH,
    },
  }),

  lastName: CRM_NAME_VALIDATION_RULE.max(CRM_CONTACT_NAME_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label:
        'client_group.structure.relationships.form_fields.individual.last_name',
      number: CRM_CONTACT_NAME_MAX_LENGTH,
    },
  }).required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'client_group.structure.relationships.form_fields.individual.last_name',
    },
  }),

  email: EMAIL_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'client_group.structure.relationships.form_fields.individual.email_address',
    },
  }),

  phone: PHONE_ASYNC_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'client_group.structure.relationships.form_fields.individual.phone_number',
    },
  }),

  isPEP: NULLABLE_BOOLEAN_VALIDATION_RULE.required({
    key: 'form:validation_messages.required',
  }),

  pepInformation: STRING_VALIDATION_RULE.when('isPEP', {
    is: true,
    then: (schema) =>
      schema.required({
        key: 'form:validation_messages.required_with_label',
        values: {
          label:
            'client_group.structure.relationships.form_fields.individual.pep_information',
        },
      }),
  }),

  isOnlineUser: NULLABLE_BOOLEAN_VALIDATION_RULE.when(
    'isPermissionsSelectAvailable',
    {
      is: true,
      then: (schema) => schema.required('form:validation_messages.required'),
    },
  ),

  adminPermissionType: NULLABLE_STRING_VALIDATION_RULE.when('isOnlineUser', {
    is: true,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),

  accountPermissions: NULLABLE_STRING_VALIDATION_RULE.when('isOnlineUser', {
    is: true,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),
});

export const OrganizationShortInfoFormValidationSchema = yup.object().shape({
  name: CRM_NAME_VALIDATION_RULE.max(NAME_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label:
        'client_group.structure.relationships.form_fields.organization.legal_name',
      number: NAME_MAX_LENGTH,
    },
  }).required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'client_group.structure.relationships.form_fields.organization.legal_name',
    },
  }),

  isRegulated: NULLABLE_BOOLEAN_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'client_group.structure.relationships.form_fields.organization.is_regulated_label',
    },
  }),

  regulatedCountries: yup
    .array()
    .of(STRING_VALIDATION_RULE)
    .when('isRegulated', {
      is: true,
      then: (schema) =>
        schema.min(1, {
          key: 'form:validation_messages.required',
        }),
    }),
});

export const AddNewNodeFormValidationSchema = yup.object().shape({
  clientGroupId: STRING_VALIDATION_RULE,
  activeApplicationScopeId: STRING_VALIDATION_RULE,

  template: yup.object().shape({
    id: NULLABLE_STRING_VALIDATION_RULE.required({
      key: 'form:validation_messages.required_with_label',
      values: {
        label:
          'client_group.structure.relationships.form_fields.relationship_type',
      },
    }),
  }),

  contact: NULLABLE_OBJECT_VALIDATION_RULE.when('template.visibleFor', {
    is: OnboardType.Contact,
    then: () => ContactShortInfoFormValidationSchema,
  }),

  organization: NULLABLE_OBJECT_VALIDATION_RULE.when('template.visibleFor', {
    is: OnboardType.Organization,
    then: () => OrganizationShortInfoFormValidationSchema,
  }),
  relationships: yup
    .array()
    .of(RelationshipFormValidationSchema)
    .min(1, 'form:validation_messages.required'),
});

export const EditNodeFormValidationSchema = yup.object().shape({
  clientGroupId: STRING_VALIDATION_RULE,
  activeScopeId: STRING_VALIDATION_RULE,
  clientUserId: NULLABLE_STRING_VALIDATION_RULE,

  entryType: NULLABLE_STRING_VALIDATION_RULE,

  contact: NULLABLE_OBJECT_VALIDATION_RULE.when('entryType', {
    is: OnboardType.Contact,
    then: () => ContactShortInfoFormValidationSchema,
  }),

  organization: NULLABLE_OBJECT_VALIDATION_RULE.when('entryType', {
    is: OnboardType.Organization,
    then: () => OrganizationShortInfoFormValidationSchema,
  }),

  relationships: yup.array().of(RelationshipFormValidationSchema),
});

export const StructureMessageValidationSchema = yup.object().shape({
  message: STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required',
  }),
});
