import React, { memo, useCallback, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import ongoingReviewsAPI from 'api/compliance/ongoingReviewsAPI';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { OngoingReviewModel } from 'typings/compliance/ongoingReviews';

// components
import SectionIntro from '@common_components/Texts/SectionIntro';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import NewMessagesBadge from 'components/Additional/Messaging/NewMessagesBadge';
import {
  default as UpdatePersonalDetailsTemplateTable,
  ActionKeys,
} from 'components/Tables/TableTemplates/CRM/UpdatePersonalDetailsTable';

const UpdatePersonalDetailsTable = memo(() => {
  const { t } = useTranslation('crm');
  const history = useHistory();
  const [page, setPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      ongoingReviewsAPI.fetchOngoingReviews({
        page,
        limit: 5,
        reviewType: 'update-personal-details',
      }),
    [page],
  );

  const handleActionsClick = useCallback(
    (key: ActionKeys, record: OngoingReviewModel) => {
      switch (key) {
        case 'crm_contact_details_view':
          {
            history.push({
              pathname: RoutePaths.CRM_Contacts_Edit,
              search: `?id=${record.entry.id}`,
            });
          }
          break;
      }
    },
    [history],
  );

  return (
    <>
      <SectionIntro
        titleVariant="h5"
        title={
          <DivAlignCenter>
            {t('update_personal_details_table.title')}
            {response?.total ? (
              <NewMessagesBadge count={response.total} />
            ) : null}
          </DivAlignCenter>
        }
      />
      <LoadingWrapper loading={!response}>
        {!response?.data.length ? (
          t('update_personal_details_table.no_data_message')
        ) : (
          <UpdatePersonalDetailsTemplateTable
            data={response.data}
            total={response.total}
            current={page}
            loading={loading}
            size="small"
            onPaginationChange={setPage}
            onActionsClick={handleActionsClick}
          />
        )}
      </LoadingWrapper>
    </>
  );
});

export default UpdatePersonalDetailsTable;
