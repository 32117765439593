import React, { memo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';

const InnerForm = memo(() => {
  const { t } = useTranslation('orac');

  return (
    <FormField
      name="reason"
      label={t(
        'users.view.lockout_details_table.confirmation_modal.form_fields.reason',
      )}
      component={FormInputTextArea}
    />
  );
});

export default InnerForm;
