import React, { useMemo, useState } from 'react';

// helpers
import moment from 'moment';
import useTranslation from '@hooks/useTranslation';
import { FormikProps, useFormikContext } from 'formik';
import { ContactDetailsFormValues } from '..';

// components
import AddButton from '@common_components/Buttons/AddButton';
import NoteModalDialog, {
  FormValuesModel as NoteFormValuesModel,
} from '../../NoteModalDialog';

const AddEditNote = () => {
  const { t } = useTranslation('crm');
  const [isVisible, setIsVisible] = useState(false);
  const { values, setFieldValue } =
    useFormikContext<ContactDetailsFormValues>();

  const initialFormValues = useMemo<NoteFormValuesModel | null>(() => {
    if (!isVisible) {
      return null;
    }

    if (values.note) {
      return {
        ...values.note,
        crmItemsInitialValue: values.note.crmItems
          ? values.note.crmItems.map((e) => ({
              id: e.itemId,
              label: e.label || '',
              model: e,
            }))
          : [],
      };
    } else {
      return (
        values.note || {
          eventDate: moment(),
          text: '',
          type: '',
          crmItems: [],
        }
      );
    }
  }, [isVisible, values.note]);

  const handleSubmit = (values: NoteFormValuesModel) => {
    setFieldValue('note', { ...values });
  };

  const handleModalClose = () => {
    setIsVisible(false);
  };

  const handleDelete = async (form: FormikProps<NoteFormValuesModel>) => {
    setFieldValue('note', undefined);
    await form.resetForm({
      values: {} as any,
      errors: {},
      touched: {},
      isSubmitting: false,
      isValidating: false,
      status: undefined,
      submitCount: 0,
    });
    handleModalClose();
  };

  return (
    <>
      <AddButton onClick={() => setIsVisible(true)}>
        {values.note ? t('notes.edit_note') : t('notes.add_note')}
      </AddButton>
      <NoteModalDialog
        onSubmit={handleSubmit}
        isVisible={isVisible}
        closeCallback={handleModalClose}
        initialValues={initialFormValues}
        onDelete={values.note ? handleDelete : undefined}
      />
    </>
  );
};

export default AddEditNote;
