import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';
import { ClientTransferApprovalStatuses } from '../../../../enums/transfers/transfers';

// components
import StatusLabel from '../../../Typography/StatusLabel';

const STATUS_MAP = {
  warning: [
    ClientTransferApprovalStatuses.PendingApprovals,
    ClientTransferApprovalStatuses.Processing,
    ClientTransferApprovalStatuses.InReview,
    ClientTransferApprovalStatuses.AMLReview,
    ClientTransferApprovalStatuses.PendingTransmit,
    ClientTransferApprovalStatuses.Scheduled,
  ],
  success: [ClientTransferApprovalStatuses.Completed],
  error: [
    ClientTransferApprovalStatuses.Rejected,
    ClientTransferApprovalStatuses.Failed,
    ClientTransferApprovalStatuses.BankRejected,
  ],
  info: [
    ClientTransferApprovalStatuses.Expired,
    ClientTransferApprovalStatuses.Cancelled,
  ],
};

interface IProps {
  transaction: {
    status: ClientTransferApprovalStatuses;
    expiresAt?: number;
  };
}

const ClientTransactionStatus = memo(({ transaction }: IProps) => {
  const { t } = useTranslation(['banking', 'common']);

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={transaction.status}
      text={
        <>
          {t(
            `transactions.client_transaction_statuses.${ClientTransferApprovalStatuses[transaction.status]}`,
          )}
          {transaction.status ==
            ClientTransferApprovalStatuses.PendingApprovals &&
          transaction.expiresAt
            ? `, ${t('expires', { ns: 'common' })} ${DateHelpers.formatTimestampToString(transaction.expiresAt, DEFAULT_DATE_TIME_FORMAT)}`
            : ''}
        </>
      }
    />
  );
});

export default ClientTransactionStatus;
