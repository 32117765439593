import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { darkTheme } from '@resources/theme/styled';
import { ErrorMessage, useField } from 'formik';

// components
import Text from '@core_components/Text';
import Checkbox from '@core_components/Checkbox';
import FormInput from '@common_components/Form/FormInput';
import FormField from '@core_components/FormField';
import { Col, Row } from 'antd';

interface IProps {
  namePrefix: string;
  disabled?: boolean;
}

export type FormValuesModel = {
  notReviewed: boolean | null;
  isApproved: boolean | null;
  isRejected: boolean | null;
  reviewComment: string;
};

const ReviewDocumentForm = ({ namePrefix, disabled }: IProps) => {
  const { t } = useTranslation('crm');
  const [field, , helpers] = useField<FormValuesModel>(namePrefix);

  const handleNotReviewedChange = (newValue: boolean) => {
    helpers.setValue({
      ...field.value,
      notReviewed: newValue,
      isApproved: false,
      isRejected: false,
      reviewComment: '',
    });
  };

  const handleApprovedChange = (newValue: boolean) => {
    helpers.setValue({
      ...field.value,
      isApproved: newValue,
      notReviewed: false,
      isRejected: false,
    });
  };

  const handleRejectedChange = (newValue: boolean) => {
    helpers.setValue({
      ...field.value,
      isRejected: newValue,
      isApproved: false,
      notReviewed: false,
    });
  };

  return (
    <>
      <Row align="middle">
        <Col>
          <FormField
            shouldShowErrorMessage={false}
            name={`${namePrefix}.notReviewed`}
            component={StyledCheckbox}
            additionalProps={{
              children: t('client_group.approval.not_reviewed'),
            }}
            handleOnChangeManually={() => handleNotReviewedChange(true)}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row align="middle">
        <Col>
          <FormField
            shouldShowErrorMessage={false}
            name={`${namePrefix}.isApproved`}
            component={StyledCheckbox}
            additionalProps={{ children: t('client_group.approval.valid') }}
            onChange={() => handleApprovedChange(true)}
            disabled={disabled}
          />
        </Col>
        <Col>
          <FormField
            shouldShowErrorMessage={false}
            name={`${namePrefix}.isRejected`}
            component={StyledCheckbox}
            additionalProps={{ children: t('client_group.approval.invalid') }}
            onChange={() => handleRejectedChange(true)}
            disabled={disabled}
          />
        </Col>
        <Col>
          <Text variant="span" color={darkTheme.errorColor}>
            <ErrorMessage name={`${namePrefix}.isApproved`} />
          </Text>
        </Col>
      </Row>

      {(field.value.isApproved || field.value.isRejected) && (
        <Text
          color={
            field.value.isApproved
              ? darkTheme.primaryGreen
              : darkTheme.warningColor
          }
        >
          {field.value.isApproved
            ? t('client_group.approval.valid_document_info')
            : t('client_group.approval.invalid_document_info')}
        </Text>
      )}

      {!field.value.notReviewed && (
        <FormField
          name={`${namePrefix}.reviewComment`}
          component={StyledInput}
          placeholder={t('client_group.approval.provide_comments')}
          disabled={disabled}
        />
      )}
    </>
  );
};

const StyledCheckbox = styled(Checkbox)`
  margin-right: ${({ theme }) => theme.marginSm};
`;

const StyledInput = styled(FormInput)`
  margin-top: ${({ theme }) => theme.marginXs};
`;

export default ReviewDocumentForm;
