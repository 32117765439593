import React, { useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import clientLockoutsAPI from 'api/orac/clientLockoutsAPI';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';

// components
import ClientLockoutsTable from 'components/Tables/TableTemplates/ORAC/ClientLockoutsTable';
import { ClientLockoutStatus } from 'enums/orac/orac';

const ClientLockoutDetails = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [filterValues, setFilterValues] = useState({
    contactIds: undefined,
    status: [ClientLockoutStatus.LOCKED],
  } as {
    contactIds: string[] | undefined;
    status: string[] | undefined;
  });

  const { response, loading } = useFetch(
    () =>
      clientLockoutsAPI.fetchClientLockouts({
        contactIds: filterValues.contactIds
          ? filterValues.contactIds.join(',')
          : undefined,
        lockoutStatus: filterValues.status
          ? filterValues.status.join(',')
          : undefined,
        lockoutTypes: undefined,
        page,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [filterValues, page],
  );

  const handleActionsClick = (key: string, value: string) => {
    switch (key) {
      case 'details': {
        history.push({
          pathname: RoutePaths.ORAC_User_Details,
          search: `?id=${value}&tab=security`,
        });
        break;
      }
    }
  };

  const handleFilterChange = (key: string, value: unknown | undefined) => {
    switch (key) {
      case 'contact':
        setFilterValues((prev) => ({
          ...prev,
          contactIds: (value as string[]) || undefined,
        }));
        break;

      case 'lockout_status':
        setFilterValues((prev) => ({
          ...prev,
          status: (value as string[]) || undefined,
        }));
        break;
    }
  };

  const onFilterChange = (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => {
    if (Object.keys(filters).length) {
      Object.keys(filters).forEach((key) =>
        handleFilterChange(key, filters[key] as any),
      );
    }
  };

  return (
    <ClientLockoutsTable
      filters={filterValues}
      onFilterChange={onFilterChange}
      onActionsClick={handleActionsClick}
      data={response?.data || []}
      onPaginationChange={setPage}
      total={response?.total || 0}
      current={page}
      loading={loading}
    />
  );
};

export default ClientLockoutDetails;
