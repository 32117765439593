import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../hooks/useTranslation';
import { CrmItemType } from '../../../../../../../enums/crm/crm';
import { NoteAssociation } from '../../../../../../../typings/note/note';
import { AutocompleteOption } from '@core_components/Autocomplete';
import { StyledComponentProps } from '../../../../../../../typings/common';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../../constants/global';
import { relationshipAPI } from 'api/crm/relationship/relationshipAPI';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';
import OptionWithClientGroupsPopover from './OptionWithClientGroupsPopover';

interface IProps extends StyledComponentProps {
  name: string;
  value: NoteAssociation[];
  initialValue?: AutocompleteOption;
  onChange: (newValue: NoteAssociation[]) => void;
}

const CrmItemAutocomplete = ({
  initialValue,
  value,
  onChange,
  ...rest
}: IProps) => {
  const { t } = useTranslation('crm');

  const formattedFieldValue = useMemo(() => {
    let result: string[] = [];

    if (value.length) {
      result = value.map((e) => e.itemId);
    }

    return result;
  }, [value]);

  const handleOnChange = (
    _: string,
    newValueModel: AutocompleteOption<NoteAssociation>[],
  ) => {
    const newValueFormatted = newValueModel.map((e) => e.model);
    onChange(newValueFormatted as NoteAssociation[]);
  };

  const fetchOptions = async (search: string) => {
    const response = await relationshipAPI.fetchCRMItemsWithClientGroups({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      search,
    });

    return response.result.map((e) => {
      let label = `${e.name} ${e.username ? `(${e.username}) ` : ''}[${t(`association_types.${e.type}`)}]`;

      if (e.clientGroups.data.length) {
        label += ` - ${e.clientGroups.data.map((cg) => cg.shortName).join(', ')}`;
      }

      return {
        id: e._id,
        label:
          e.type !== CrmItemType.ClientGroup && e.clientGroups.data.length ? (
            <OptionWithClientGroupsPopover label={label} option={e} />
          ) : (
            label
          ),
        model: { itemId: e._id, itemType: e.type as CrmItemType, label },
      };
    });
  };

  return (
    <FormAutocomplete
      {...rest}
      value={formattedFieldValue}
      onChange={handleOnChange as any}
      fetchData={fetchOptions}
      initialValue={initialValue}
    />
  );
};

export default CrmItemAutocomplete;
