import React, { memo } from 'react';

// helpers
import { EntityDetailsFormValidationSchema } from 'validations/crm/entities';
import { FormValuesModel as NoteFormValuesModel } from '../NoteModalDialog';

// components
import AddEditNote from './AddEditNote';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import EntityDetailsForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/CRM/EntityDetailsForm';
import { Col, Row } from 'antd';

export interface EntityDetailsFormValues extends FormValuesModel {
  note?: NoteFormValuesModel;
}

interface IProps
  extends RequiredPropsForFormModel<FormValuesModel>,
    RequiredPropsForModalDialogModel {
  title: string;
}

const EntityDetailsModalDialog = memo(
  ({ isVisible, closeCallback, initialValues, onSubmit, title }: IProps) => {
    return (
      <Form
        enableReinitialize
        confirmExitWithoutSaving
        onSubmit={onSubmit}
        initialValues={isVisible ? initialValues : null}
        validationSchema={EntityDetailsFormValidationSchema}
        renderForm={
          <ModalDialog
            title={title}
            isVisible={isVisible}
            closeCallback={closeCallback}
          >
            <Row justify="end">
              <Col>
                <AddEditNote />
              </Col>
            </Row>
            <EntityDetailsForm />
          </ModalDialog>
        }
      />
    );
  },
);

export default EntityDetailsModalDialog;
