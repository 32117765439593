import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { enumToMap } from '@helpers/utils';
import { UserTypes } from 'enums/admin/orac';
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export type UserTypeFormSelectProps = SelectProps;

const UserTypeFormSelect = (props: UserTypeFormSelectProps) => {
  const { t } = useTranslation('orac');

  const options = useMemo<SelectOption[]>(() => {
    return Array.from(enumToMap(UserTypes)).map((el) => ({
      id: el[1],
      label: t(`users.types.${el[1]}`),
    }));
  }, [t]);

  return <FormSelect {...props} options={options} />;
};

export default UserTypeFormSelect;
