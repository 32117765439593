import { IApprovalMember } from 'typings/crm/onboarding';
import {
  ApprovalStatuses,
  ApprovalWorkflowStatuses,
} from 'enums/approvalManagement/workflow';

export const ApprovalHelpers = {
  userHasPendingApproval: (
    userId: string,
    approvalsArray: {
      groupNameTittle: string;
      members: IApprovalMember[];
    }[][],
    status: number,
  ) => {
    let result: IApprovalMember | null = null;

    for (const approvalItem of approvalsArray) {
      for (const approvalGroup of approvalItem) {
        const foundMember = approvalGroup.members.find(
          (member) => member.userId === userId,
        );

        if (foundMember) {
          result = foundMember;
          break;
        }
      }
      if (result) break;
    }

    return (
      result?.approvalAction === ApprovalStatuses.Pending &&
      status === ApprovalWorkflowStatuses.PendingApproval
    );
  },
};
