import React, { useMemo, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import clientLockoutsAPI from 'api/orac/clientLockoutsAPI';
import { message, Typography } from 'antd';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';

// components
import LockoutDetailsTable from 'components/Tables/TableTemplates/ORAC/LockoutDetailsTable';
import UnlockModalDialog, {
  FormValuesModel,
} from 'components/Modals/TemplateModalDialogs/ORAC/Users/Security/UnlockModalDialog';
import { CardWrapper } from 'layouts/PageLayout';

interface IProps {
  userId: string;
}

const LockoutDetails = ({ userId }: IProps) => {
  const { t } = useTranslation('orac');

  const [page, setPage] = useState(1);
  const [updateTableTrigger, updateTable] = useState({});

  const { response, loading } = useFetch(
    () =>
      clientLockoutsAPI.fetchClientLockoutsByUserId(userId, {
        page,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [page, userId, updateTableTrigger],
  );

  const [unlockId, setUnlockId] = useState<string | null>(null);

  const onActionsClick = (key: string, value: unknown) => {
    switch (key) {
      case 'unlock': {
        setUnlockId(value as string);
      }
    }
  };

  const initialValues = useMemo<FormValuesModel>(() => {
    return {
      reason: '',
    };
  }, []);

  const onSubmit = async (values: FormValuesModel) => {
    await clientLockoutsAPI.unlockClientLockout(values, unlockId as string);
    message.success(t('users.view.lockout_details_table.unlock_success'));
  };

  const handleCloseCallback = (wasSubmitted?: boolean) => {
    if (wasSubmitted) {
      updateTable({});
    }
    setUnlockId(null);
  };

  return (
    <CardWrapper>
      <Typography.Title level={4}>
        {t('users.view.lockout_details_table.title')}
      </Typography.Title>
      <LockoutDetailsTable
        total={response?.total || 0}
        current={page}
        loading={loading}
        data={response?.data || []}
        onPaginationChange={setPage}
        onActionsClick={onActionsClick}
      />
      <UnlockModalDialog
        initialValues={initialValues}
        onSubmit={onSubmit}
        isVisible={!!unlockId}
        closeCallback={handleCloseCallback}
      />
    </CardWrapper>
  );
};

export default LockoutDetails;
