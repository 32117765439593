import React from 'react';

// helpers
import { ContactHelpers } from '@helpers/crm/contact';
import { AUTOCOMPLETE_RESULT_LIMIT } from 'constants/global';
import {
  AutocompleteProps,
  AutocompleteOption,
} from '@core_components/Autocomplete';
import {
  contactsAPI,
  ContactDataAutocompleteFromQuery,
} from 'api/crm/contact/contactsAPI';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export interface IProps
  extends AutocompleteProps<ContactDataAutocompleteFromQuery> {
  isAssociated?: boolean;
  shouldDisableContactsWithoutEmailAndPhone?: boolean;
}

const ContactFormAutocomplete = ({
  isAssociated,
  shouldDisableContactsWithoutEmailAndPhone,
  ...rest
}: IProps) => {
  const fetchContacts = async (search: string) => {
    const { data } = await contactsAPI.fetchContactsAutocomplete({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      isAssociated,
      search,
    });

    return data.map<AutocompleteOption<ContactDataAutocompleteFromQuery>>(
      (contact: ContactDataAutocompleteFromQuery) => {
        const isDisabled =
          shouldDisableContactsWithoutEmailAndPhone &&
          (!contact.emails.length ||
            !contact.phoneNumbers.length ||
            (contact.emails.length &&
              !contact.emails.find((email) => email.address)) ||
            (contact.phoneNumbers.length &&
              !contact.phoneNumbers.find((phone) => phone.number)));

        return {
          id: contact._id,
          model: contact,
          disabled: !!isDisabled,
          label: ContactHelpers.getContactName({
            firstName: contact.firstName,
            lastName: contact.lastName,
          }),
        };
      },
    );
  };

  return <FormAutocomplete {...rest} fetchData={fetchContacts} />;
};

export default ContactFormAutocomplete;
