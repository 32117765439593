import { PermissionedAccessModel } from '.';

export type ORACPermissionKeys =
  | 'ORAC_View'
  | 'ORAC_Users_View'
  | 'ORAC_Users_Create'
  | 'ORAC_Users_Update'
  | 'ORAC_Users_Details_View'
  | 'ORAC_Users_Details_Groups_View'
  | 'ORAC_Users_Details_Groups_Update'
  | 'ORAC_Users_Details_Groups_Roles_View'
  | 'ORAC_Users_Details_Groups_Roles_Permissions_View'
  | 'ORAC_Users_Details_Roles_View'
  | 'ORAC_Users_Details_Permissions_View'
  | 'ORAC_Users_Details_Permissions_Update'
  | 'ORAC_Users_Details_ForbiddenPermissions_View'
  | 'ORAC_Users_Details_ForbiddenPermissions_Update'
  | 'ORAC_Roles_View'
  | 'ORAC_Roles_Create'
  | 'ORAC_Roles_Details_View'
  | 'ORAC_Roles_Details_Update'
  | 'ORAC_Roles_Details_Groups_View'
  | 'ORAC_Roles_Details_Groups_Update'
  | 'ORAC_Roles_Details_Users_View'
  | 'ORAC_Roles_Details_Permissions_View'
  | 'ORAC_Roles_Details_Permissions_Update'
  | 'ORAC_Groups_View'
  | 'ORAC_Groups_Create'
  | 'ORAC_Groups_Details_View'
  | 'ORAC_Groups_Details_Update'
  | 'ORAC_Groups_Details_Roles_View'
  | 'ORAC_Groups_Details_Roles_Update'
  | 'ORAC_Groups_Details_Roles_Permissions_View'
  | 'ORAC_Groups_Details_Users_View'
  | 'ORAC_Groups_Details_Users_Update'
  | 'ORAC_Groups_Details_Contexts_View'
  | 'ORAC_Groups_Details_Contexts_Update'
  | 'ORAC_Objects_View'
  | 'ORAC_Objects_Details_View'
  | 'ORAC_Objects_Details_Actions_View'
  | 'ORAC_ForgotUsernameRequests_View'
  | 'ORAC_ForgotUsernameRequestDetails_View'
  | 'ORAC_ForgotUsernameRequestDetails_UpdateStatus'
  | 'ORAC_ClientLockoutsDetails_View';

export const ORACPermissions: Record<
  ORACPermissionKeys,
  PermissionedAccessModel
> = {
  ORAC_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Authentication:User:Read' }],
  },

  // --- Users page ---
  ORAC_Users_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Authentication:User:Read' },
      { permissionKey: 'ORAC:User:Create' },
    ],
  },

  ORAC_Users_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Authentication:User:Create' }],
  },

  ORAC_Users_Update: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Authentication:User:Update' }],
  },

  ORAC_Users_Details_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Authentication:User:Read' },
      { permissionKey: 'ORAC:User:Read' },
    ],
  },

  // Groups tab
  ORAC_Users_Details_Groups_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Group:Read' }],
  },

  ORAC_Users_Details_Groups_Update: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Group:Update' }],
  },

  ORAC_Users_Details_Groups_Roles_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Role:Read' }],
  },

  ORAC_Users_Details_Groups_Roles_Permissions_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Permission:Read' }],
  },

  // Roles tab
  ORAC_Users_Details_Roles_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Role:Read' }],
  },

  // Permissions tab
  ORAC_Users_Details_Permissions_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Permission:Read' }],
  },

  ORAC_Users_Details_Permissions_Update: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:User:Update' }],
  },

  // Forbidden permissions tab
  ORAC_Users_Details_ForbiddenPermissions_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Permission:Read' }],
  },

  ORAC_Users_Details_ForbiddenPermissions_Update: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:User:Update' }],
  },
  // --- End Users page ---

  // --- Roles page ---
  ORAC_Roles_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'ORAC:Role:Read' },
      { permissionKey: 'ORAC:Role:Create' },
    ],
  },

  ORAC_Roles_Create: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Role:Create' }],
  },

  ORAC_Roles_Details_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Role:Read' }],
  },

  ORAC_Roles_Details_Update: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Role:Update' }],
  },

  // Groups tab
  ORAC_Roles_Details_Groups_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Group:Read' }],
  },

  ORAC_Roles_Details_Groups_Update: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Group:Update' }],
  },

  // Users tab
  ORAC_Roles_Details_Users_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:User:Read' }],
  },

  // Permissions
  ORAC_Roles_Details_Permissions_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Permission:Read' }],
  },

  ORAC_Roles_Details_Permissions_Update: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Role:Update' }],
  },
  // --- End Roles page ---

  // --- Groups page ---
  ORAC_Groups_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'ORAC:Group:Read' },
      { permissionKey: 'ORAC:Group:Create' },
    ],
  },

  ORAC_Groups_Create: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Group:Create' }],
  },

  ORAC_Groups_Details_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Group:Read' }],
  },

  ORAC_Groups_Details_Update: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Group:Update' }],
  },

  // Roles tab
  ORAC_Groups_Details_Roles_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Role:Read' }],
  },

  ORAC_Groups_Details_Roles_Update: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'ORAC:Group:Update' },
      { permissionKey: 'ORAC:Role:Read' },
    ],
  },

  ORAC_Groups_Details_Roles_Permissions_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Permission:Read' }],
  },

  // Users tab
  ORAC_Groups_Details_Users_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:User:Read' }],
  },

  ORAC_Groups_Details_Users_Update: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'ORAC:Group:Update' },
      { permissionKey: 'ORAC:User:Read' },
    ],
  },

  // Contexts tab
  ORAC_Groups_Details_Contexts_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'ORAC:Context:Read' }],
  },

  ORAC_Groups_Details_Contexts_Update: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'ORAC:Group:Update' },
      { permissionKey: 'ORAC:Context:Read' },
    ],
  },

  // --- End Groups Page ---

  // --- Objects Page ---
  ORAC_Objects_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'ORAC:Object:Read' }],
  },

  ORAC_Objects_Details_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'ORAC:Object:Read' }],
  },

  ORAC_Objects_Details_Actions_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'ORAC:Action:Read' },
      { permissionKey: 'ORAC:Object:Read' },
    ],
  },
  // --- End Objects Page ---

  // --- Forgot username requests pages ---
  ORAC_ForgotUsernameRequests_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Authentication:ForgotUsernameRequest:Read' },
    ],
  },

  ORAC_ForgotUsernameRequestDetails_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Authentication:ForgotUsernameRequest:Read' },
    ],
  },

  ORAC_ForgotUsernameRequestDetails_UpdateStatus: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Authentication:ForgotUsernameRequest:ChangeStatus' },
    ],
  },
  // --- End Forgot username requests ---

  // --- Client lockouts details pages ---
  ORAC_ClientLockoutsDetails_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Authentication:Lockout:Read' }],
  },
  // --- End Client lockouts details ---
};
