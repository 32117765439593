import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { IEntity } from '../../../../../typings/crm/entity';
import { enumToMap } from '@helpers/utils';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { DateHelpers } from '@helpers/date';
import { IClientGroup } from '../../../../../typings/crm/client-group';
import { EntityHelpers } from '@helpers/crm/entity';
import { IContactModel } from '../../../../../typings/crm/contact';
import { ContactHelpers } from '@helpers/crm/contact';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { DEFAULT_DATE_TIME_FORMAT } from '../../../../../constants/global';
import { ClientReviewStatus, ClientStatus } from 'enums/crm/crm';

// components
import Tag from '@core_components/Tag';
import Text from '@core_components/Text';
import TagsAutocomplete from 'components/Forms/FormComponents/Autocompletes/CRM/TagsAutocomplete';
import ClientGroupStatus from 'components/Additional/Statuses/ClientGroupStatus';
import FilterDropdownOverlay from 'components/Tables/FilterDropdownOverlay';
import ClientGroupReviewStatus from 'components/Additional/Statuses/ClientGroupReviewStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Col, Divider, Row, Switch, Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<IClientGroup[]> {
  filters: Record<string, any>;
  onFilterChange?: (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => void;
}

const ClientGroupsTable = memo(
  ({ filters, onFilterChange, ...rest }: IProps) => {
    const { t } = useTranslation('crm');

    const history = useHistory();

    const renderDirectClientName = (value: IClientGroup) => {
      if (value.directClientType === 'contact') {
        const directClient = value.directClient as IContactModel;
        return ContactHelpers.getContactName(directClient);
      } else {
        const directClient = value.directClient as IEntity;
        return (
          EntityHelpers.getEntityName(directClient) || (
            <NoNameText>{t('client_groups.no_direct_name')}</NoNameText>
          )
        );
      }
    };

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          title: t('client_groups.name'),
          key: 'clientGroupName',
          render: (record: IClientGroup) => (
            <Typography.Link
              onClick={() =>
                history.push({
                  pathname: RoutePaths.CRM_Client_Group_Details,
                  search: `?id=${record._id}`,
                })
              }
            >
              {record.clientGroupName || t('client_groups.no_group_name')}
            </Typography.Link>
          ),
        },
        {
          title: t('client_groups.direct_client_name'),
          key: 'directClientName',
          render: renderDirectClientName,
        },
        {
          title: t('client_groups.direct_client_type'),
          key: 'categoryType',
          render: (record: IClientGroup) =>
            t(`application.type.${record.directClientType}`),
        },
        {
          title: t('client_groups.review_status'),
          key: 'filter_review_status',
          render: (record: IClientGroup) => (
            <ClientGroupReviewStatus status={record.reviewStatus} />
          ),
          filteredValue: filters.filter_review_status,
          filterSearch: true,
          filters: Array.from(enumToMap(ClientReviewStatus)).map((e) => ({
            text: t(`client_groups.business_review_status.${e[1]}`),
            value: e[1],
          })),
        },
        {
          key: 'filter_status',
          title: t('client_groups.status'),
          render: (record: IClientGroup) => (
            <ClientGroupStatus status={record.status} />
          ),
          filteredValue: filters.filter_status,
          filterSearch: true,
          filters: Array.from(enumToMap(ClientStatus)).map((e) => ({
            text: t(`client_groups.statuses.${e[1]}`),
            value: e[1],
          })),
        },
        {
          title: t('client_groups.tags'),
          key: 'tags',
          width: 210,
          render: (record: IClientGroup) =>
            record.tags.map((tag) => <Tag key={tag.id} tag={tag.name} />),
          filteredValue: filters.tags,
          filterDropdown: (filterProps: FilterDropdownProps) => (
            <StyledFilterDropdownOverlay filterProps={filterProps}>
              <StyledRow>
                <StyledCol>
                  <StyledText>{t('client_groups.no_tags_filter')}</StyledText>
                </StyledCol>
                <Col>
                  <Switch
                    checked={(filterProps.selectedKeys as any) === 'no-tags'}
                    onChange={(checked) => {
                      filterProps.setSelectedKeys(
                        checked ? 'no-tags' : ([] as any),
                      );
                    }}
                  />
                </Col>
              </StyledRow>
              {(filterProps.selectedKeys as any) !== 'no-tags' && (
                <Row>
                  <StyledDivider />
                  <StyledCol>
                    <StyledText gutterBottom>
                      {t('client_groups.filter_by_tags')}
                    </StyledText>
                  </StyledCol>
                  <Col span={24}>
                    <StyledClientGroupTagsSelect
                      value={filterProps.selectedKeys as any}
                      mode="multiple"
                      onChange={(value) =>
                        filterProps.setSelectedKeys(value as any)
                      }
                    />
                  </Col>
                </Row>
              )}
            </StyledFilterDropdownOverlay>
          ),
        },
        {
          title: t('client_groups.creation_date'),
          key: 'creationDate',
          render: (record: IClientGroup) => (
            <Typography.Text>
              {DateHelpers.formatDateToString(
                record._audit.createdAt,
                DEFAULT_DATE_TIME_FORMAT,
              )}
            </Typography.Text>
          ),
        },
      ];

      return result;
    }, [filters, onFilterChange]);

    return (
      <Table {...rest} onFilterChange={onFilterChange} columns={columns} />
    );
  },
);

const NoNameText = styled(Typography.Text)`
  color: ${({ theme }) => theme.textLightColor4} !important;
`;

const StyledClientGroupTagsSelect = styled(TagsAutocomplete)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginXXs};
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-right: ${({ theme }) => theme.marginXXs};
`;

const StyledFilterDropdownOverlay = styled(FilterDropdownOverlay)`
  width: 350px;
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.marginXXs} 0;
`;

export default ClientGroupsTable;
