import React, { useMemo, useState } from 'react';

// helpers
import moment from 'moment';
import useFetch from '@hooks/useFetch';
import useUserAccess from '../../../../../../../hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';
import { noteAPI } from 'api/note/noteAPI';
import { INoteModel } from 'typings/note/note';
import { IContactModel } from 'typings/crm/contact';
import { CrmItemType, NoteType } from '../../../../../../../enums/crm/crm';

// components
import AddNote from './AddNote';
import NotesTable from '../../../../../Notes/NotesTable';
import NoteModalDialog, {
  FormValuesModel,
} from 'components/Modals/TemplateModalDialogs/CRM/NoteModalDialog';
import { Row, Col } from 'antd';

interface IProps {
  contact: IContactModel;
}

const Notes = ({ contact }: IProps) => {
  const [hasCreateAccess] = useUserAccess([
    'CRM_Contacts_Details_NotesTab_Create',
  ]);
  const { t } = useTranslation('crm');
  const [noteId, setNoteId] = useState<string | null>(null);
  const [updateTrigger, updateTable] = useState({});

  const { response: noteResponse, loading: noteLoading } = useFetch(
    () => (noteId ? noteAPI.fetchNoteById(noteId) : null),
    [noteId],
  );

  const onActionsClick = (key: string, value: INoteModel) => {
    switch (key) {
      case 'view': {
        setNoteId(value._id);
        break;
      }
    }
  };

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!noteId || !noteResponse) {
      return null;
    }

    return {
      text: noteResponse.text,
      type: noteResponse.type,
      eventDate: moment(noteResponse.createdAt),
      crmItems: noteResponse.sharedWith.map((shared) => ({
        itemId: shared.itemId,
        itemType: shared.itemType as unknown as CrmItemType,
      })),
      documents: noteResponse.documents.map((doc) => ({
        id: doc._id,
        name: doc.name,
        fileId: doc.fileId,
        file: null,
      })),
      crmItemsInitialValue: noteResponse.sharedWith.map((shared) => ({
        id: shared.itemId,
        label: shared.name,
      })),
    };
  }, [noteId, noteResponse]);

  return (
    <>
      <Row gutter={[36, 16]}>
        {hasCreateAccess && (
          <Col span={24}>
            <AddNote
              association={{ id: contact._id, type: NoteType.Contact }}
              onAdd={() => updateTable({})}
            />
          </Col>
        )}

        <Col span={24}>
          <NotesTable
            onActionsClick={onActionsClick}
            associationId={contact._id}
            updateTrigger={updateTrigger}
          />
        </Col>
        <NoteModalDialog
          title={t('notes.view_modal.title')}
          initialValues={initialFormValues}
          isVisible={!!noteId}
          disabled
          loading={noteLoading}
          closeCallback={() => setNoteId(null)}
          onSubmit={console.log}
        />
      </Row>
    </>
  );
};

export default Notes;
