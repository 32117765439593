import React, { memo, useMemo } from 'react';

//  helpers
import useTranslation from '@hooks/useTranslation';
import { styled } from 'styled-components';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { DateHelpers } from '@helpers/date';
import { OngoingReviewModel } from 'typings/compliance/ongoingReviews';
import { DEFAULT_FULL_DATE_FORMAT } from 'constants/global';
import { OngoingReviewTriggerTypes } from 'enums/compliance/reviewConfiguration';

// components
import Link from '@common_components/Texts/Link';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import NewMessageMarker from 'components/Additional/Messaging/NewMessageMarker';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

export type ActionKeys = 'crm_contact_details_view';

interface IProps extends RequiredPropsForTableModel<OngoingReviewModel> {
  size?: SizeType;
  onActionsClick?: (key: ActionKeys, record: OngoingReviewModel) => void;
}

const UpdatePersonalDetailsTable = memo(
  ({ size, onActionsClick, ...rest }: IProps) => {
    const { t } = useTranslation('crm');

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          width: 210,
          key: 'name',
          title: t('update_personal_details_table.table.name'),
          render: (record: OngoingReviewModel) => {
            const name = (
              <EllipsisTooltip
                title={record.entry.name}
                maxTextContainerWidth="210px"
              >
                {record.entry.name}
              </EllipsisTooltip>
            );

            return (
              <DivAlignCenter>
                <StyledNewMessageMarker />
                {!onActionsClick ? (
                  name
                ) : (
                  <Link
                    onClick={() =>
                      onActionsClick('crm_contact_details_view', record)
                    }
                  >
                    {name}
                  </Link>
                )}
              </DivAlignCenter>
            );
          },
        },

        {
          width: 200,
          key: 'startReviewDate',
          title: t('update_personal_details_table.table.start_review_date'),
          render: (record: OngoingReviewModel) =>
            DateHelpers.formatDateToString(
              record.startReviewDate,
              DEFAULT_FULL_DATE_FORMAT,
            ),
        },

        {
          width: 200,
          key: 'triggerType',
          title: t('update_personal_details_table.table.trigger_type'),
          render: (record: OngoingReviewModel) =>
            t(
              `update_personal_details_table.ongoing_review_types.${OngoingReviewTriggerTypes[record.triggerType]}`,
            ),
        },
      ];

      return result;
    }, [onActionsClick]);

    return <Table {...rest} size={size} columns={columns} />;
  },
);

const StyledNewMessageMarker = styled(NewMessageMarker)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default UpdatePersonalDetailsTable;
