import APIConfig from '../../../config/api';
import { APIService } from 'api/axiosInstance';
import { ContactAddressType, OnboardType } from 'enums/crm/crm';
import { FetchParamsModel, FetchResponseModel } from 'typings/common';

interface FetchExpiringDocumentParamsModel extends FetchParamsModel {
  entityId?: string;
  sortBy?: 'expirationDate';
  sortDirection?: boolean;
  status?: string;
  type?: string;
  search?: string;
  entityIds?: string;
  toExpirationDate?: string;
  fromExpirationDate?: string;
}

export type ExpiringDocument = {
  _id: string;
  type: 'passport';
  expirationDate: string;

  status:
    | 'pending'
    | 'submitted'
    | 'in-review'
    | 'approved'
    | 'rejected'
    | 'finalized';

  entity: {
    id: string;
    type: OnboardType;
    name: string;
  };
  details: {
    passportDetails: {
      number: string;
      issuingCountry: string;
      issuedAt: string;
      documents: {
        id: string;
        createdAt: string;
        creatorId: string;
        creatorName: string;
        files: {
          id: string;
          name: string;
          size: number;
        }[];
      }[];
    };
  };

  notifications: {
    type: 'sms' | 'email';
    triggeredAt: string;
    isSent: boolean;
    reason: string;
  }[];

  review: {
    isValid: boolean;
    comment: string;
  } | null;
};

export type AddressModel = {
  _id: string;
  isPrimary: boolean;
  type: ContactAddressType[];
  country: string;
  city: string;
  street: string;
  state: string;
  postalCode: string;
  documents?: {
    id: string;
    createdAt: string;
    creatorId: string;
    creatorName: string;
    files: {
      id: string;
      name: string;
      size: number;
    }[];
  }[];

  status:
    | 'pending'
    | 'submitted'
    | 'in-review'
    | 'approved'
    | 'rejected'
    | 'finalized';

  review: {
    isValid: boolean;
    comment: string;
  } | null;
};

export interface IdentificationModel {
  passportExpiringDocuments: FetchResponseModel<ExpiringDocument>;
  addresses: FetchResponseModel<AddressModel>;
}

export type ReviewOfExpiringDocument = {
  expiringDocumentId: string;
  review: {
    isValid: boolean;
    comment?: string;
  } | null;
};

export type ReviewOfAddressDocument = {
  documentId: string;
  review: {
    isValid: boolean;
    comment?: string;
  } | null;
};

export type ReviewExpiringDocumentsReqBody = {
  entityId: string;
  reviews: ReviewOfExpiringDocument[];
};

export type ReviewIdentificationDocumentsReqBody = {
  entityId: string;
  addresses: ReviewOfAddressDocument[];
  expiringDocuments: ReviewOfExpiringDocument[];
};

export interface FetchContactParams extends FetchParamsModel {
  search: string;
}

const expiringDocumentsAPI = {
  fetchExpiringDocuments: (params: FetchExpiringDocumentParamsModel) => {
    return APIService.get<FetchResponseModel<ExpiringDocument>>(
      `${APIConfig.crmApi}/expiring-document`,
      { params },
    ).then((response) => response?.data);
  },
  reviewIdentificationDocuments: (
    body: ReviewIdentificationDocumentsReqBody,
  ) => {
    return APIService.post(
      `${APIConfig.crmApi}/reviewal/review-identification`,
      body,
    ).then((response) => response?.data);
  },

  fetchIdentificationDocuments: (crmItemId: string) => {
    return APIService.get<IdentificationModel>(
      `${APIConfig.crmApi}/crm-item/${crmItemId}/identification`,
    ).then((response) => response?.data);
  },
};

export { expiringDocumentsAPI };
