import React, { useEffect, useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { StateModel } from '../../../../redux/reducers';
import { approvalAPI } from '../../../../api/crm/onboarding/approvalAPI';
import { useSelector } from 'react-redux';
import { ApprovalHelpers } from '@helpers/approval/approval';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ApprovalsMatrix from '../ApprovalsMatrix';
import WorkflowActions from '../WorkflowActions';

interface INewBusinessWorkflowStatusProps {
  workflowId: string;
  onUpdate: () => void;
}

const NewBusinessWorkflowStatus = ({
  workflowId,
  onUpdate,
}: INewBusinessWorkflowStatusProps) => {
  const currentUserId = useSelector<StateModel, string>(
    (state) => state.auth.profileData?._id || '',
  );

  const [updateTrigger, setUpdateTrigger] = useState({});
  const [shouldApproveOrReject, setShouldApproveOrReject] =
    useState<boolean>(false);

  const { response, loading } = useFetch(
    () =>
      workflowId ? approvalAPI.fetchNewBusinessStatuses(workflowId) : null,
    [updateTrigger, workflowId],
  );

  useEffect(() => {
    if (response) {
      const approvalsArray = response?.approvalProgressStateSets
        ? response.approvalProgressStateSets.map((e) => e.progressStateItems)
        : [];

      const result = ApprovalHelpers.userHasPendingApproval(
        currentUserId,
        approvalsArray,
        response?.status,
      );

      setShouldApproveOrReject(result);
    }
  }, [response]);

  const handleApprove = async (reason: string) => {
    await approvalAPI.approveNewBusiness(workflowId, reason);
    setUpdateTrigger({});
    onUpdate();
  };

  const handleReject = async (reason: string) => {
    await approvalAPI.rejectNewBusiness(workflowId, reason);
    setUpdateTrigger({});
    onUpdate();
  };

  return (
    <LoadingWrapper loading={loading}>
      {!!response && (
        <ApprovalsMatrix
          approvalProgressStateSets={response.approvalProgressStateSets}
        />
      )}
      <WorkflowActions
        canReject={shouldApproveOrReject}
        canApprove={shouldApproveOrReject}
        onApprove={handleApprove}
        onReject={handleReject}
      />
    </LoadingWrapper>
  );
};

export default NewBusinessWorkflowStatus;
