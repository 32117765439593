import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { ClientTransferApprovalStatuses } from '../../../../enums/transfers/transfers';

// components
import StatusLabel from '../../../Typography/StatusLabel';

const STATUS_MAP = {
  warning: [
    ClientTransferApprovalStatuses.PendingApprovals,
    ClientTransferApprovalStatuses.Processing,
    ClientTransferApprovalStatuses.InReview,
    ClientTransferApprovalStatuses.AMLReview,
    ClientTransferApprovalStatuses.PendingTransmit,
    ClientTransferApprovalStatuses.Scheduled,
  ],
  success: [ClientTransferApprovalStatuses.Completed],
  error: [
    ClientTransferApprovalStatuses.Rejected,
    ClientTransferApprovalStatuses.Failed,
    ClientTransferApprovalStatuses.BankRejected,
  ],
  info: [
    ClientTransferApprovalStatuses.Expired,
    ClientTransferApprovalStatuses.Cancelled,
  ],
};

interface IProps {
  status: ClientTransferApprovalStatuses;
}

const TransactionStatus = memo(({ status }: IProps) => {
  const { t } = useTranslation('banking');

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      text={t(
        `transactions.transaction_statuses.${ClientTransferApprovalStatuses[status]}`,
      )}
    />
  );
});

export default TransactionStatus;
