import React, { useEffect, useMemo, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { styled } from 'styled-components';
import { message } from 'antd';
import { darkTheme } from '@resources/theme/styled';
import { IClientGroup } from 'typings/crm/client-group';
import { clientGroupsAPI, TagModel } from 'api/crm/clientGroup/clientGroupsAPI';

// components
import Tag from '@core_components/Tag';
import Text from '@core_components/Text';
import Spinner from '@core_components/Spinner';
import IconSVG from '@core_components/IconSVG';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import Select, { SelectOption } from '@core_components/Select';
import { ReactComponent as PlusIcon } from 'resources/icons/remix-icons/add-line.svg';

interface IProps {
  clientGroupData: IClientGroup;
}

const TagsContainer = ({ clientGroupData }: IProps) => {
  const { t } = useTranslation('crm');

  const [isDropdownOpen, setOpen] = useState(false);
  const [activeTags, setActiveTags] = useState<{ id: string; label: string }[]>(
    [],
  );

  const { response: tagsResponse, loading: tagsLoading } = useFetch(
    () => clientGroupsAPI.fetchTags(),
    [],
  );

  const handleDropdownVisibleChange = (visible: boolean) => {
    setOpen(visible);
  };

  const updateTags = async (tags: { id: string; label: string }[]) => {
    await clientGroupsAPI.setTags({
      entityId: clientGroupData._id,
      entityType: 'client-group',
      slugs: tags.map((tag) => tag.id),
    });
  };

  const onRemove = async (tag: string) => {
    const filteredTags = activeTags.filter((t) => t.label !== tag);
    setActiveTags(filteredTags);
    await updateTags(filteredTags);
    message.success(t('client_group.info.tags_update_message'));
  };

  const handleAddTag = async (_: string, option?: SelectOption<TagModel>) => {
    if (option && option.model) {
      const modifiedTags = [
        ...activeTags,
        { id: option.model.slug, label: option.model.name },
      ];
      setActiveTags(modifiedTags);
      setOpen(false);
      await updateTags(modifiedTags);
      message.success(t('client_group.info.tags_update_message'));
    }
  };

  const options = useMemo<SelectOption<TagModel>[] | undefined>(() => {
    let result: SelectOption<TagModel>[] | undefined = tagsResponse
      ? tagsResponse.data.map((tag) => ({
          id: tag.slug,
          model: tag,
          label: tag.name,
        }))
      : undefined;

    if (activeTags.length && tagsResponse) {
      result = tagsResponse.data.reduce<SelectOption<TagModel>[]>(
        (acc, tag) => {
          if (!activeTags.find((aTag) => aTag.label === tag.name)) {
            acc.push({
              id: tag.slug,
              model: tag,
              label: tag.name,
            });
          }
          return acc;
        },
        [],
      );
    }

    return result;
  }, [tagsResponse, activeTags]);

  useEffect(() => {
    if (clientGroupData) {
      setActiveTags(
        clientGroupData.tags.map((tag) => ({ id: tag.slug, label: tag.name })),
      );
    }
  }, [clientGroupData]);

  return tagsLoading ? (
    <Spinner />
  ) : (
    <TagsWrapper>
      {activeTags.map((x) => (
        <Tag key={x.id} closable tag={x.label} onRemove={onRemove} />
      ))}

      {!isDropdownOpen && activeTags.length !== tagsResponse?.data.length && (
        <Container onClick={() => setOpen(true)}>
          {activeTags.length > 0 ? (
            <StyledIconSVG component={PlusIcon} color={darkTheme.colorLight} />
          ) : (
            <StyledDivAlignCenter>
              <StyledIconSVG
                component={PlusIcon}
                color={darkTheme.colorLight}
              />
              <Text>{t('client_group.info.add_tag_button')}</Text>
            </StyledDivAlignCenter>
          )}
        </Container>
      )}
      {isDropdownOpen && (
        <StyledSelect
          size="small"
          options={options}
          loading={tagsLoading}
          isDropdownOpen={isDropdownOpen}
          onChange={handleAddTag}
          onDropdownVisibleChange={handleDropdownVisibleChange}
        />
      )}
    </TagsWrapper>
  );
};

const Container = styled.div`
  cursor: pointer;
`;

const StyledIconSVG = styled(IconSVG)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDivAlignCenter = styled(DivAlignCenter)`
  &:hover {
    background: ${({ theme }) => theme.backgroundColor3};
  }
`;

const StyledSelect = styled(Select<TagModel>)`
  width: 130px;
  height: 22px;
  &.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 22px;
  }
`;

export default TagsContainer;
