import React, { useEffect } from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import usersAPI from '../../../api/orac/usersAPI';
import useQueryParam from '../../../hooks/useQueryParam';
import useTranslation from '../../../hooks/useTranslation';
import { RoutePaths } from '../../../routes/routes';

// components
import LoadingWrapper from '../../../components/WrapperComponents/LoadingWrapper';
import { default as UserDetailsContent } from '../../../modules/ORAC/Users/UserDetails';

interface IProps {
  setHeaderOptions: (options: any) => void;
}

const UserDetails = ({ setHeaderOptions }: IProps) => {
  const { t } = useTranslation('page_titles');
  const userId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.ORAC_Users,
  });
  const { response, loading } = useFetch(
    () => (userId ? usersAPI.getUserById(userId) : null),
    [userId],
  );

  useEffect(() => {
    setHeaderOptions({
      title: `${t('User')}${response ? ': ' + response.name : ''}`,
    });
  }, [response]);

  return (
    <LoadingWrapper loading={loading}>
      {!!response && <UserDetailsContent user={response} />}
    </LoadingWrapper>
  );
};

export default UserDetails;
