import { PermissionedAccessModel } from '.';

// Permission keys
export type CRMPermissionKeys =
  | 'CRM_View'
  | 'CRM_Dashboard_MessagesWidget_View'
  // ------ Entities ------
  | 'CRM_Entities_View'
  | 'CRM_Entities_Create'
  | 'CRM_Entities_Details_View'
  | 'CRM_Entities_Details_DetailsTab_View'
  | 'CRM_Entities_Details_DetailsTab_Update'
  | 'CRM_Entities_Details_DetailsTab_Delete'
  | 'CRM_Entities_Details_RelationshipsTab_View'
  | 'CRM_Entities_Details_RelationshipsTab_CreateUpdate'
  | 'CRM_Entities_Details_DocumentsTab_View'
  | 'CRM_Entities_Details_DocumentsTab_UploadDocuments'
  | 'CRM_Entities_Details_NotesTab_View'
  | 'CRM_Entities_Details_NotesTab_Create'
  | 'CRM_Entities_Details_RDCTab_View'
  // ------ Contacts ------
  | 'CRM_Contacts_View'
  | 'CRM_Contacts_Create'
  | 'CRM_Contacts_Details_View'
  | 'CRM_Contacts_Details_DetailsTab_View'
  | 'CRM_Contacts_Details_DetailsTab_Update'
  | 'CRM_Contacts_Details_DetailsTab_Delete'
  | 'CRM_Contacts_IdentificationTab_View'
  | 'CRM_Contacts_IdentificationTab_Update'
  | 'CRM_Contacts_Details_RelationshipsTab_View'
  | 'CRM_Contacts_Details_RelationshipsTab_CreateUpdate'
  | 'CRM_Contacts_Details_DocumentsTab_View'
  | 'CRM_Contacts_Details_DocumentsTab_UploadDocuments'
  | 'CRM_Contacts_Details_NotesTab_View'
  | 'CRM_Contacts_Details_NotesTab_Create'
  | 'CRM_Contacts_Details_RDCTab_View'
  // ------ Invitations ------
  | 'CRM_Invitations_View'

  // ------ Client groups ------
  | 'CRM_ClientGroups_View'
  | 'CRM_ClientGroup_Details_View'
  | 'CRM_ClientGroup_Structure_View'
  | 'CRM_ClientGroup_Account_View'
  | 'CRM_ClientGroup_Approval_View'
  | 'CRM_ClientGroup_Users_View'
  | 'CRM_ClientGroup_Details_FeeScheduleTab_View'
  | 'CRM_ClientGroup_Details_FeeScheduleTab_FeeSchedule_View'
  | 'CRM_ClientGroup_Details_FeeScheduleTab_FeeSchedule_Edit'
  | 'CRM_ClientGroup_Details_FeeScheduleTab_SpreadMatrix_View'
  | 'CRM_ClientGroup_Details_FeeScheduleTab_SpreadMatrix_Edit'
  | 'CRM_ClientGroup_Details_TransferTemplatesTab_View'
  | 'CRM_ClientGroup_Details_Compliance_Notes_Create'
  | 'CRM_ClientGroup_Details_Compliance_Notes_View'
  | 'CRM_ClientGroup_Details_Messages_View'
  | 'CRM_ClientGroup_Details_TransactionRules_View'
  | 'CRM_ClientGroup_Details_TransactionRules_ApprovalGroups_View'
  | 'CRM_ClientGroup_Details_TransactionRules_ApprovalGroups_Create'
  | 'CRM_ClientGroup_Details_TransactionRules_ApprovalGroups_Edit'
  | 'CRM_ClientGroup_Details_TransactionRules_ApprovalRules_View'
  | 'CRM_ClientGroup_Details_TransactionRules_ApprovalRules_Create'
  | 'CRM_ClientGroup_Details_TransactionRules_ApprovalRules_Edit'
  | 'CRM_ClientGroup_Details_AdministrationRules_View'
  | 'CRM_ClientGroup_Details_AdministrationRules_ApprovalGroups_View'
  | 'CRM_ClientGroup_Details_AdministrationRules_ApprovalGroups_Create'
  | 'CRM_ClientGroup_Details_AdministrationRules_ApprovalGroups_Edit'
  | 'CRM_ClientGroup_Details_AdministrationRules_ApprovalRules_View'
  | 'CRM_ClientGroup_Details_AdministrationRules_ApprovalRules_Edit'

  // ------ Notes dashboard ------
  | 'CRM_Notes_View'

  // ------ Other ------
  | 'CRM_Relationships_View'
  | 'CRM_Relationships_Create'
  | 'CRM_Relationships_Edit'
  | 'CRM_Relationships_Delete'
  | 'CRM_Configuration_View'
  | 'CRM_Configuration_RelationshipTemplates_View'
  | 'CRM_Configuration_RelationshipTemplates_Create'
  | 'CRM_Configuration_RelationshipTemplates_Delete'
  | 'CRM_Configuration_EntityTypeCategories_View'
  | 'CRM_Configuration_EntityTypeCategories_Create'
  | 'CRM_EntityTypeCategory_Delete'
  | 'CRM_Transaction_Approval_Matrix_View';

// Permission config
export const CRMPermissions: Record<
  CRMPermissionKeys,
  PermissionedAccessModel
> = {
  CRM_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'CRM:Organization:Read' },
      { permissionKey: 'CRM:Organization:Create' },
      { permissionKey: 'CRM:Contact:Read' },
      { permissionKey: 'CRM:Contact:Create' },
      { permissionKey: 'CRM:ClientGroup:Read' },
      { permissionKey: 'CRM:RelationshipTemplate:Read' },
      { permissionKey: 'CRM:RelationshipTemplate:Create' },
      { permissionKey: 'CRM:KeyRelationshipTemplate:Read' },
      { permissionKey: 'CRM:KeyRelationshipTemplate:Create' },
    ],
  },

  CRM_Dashboard_MessagesWidget_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Messaging:Message:Read' }],
  },

  // ------ Entities ------
  CRM_Entities_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:Organization:Read' }],
  },

  CRM_Entities_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:Organization:Create' }],
  },

  CRM_Entities_Details_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:Organization:Read' }],
  },

  CRM_Entities_Details_DetailsTab_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:Organization:Read' }],
  },

  CRM_Entities_Details_DetailsTab_Update: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:Organization:Update' }],
  },

  CRM_Entities_Details_DetailsTab_Delete: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:Organization:Delete' }],
  },

  CRM_Entities_Details_RelationshipsTab_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:Relationship:Read' }],
  },

  CRM_Entities_Details_RelationshipsTab_CreateUpdate: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'CRM:Relationship:Create' },
      { permissionKey: 'CRM:Relationship:Update' },
      { permissionKey: 'CRM:Relationship:Delete' },
    ],
  },

  CRM_Entities_Details_DocumentsTab_View: {
    requiredAllPermissions: true,
    permissions: [
      {
        permissionKey: 'Documents:Document:Read',
      },
    ],
  },

  CRM_Entities_Details_DocumentsTab_UploadDocuments: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Documents:Document:Create' }],
  },

  CRM_Entities_Details_NotesTab_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Notes:Note:Read' }],
  },

  CRM_Entities_Details_NotesTab_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Notes:Note:Create' }],
  },

  CRM_Entities_Details_RDCTab_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'AML:RDCAssociation:Read' }],
  },

  // ------ Contacts ------
  CRM_Contacts_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:Contact:Read' }],
  },

  CRM_Contacts_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:Contact:Create' }],
  },

  CRM_Contacts_Details_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:Contact:Read' }],
  },

  CRM_Contacts_Details_DetailsTab_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:Contact:Read' }],
  },

  CRM_Contacts_Details_DetailsTab_Update: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:Contact:Update' }],
  },

  CRM_Contacts_Details_DetailsTab_Delete: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:Contact:Delete' }],
  },

  CRM_Contacts_IdentificationTab_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:Identification:Read' }],
  },

  CRM_Contacts_IdentificationTab_Update: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'CRM:Identification:Read' },
      { permissionKey: 'CRM:Contact:Update' },
    ],
  },

  CRM_Contacts_Details_RelationshipsTab_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:Relationship:Read' }],
  },

  CRM_Contacts_Details_RelationshipsTab_CreateUpdate: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'CRM:Relationship:Create' },
      { permissionKey: 'CRM:Relationship:Update' },
      { permissionKey: 'CRM:Relationship:Delete' },
    ],
  },

  CRM_Contacts_Details_DocumentsTab_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Documents:Document:Read' }],
  },

  CRM_Contacts_Details_DocumentsTab_UploadDocuments: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Documents:Document:Create' }],
  },

  CRM_Contacts_Details_NotesTab_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Notes:Note:Read' }],
  },

  CRM_Contacts_Details_NotesTab_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Notes:Note:Create' }],
  },

  CRM_Contacts_Details_RDCTab_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'AML:RDCAssociation:Read' }],
  },

  // ------ Invitations ------
  CRM_Invitations_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:Invitation:Read' }],
  },

  // ------ Other ------
  CRM_ClientGroups_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'CRM:ClientGroup:Read' }],
  },

  CRM_Configuration_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'CRM:RelationshipTemplate:Read' },
      { permissionKey: 'CRM:RelationshipTemplate:Create' },
      { permissionKey: 'CRM:KeyRelationshipTemplate:Read' },
      { permissionKey: 'CRM:KeyRelationshipTemplate:Create' },
    ],
  },

  CRM_Configuration_RelationshipTemplates_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'CRM:RelationshipTemplate:Read' },
      { permissionKey: 'CRM:RelationshipTemplate:Create' },
    ],
  },

  CRM_Configuration_RelationshipTemplates_Create: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'CRM:RelationshipTemplate:Create' }],
  },

  CRM_Configuration_RelationshipTemplates_Delete: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'CRM:RelationshipTemplate:Delete' }],
  },

  CRM_Relationships_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'CRM:Relationship:Read' }],
  },

  CRM_Relationships_Create: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'CRM:Relationship:Create' }],
  },

  CRM_Relationships_Edit: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'CRM:Relationship:Update' }],
  },

  CRM_Relationships_Delete: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'CRM:Relationship:Delete' }],
  },

  CRM_Configuration_EntityTypeCategories_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'CRM:KeyRelationshipTemplate:Read' },
      { permissionKey: 'CRM:KeyRelationshipTemplate:Create' },
    ],
  },

  CRM_Configuration_EntityTypeCategories_Create: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'CRM:KeyRelationshipTemplate:Create' }],
  },

  CRM_ClientGroup_Details_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:ClientGroup:Read' }],
  },

  CRM_ClientGroup_Structure_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'CRM:Organization:Read' },
      { permissionKey: 'CRM:Contact:Read' },
    ],
  },

  CRM_ClientGroup_Account_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'CRM:CRMAccount:Read' }],
  },

  CRM_ClientGroup_Approval_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'CRM:OnboardingProcess:Read' },
      { permissionKey: 'CRM:ExpiringDocument:Read' },
    ],
  },

  CRM_ClientGroup_Users_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'CRM:OnboardingProcess:Read' }],
  },

  CRM_ClientGroup_Details_FeeScheduleTab_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Accounting:FeeSchedule:Read' },
      { permissionKey: 'Accounting:SpreadMatrix:Read' },
    ],
  },

  CRM_ClientGroup_Details_TransferTemplatesTab_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Accounting:InternalTransferTemplate:Read' },
      { permissionKey: 'Accounting:InternationalTransferTemplate:Read' },
      { permissionKey: 'Accounting:BlockchainTransferTemplate:Read' },
    ],
  },

  CRM_ClientGroup_Details_FeeScheduleTab_FeeSchedule_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:FeeSchedule:Read' }],
  },

  CRM_ClientGroup_Details_FeeScheduleTab_FeeSchedule_Edit: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:FeeSchedule:Update' }],
  },

  CRM_ClientGroup_Details_FeeScheduleTab_SpreadMatrix_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:SpreadMatrix:Read' }],
  },

  CRM_ClientGroup_Details_FeeScheduleTab_SpreadMatrix_Edit: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:SpreadMatrix:Update' }],
  },

  CRM_ClientGroup_Details_Compliance_Notes_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Notes:Note:Read' }],
  },

  CRM_ClientGroup_Details_Compliance_Notes_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Notes:Note:Create' }],
  },

  CRM_ClientGroup_Details_Messages_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Messaging:Message:Read' }],
  },

  CRM_EntityTypeCategory_Delete: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'CRM:KeyRelationshipTemplate:Delete' }],
  },

  CRM_Notes_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Notes:Note:Read' }],
  },

  CRM_ClientGroup_Details_TransactionRules_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:TransactionGroup:Read' },
      { permissionKey: 'Approval:TransactionTemplate:Read' },
    ],
  },

  CRM_ClientGroup_Details_TransactionRules_ApprovalGroups_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:TransactionGroup:Read' }],
  },

  CRM_ClientGroup_Details_TransactionRules_ApprovalGroups_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:TransactionGroup:Create' }],
  },

  CRM_ClientGroup_Details_TransactionRules_ApprovalGroups_Edit: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:TransactionGroup:Update' }],
  },

  CRM_ClientGroup_Details_TransactionRules_ApprovalRules_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:TransactionTemplate:Read' }],
  },

  CRM_ClientGroup_Details_TransactionRules_ApprovalRules_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:TransactionTemplate:Create' }],
  },

  CRM_ClientGroup_Details_TransactionRules_ApprovalRules_Edit: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:TransactionTemplate:Update' }],
  },

  CRM_ClientGroup_Details_AdministrationRules_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:AccountManagementGroup:Read' },
      { permissionKey: 'Approval:AccountManagementTemplate:Read' },
    ],
  },

  CRM_ClientGroup_Details_AdministrationRules_ApprovalGroups_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:AccountManagementGroup:Read' }],
  },

  CRM_ClientGroup_Details_AdministrationRules_ApprovalGroups_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:AccountManagementGroup:Create' }],
  },

  CRM_ClientGroup_Details_AdministrationRules_ApprovalGroups_Edit: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:AccountManagementGroup:Update' }],
  },

  CRM_ClientGroup_Details_AdministrationRules_ApprovalRules_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:AccountManagementTemplate:Read' }],
  },

  CRM_ClientGroup_Details_AdministrationRules_ApprovalRules_Edit: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Approval:AccountManagementTemplate:Update' },
    ],
  },

  CRM_Transaction_Approval_Matrix_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:TransactionWorkflow:Read' }],
  },
};
