import React, { useMemo, useState } from 'react';

// helpers
import moment from 'moment';
import useTranslation from '@hooks/useTranslation';
import { FormikProps, useField } from 'formik';
import { RelationshipWithNoteFormValuesModel } from '../..';

// components
import AddButton from '@common_components/Buttons/AddButton';
import NoteModalDialog, {
  FormValuesModel as NoteFormValuesModel,
} from '../../../NoteModalDialog';

interface IProps {
  fieldName: string;
}

const AddEditNote = ({ fieldName }: IProps) => {
  const { t } = useTranslation('crm');
  const [isVisible, setIsVisible] = useState(false);
  const [field, , helpers] =
    useField<RelationshipWithNoteFormValuesModel>(fieldName);

  const initialFormValues = useMemo<NoteFormValuesModel | null>(() => {
    if (!isVisible) {
      return null;
    }

    if (field.value.note) {
      return {
        ...field.value.note,
        crmItemsInitialValue: field.value.note.crmItems
          ? field.value.note.crmItems.map((e) => ({
              id: e.itemId,
              label: e.label || '',
              model: e,
            }))
          : [],
      };
    } else {
      return (
        field.value.note || {
          eventDate: moment(),
          text: '',
          type: '',
          crmItems: [],
        }
      );
    }
  }, [isVisible, field.value.note]);

  const handleSubmit = (values: NoteFormValuesModel) => {
    helpers.setValue({ ...field.value, note: { ...values } });
  };

  const handleModalClose = () => {
    setIsVisible(false);
  };

  const handleDelete = async (form: FormikProps<NoteFormValuesModel>) => {
    helpers.setValue({
      ...field.value,
      note: undefined,
    });
    await form.resetForm({
      values: {} as any,
      errors: {},
      touched: {},
      isSubmitting: false,
      isValidating: false,
      status: undefined,
      submitCount: 0,
    });
    handleModalClose();
  };

  return (
    <>
      <AddButton onClick={() => setIsVisible(true)}>
        {field.value.note ? t('notes.edit_note') : t('notes.add_note')}
      </AddButton>
      <NoteModalDialog
        hideAddSameNoteToField
        onSubmit={handleSubmit}
        isVisible={isVisible}
        closeCallback={handleModalClose}
        initialValues={initialFormValues}
        onDelete={field.value.note ? handleDelete : undefined}
      />
    </>
  );
};

export default AddEditNote;
