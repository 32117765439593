import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { clientGroupsAPI } from '../../../../api/crm/clientGroup/clientGroupsAPI';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';

// components
import FilterBar, { FilterKeys } from './FilterBar';
import { Divider } from 'antd';
import { default as TemplateClientGroupsTable } from '../../../../components/Tables/TableTemplates/CRM/ClientGroupsTable';

const ClientGroupsTable = () => {
  // table
  const [current, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterValues, setFilterValues] = useState<{
    tags: string[] | string | undefined;
    search: string | undefined;
    filter_status: string[] | undefined;
    filter_review_status: string[] | undefined;
  }>({
    tags: 'no-tags',
    search: undefined,
    filter_status: undefined,
    filter_review_status: undefined,
  });

  const { response, loading } = useFetch(
    () =>
      clientGroupsAPI.fetchClientGroups({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        search: searchQuery || undefined,
        filter_status: filterValues.filter_status
          ? filterValues.filter_status.join(',')
          : undefined,
        filter_review_status: filterValues.filter_review_status
          ? filterValues.filter_review_status.join(',')
          : undefined,
        tags:
          Array.isArray(filterValues.tags) && filterValues.tags.length
            ? filterValues.tags.join(',')
            : undefined,
        noTag: filterValues.tags === 'no-tags' ? true : undefined,
      }),
    [current, searchQuery, filterValues],
  );

  const handleFilterBarChange = (key: FilterKeys, value: string) => {
    switch (key) {
      case 'search':
        setSearchQuery(value ? value.trim() : '');
        break;
    }
  };

  const handleFilterChange = (key: string, value: unknown | undefined) => {
    switch (key) {
      case 'filter_status':
        setFilterValues((prevState) => ({
          ...prevState,
          filter_status: value as string[],
        }));
        break;

      case 'filter_review_status':
        setFilterValues((prevState) => ({
          ...prevState,
          filter_review_status: value as string[],
        }));
        break;
      case 'tags': {
        setFilterValues((prevState) => ({
          ...prevState,
          tags:
            value && Array.isArray(value) && value.includes('no-tags')
              ? 'no-tags'
              : (value as any),
        }));
        break;
      }
    }
  };

  const onFilterChange = (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => {
    if (Object.keys(filters).length) {
      Object.keys(filters).forEach((key) =>
        handleFilterChange(key, filters[key] as any),
      );
    }
  };

  return (
    <>
      <FilterBar onFilterChange={handleFilterBarChange} />
      <Divider />
      <TemplateClientGroupsTable
        total={response?.total || 0}
        current={current}
        data={response?.data || []}
        loading={loading}
        onPaginationChange={setCurrentPage}
        filters={filterValues}
        onFilterChange={onFilterChange}
      />
    </>
  );
};

export default ClientGroupsTable;
