import React, { useMemo } from 'react';

//helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { useField } from 'formik';
import { JournalModel } from '..';

// components
import Table, { TableColumnModel } from '@core_components/Table';
import JournalEntry from './JournalEntry';

interface IProps {
  disabled?: boolean;
  isEditMode: boolean;
  isEditActive: boolean;
  fieldName: string;
  displayPostingDate: boolean;
  handleDelete: (index: number) => void;
}

const InnerFormTable = ({
  fieldName,
  isEditMode,
  isEditActive,
  displayPostingDate,
  handleDelete,
  disabled,
}: IProps) => {
  const { t } = useTranslation('finance');
  const [field] = useField<JournalModel[]>(fieldName);

  const tableColumns = useMemo<TableColumnModel[]>(() => {
    const columns = [
      {
        width: 40,
        title: '#',
        key: 'index',
        dataIndex: 'index',
      },
      {
        title: t('journals.new.entries_table.posting_date'),
        key: 'journalPostingDate',
        dataIndex: 'journalPostingDate',
        hidden: !displayPostingDate,
      },
      {
        title: t('journals.new.entries_table.account'),
        key: 'accountNumber',
        dataIndex: 'accountNumber',
        width: 240,
      },
      {
        title: t('journals.new.entries_table.type'),
        key: 'financialTypeName',
        dataIndex: 'financialTypeName',
        width: 80,
      },
      {
        title: t('journals.new.entries_table.currency'),
        key: 'currencyCode',
        dataIndex: 'currencyCode',
      },
      {
        title: t('journals.new.entries_table.debits'),
        key: 'debitAmount',
        dataIndex: 'debitAmount',
        width: 200,
      },
      {
        title: t('journals.new.entries_table.credits'),
        key: 'creditAmount',
        dataIndex: 'creditAmount',
        width: 200,
      },
      {
        title: t('journals.new.entries_table.currency_rate'),
        key: 'currencyRate',
        dataIndex: 'currencyRate',
      },
      {
        title: t('journals.new.entries_table.debits_base'),
        key: 'debitsHome',
        dataIndex: 'debitsHome',
      },
      {
        title: t('journals.new.entries_table.credits_base'),
        key: 'creditsHome',
        dataIndex: 'creditsHome',
      },
      {
        title: t('journals.new.entries_table.description'),
        key: 'description',
        dataIndex: 'description',
      },
      {
        title: t('journals.new.entries_table.counterparty'),
        key: 'relatedPartyName',
        dataIndex: 'relatedPartyId',
        width: 150,
      },
      {
        title: t('journals.new.entries_table.is_resident'),
        key: 'isResident',
        dataIndex: 'isResident',
        width: 150,
      },
      {
        title: t('journals.new.entries_table.is_visible_client'),
        key: 'isVisibleForClient',
        dataIndex: 'isVisibleForClient',
        width: 100,
      },
      {
        title: t('journals.new.entries_table.documents'),
        key: 'documents',
        dataIndex: 'journalDocuments',
        width: 350,
      },
      {
        key: 'delete',
        dataIndex: 'delete',
        width: 40,
      },
    ];

    return columns.reduce((columnsArray: any, col) => {
      columnsArray.push({
        ...col,
        onCell: (record: any, index: number) => ({
          disabled,
          onDelete: (index: number) => handleDelete(index),
          record,
          journalEntriesLength: field.value.length,
          dataIndex: col.dataIndex,
          index,
          isEditMode,
          isEditActive,
          fieldName,
        }),
      });
      return columnsArray;
    }, []);
  }, [
    field.value,
    isEditActive,
    isEditMode,
    fieldName,
    displayPostingDate,
    handleDelete,
  ]);

  return (
    <Table
      bordered
      size="small"
      columns={tableColumns}
      data={field.value}
      components={{ body: { cell: JournalEntry } }}
    />
  );
};
export default InnerFormTable;
