import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { Moment } from 'moment';
import { accountsAPI } from 'api/finance/account/accountsAPI';
import { AutocompleteInitialValue } from '../../../../Fields/Autocomplete';
import { useField, useFormikContext } from 'formik';
import {
  BankOperationsCodes,
  DetailsOfChargesCodes,
} from '../../../../../enums/banking/transactions';

// components
import FormField from '@core_components/FormField';
import AmountField from '../../../FormComponents/AmountField';
import YesNoSelect from '../../../FormComponents/SelectInputs/YesNoSelect';
import CountrySelect from '../../../FormComponents/SelectInputs/CountrySelect';
import CurrencySelect from '../../../FormComponents/SelectInputs/CurrencySelect';
import DatePickerComponent from '../../../FormComponents/DatePicker';
import DetailsOfChargesSelect from '../../../FormComponents/SelectInputs/DetailsOfChargesSelect';
import BankOperationsCodeSelect from '../../../FormComponents/SelectInputs/BankOperationsCodeSelect';
import BankingBankAccountsAutocomplete from 'components/Forms/FormComponents/Autocompletes/Finance/BankingBankAccountsAutocomplete';
import DocumentsField, {
  DocumentFieldValueModel,
} from 'components/Forms/FormComponents/DocumentsField';
import { Checkbox, Collapse, Input } from 'antd';

export type BeneficiaryData = {
  accountNumber: string;
  accountName: string;
  country: string | null;
  city: string;
  district: string;
  postalCode: string;
  address1: string;
};

export type BeneficiaryCustomerData = {
  accountNumber: string;
  accountName: string;
  country: string | null;
  city: string;
  district: string;
  postalCode: string;
  address1: string;
  repair: boolean;
  repairedBeneficiary: string | null;
  fee: boolean;
};

export type BankInfoData = {
  code: string;
  name: string;
  country: string | null;
  address: string;
  city: string;
  district: string;
  postalCode: string;
};

export type FormValuesModel = {
  general: {
    senderReference: string;
    bankOperationsCode: BankOperationsCodes | null;
    valueDate: Moment | null;
    currency: number | null;
    currencyInitialOption?: AutocompleteInitialValue;
    settledAmount: number | null;
    detailsOfCharges: DetailsOfChargesCodes | null;
    documents: DocumentFieldValueModel[];
  };
  other: {
    incomingTransactionCurrency: number | null;
    incomingTransactionCurrencyInitialOption?: AutocompleteInitialValue;
    instructedAmount: number | null;
  };
  orderingCustomer: BeneficiaryData;
  sendingInstitution: BankInfoData;
  orderingInstitution: BankInfoData;
  senderCorrespondent: BankInfoData;
  receiverCorrespondent: BankInfoData;
  thirdReimbursement: BankInfoData;
  intermediaryInstitution: BankInfoData;
  accountWithInstitution: BankInfoData;
  beneficiaryCustomer: BeneficiaryCustomerData;
  remittanceInformation: {
    line1: string;
    line2: string;
    line3: string;
    line4: string;
  };
  senderToReceiver: {
    line1: string;
    line2: string;
    line3: string;
    line4: string;
    line5: string;
    line6: string;
  };
};

interface IProps {
  formName: string;
  transactionId: number | null;
}

const IncomingTransactionForm = memo(({ formName, transactionId }: IProps) => {
  const { t } = useTranslation(['banking', 'crm', 'common']);
  const { setFieldValue } = useFormikContext<FormValuesModel>();
  const [field] = useField<FormValuesModel>(formName);
  const formFieldProps = useMemo(
    () => ({ labelCol: { xl: 6, lg: 24, md: 24, sm: 24, xs: 24 } }),
    [],
  );

  const onRepairChange = (isChecked: boolean) => {
    if (isChecked) {
      setFieldValue(`${formName}.beneficiaryCustomer.fee`, true);
    }
  };

  const handleBeneficiaryBankAccountSelect = async (newValue: string) => {
    if (newValue) {
      const response =
        await accountsAPI.fetchBankAccountWithBeneficiaryDetails(newValue);
      if (response) {
        const newBeneficiaryCustomerData: BeneficiaryCustomerData = {
          ...field.value.beneficiaryCustomer,
          accountName: response.accountName || '',
          accountNumber: newValue || '',
          country: response.countryCode || null,
          city: response.city || '',
          district: response.district || '',
          postalCode: response.postalCode || '',
          address1: response.address || '',
        };

        setFieldValue(
          `${formName}.beneficiaryCustomer`,
          newBeneficiaryCustomerData,
        );
      }
    }
  };

  const renderBankInfoFields = (fieldBaseName: string) => {
    return (
      <>
        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_code',
          )}
          name={`${fieldBaseName}.code`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_name',
          )}
          name={`${fieldBaseName}.name`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_country',
          )}
          name={`${fieldBaseName}.country`}
          component={CountrySelect}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_address',
          )}
          name={`${fieldBaseName}.address`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_city',
          )}
          name={`${fieldBaseName}.city`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_district',
          )}
          name={`${fieldBaseName}.district`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_postal_code',
          )}
          name={`${fieldBaseName}.postalCode`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
      </>
    );
  };

  const renderBeneficiaryFields = (
    fieldBaseName: string,
    disableAccountName: boolean,
  ) => {
    return (
      <>
        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.account_number',
          )}
          name={`${fieldBaseName}.accountNumber`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          disabled={disableAccountName}
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.account_name',
          )}
          name={`${fieldBaseName}.accountName`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.country',
          )}
          name={`${fieldBaseName}.country`}
          component={CountrySelect}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.address',
          )}
          name={`${fieldBaseName}.address1`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.city',
          )}
          name={`${fieldBaseName}.city`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.district',
          )}
          name={`${fieldBaseName}.district`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.post_code',
          )}
          name={`${fieldBaseName}.postalCode`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
      </>
    );
  };

  return (
    <Collapse
      defaultActiveKey={[
        'general',
        'ordering_customer',
        'ordering_institution',
        'intermediary_institution',
        'account_with_institution',
        'beneficiary_customer',
        'remittance_information',
        'sender_to_receiver',
      ]}
    >
      <Collapse.Panel
        header={t('banking.transactions.add_transaction.general_section_title')}
        key="general"
      >
        <FormField
          {...formFieldProps}
          disabled={!!transactionId}
          label={t(
            'banking.transactions.add_transaction.form_fields.sender_reference',
          )}
          name={`${formName}.general.senderReference`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_operations_code',
          )}
          name={`${formName}.general.bankOperationsCode`}
          component={BankOperationsCodeSelect}
          placeholder={t('select_option', { ns: 'common' })}
          additionalProps={{ allowClear: true }}
        />

        <FormField
          {...formFieldProps}
          disabled={!!transactionId}
          label={t(
            'banking.transactions.add_transaction.form_fields.value_date',
          )}
          name={`${formName}.general.valueDate`}
          component={StyledDatePicker}
          placeholder={t('select_date', { ns: 'common' })}
          additionalProps={{ allowClear: true }}
        />

        <FormField
          {...formFieldProps}
          disabled={!!transactionId}
          label={t(
            'banking.transactions.add_transaction.form_fields.incomingTransactionCurrency',
          )}
          name={`${formName}.general.currency`}
          component={CurrencySelect}
          placeholder={t('select_option', { ns: 'common' })}
          additionalProps={{
            allowClear: true,
            isActive: true,
            isExternal: 'false',
            initialValue: field.value.general.currencyInitialOption,
            loadDataOnMount: true,
            autoSelectIfOneOption: true,
          }}
        />

        <FormField
          {...formFieldProps}
          disabled={!!transactionId}
          label={t(
            'banking.transactions.add_transaction.form_fields.settledAmount',
          )}
          name={`${formName}.general.settledAmount`}
          component={StyledAmountField}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.detailsOfCharges',
          )}
          name={`${formName}.general.detailsOfCharges`}
          component={DetailsOfChargesSelect}
          additionalProps={{ allowClear: true }}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.documents',
          )}
          name={`${formName}.general.documents`}
          component={DocumentsField}
          placeholder={t('documents.select_documents', { ns: 'crm' })}
        />
      </Collapse.Panel>

      <Collapse.Panel
        header={t('banking.transactions.add_transaction.other_section_title')}
        key="other"
      >
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.currency')}
          name={`${formName}.other.incomingTransactionCurrency`}
          component={CurrencySelect}
          placeholder={t('select_option', { ns: 'common' })}
          additionalProps={{
            allowClear: true,
            isActive: true,
            isExternal: 'false',
            initialValue:
              field.value.other.incomingTransactionCurrencyInitialOption,
            loadDataOnMount: true,
            autoSelectIfOneOption: true,
          }}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.instructedAmount',
          )}
          name={`${formName}.other.instructedAmount`}
          component={StyledAmountField}
          placeholder={t('enter_value', { ns: 'common' })}
        />
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.ordering_customer_section_title',
        )}
        key="ordering_customer"
      >
        {renderBeneficiaryFields(
          `${formName}.orderingCustomer`,
          !!transactionId,
        )}
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.sending_institution_section_title',
        )}
        key="sending_institution"
      >
        {renderBankInfoFields(`${formName}.sendingInstitution`)}
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.ordering_institution_section_title',
        )}
        key="ordering_institution"
      >
        {renderBankInfoFields(`${formName}.orderingInstitution`)}
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.sender_correspondent_section_title',
        )}
        key="sender_correspondent"
      >
        {renderBankInfoFields(`${formName}.senderCorrespondent`)}
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.receiver_correspondent_section_title',
        )}
        key="receiver_correspondent"
      >
        {renderBankInfoFields(`${formName}.receiverCorrespondent`)}
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.third_reimbursement_section_title',
        )}
        key="third_reimbursement"
      >
        {renderBankInfoFields(`${formName}.thirdReimbursement`)}
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.intermediary_institution_section_title',
        )}
        key="intermediary_institution"
      >
        {renderBankInfoFields(`${formName}.intermediaryInstitution`)}
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.account_with_institution_section_title',
        )}
        key="account_with_institution"
      >
        {renderBankInfoFields(`${formName}.accountWithInstitution`)}
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.beneficiary_customer_section_title',
        )}
        key="beneficiary_customer"
      >
        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.account_number',
          )}
          name={`${formName}.beneficiaryCustomer.accountNumber`}
          component={BankingBankAccountsAutocomplete}
          placeholder={t('select_option', { ns: 'common' })}
          additionalProps={{
            isFrozen: 'all',
            isActive: true,
            onSelect: handleBeneficiaryBankAccountSelect,
            onClear: () => handleBeneficiaryBankAccountSelect(''),
          }}
        />
        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.account_name',
          )}
          name={`${formName}.beneficiaryCustomer.accountName`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.country',
          )}
          name={`${formName}.beneficiaryCustomer.country`}
          component={CountrySelect}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.address',
          )}
          name={`${formName}.beneficiaryCustomer.address1`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.city',
          )}
          name={`${formName}.beneficiaryCustomer.city`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.district',
          )}
          name={`${formName}.beneficiaryCustomer.district`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.post_code',
          )}
          name={`${formName}.beneficiaryCustomer.postalCode`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          onChange={(e) => onRepairChange(!!e?.target?.checked)}
          label={t('banking.transactions.add_transaction.form_fields.repair')}
          name={`${formName}.beneficiaryCustomer.repair`}
          component={Checkbox}
        />

        <FormField
          {...formFieldProps}
          label={t(
            'banking.transactions.add_transaction.form_fields.repairedBeneficiary',
          )}
          name={`${formName}.beneficiaryCustomer.repairedBeneficiary`}
          component={BankingBankAccountsAutocomplete}
          placeholder={t('select_option', { ns: 'common' })}
          additionalProps={{ isFrozen: 'all', isActive: true }}
        />

        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.fee')}
          name={`${formName}.beneficiaryCustomer.fee`}
          component={YesNoSelect}
          additionalProps={{ allowClear: true }}
          placeholder={t('select_option', { ns: 'common' })}
        />
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.remittance_information_section_title',
        )}
        key="remittance_information"
      >
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 1,
          })}
          name={`${formName}.remittanceInformation.line1`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 2,
          })}
          name={`${formName}.remittanceInformation.line2`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 3,
          })}
          name={`${formName}.remittanceInformation.line3`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 4,
          })}
          name={`${formName}.remittanceInformation.line4`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.sender_to_receiver_section_title',
        )}
        key="sender_to_receiver"
      >
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 1,
          })}
          name={`${formName}.senderToReceiver.line1`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 2,
          })}
          name={`${formName}.senderToReceiver.line2`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 3,
          })}
          name={`${formName}.senderToReceiver.line3`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 4,
          })}
          name={`${formName}.senderToReceiver.line4`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 5,
          })}
          name={`${formName}.senderToReceiver.line5`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 6,
          })}
          name={`${formName}.senderToReceiver.line6`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
      </Collapse.Panel>
    </Collapse>
  );
});

const StyledDatePicker = styled(DatePickerComponent)`
  width: 100%;
`;

const StyledAmountField = styled(AmountField)`
  width: 100%;
`;

export default IncomingTransactionForm;
