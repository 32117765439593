import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import {
  Transaction,
  TransferTemplate,
} from '../../typings/banking/transactions';
import {
  FullTransactionFromQuery,
  PendingTransaction,
  ShortTransactionFromQuery,
  bankingTransactionsAPIAdapter,
} from '../../apiAdapters/banking/bankingTransactionsAPIAdapter';

interface FetchTransactionsParamsModel extends FetchParamsModel {
  type: string | undefined;
  status: string | undefined;
  sortBy?: 'ValueDate' | 'CreatedOn';
  sortDirection?: boolean;
  searchQuery?: string;
  fromRequestedDate?: number;
  toRequestedDate?: number;
  fromValueDate?: number;
  toValueDate?: number;
  orderingCustomerFilter?: string;
  beneficiaryCustomerFilter?: string;
  fromAmount?: number;
  toAmount?: number;
  templateIds?: string;
}

interface FetchOrderingCustomersParamsModel extends FetchParamsModel {
  searchQuery: string;
}

interface FetchBeneficiaryParamsModel extends FetchParamsModel {
  searchQuery: string;
}

interface FetchTemplateParams extends FetchParamsModel {
  search: string;
}

const bankingTransactionsAPI = {
  fetchTransactions: (params: FetchTransactionsParamsModel) => {
    return APIService.get<FetchResponseModel<ShortTransactionFromQuery>>(
      `${APIConfig.accountingApi}/transactions/pending`,
      {
        params,
      },
    ).then<FetchResponseModel<Transaction>>(({ data }) =>
      bankingTransactionsAPIAdapter.fetchTransactions(data),
    );
  },

  fetchTransactionById: (transactionId: number) => {
    return APIService.get<FullTransactionFromQuery>(
      `${APIConfig.accountingApi}/transactions/pending/${transactionId}`,
    ).then(({ data }) => data);
  },

  sendTransactionToRDCReview: (transactionId: number) => {
    return APIService.post(
      `${APIConfig.accountingApi}/transactions/pending/${transactionId}/rdc`,
    ).then(({ data }) => data);
  },

  addTransaction: (transaction: PendingTransaction) => {
    return APIService.post(
      `${APIConfig.accountingApi}/transactions/pending`,
      transaction,
    ).then(({ data }) => data);
  },

  updateTransactionById: (
    transactionId: number,
    transaction: PendingTransaction,
  ) => {
    return APIService.patch(
      `${APIConfig.accountingApi}/transactions/pending/${transactionId}`,
      transaction,
    ).then(({ data }) => data);
  },

  returnTransactionById: (transactionId: number) => {
    return APIService.post(
      `${APIConfig.accountingApi}/transactions/pending/${transactionId}/return`,
    ).then(({ data }) => data);
  },

  rejectOutgoingTransactionById: (transactionId: number) => {
    return APIService.post(
      `${APIConfig.accountingApi}/transactions/pending/${transactionId}/reject`,
    ).then(({ data }) => data);
  },

  transmitOutgoingTransactionById: (
    transactionId: number,
    senderReference: string,
  ) => {
    return APIService.post(
      `${APIConfig.accountingApi}/transactions/pending/${transactionId}/transmit`,
      { senderReference },
    ).then(({ data }) => data);
  },

  fetchOrderingCustomers: (params: FetchOrderingCustomersParamsModel) => {
    return APIService.get<FetchResponseModel<string>>(
      `${APIConfig.accountingApi}/transactions/pending/customers/ordering`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchBeneficiary: (params: FetchBeneficiaryParamsModel) => {
    return APIService.get<FetchResponseModel<string>>(
      `${APIConfig.accountingApi}/transactions/pending/customers/beneficiary`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchTemplates: (params: FetchTemplateParams) => {
    return APIService.get<FetchResponseModel<TransferTemplate>>(
      `${APIConfig.accountingApi}/transactions/pending/outgoing-wire-transfer-template/search`,
      { params },
    ).then(({ data }) => data);
  },
};

export { bankingTransactionsAPI };
